import {format} from 'date-fns'

export const COLUMNS = [
    {
        Header: 'ID',
        accessor: 'id',
        disableFilters: true
    },
    {
        Header: 'Student Name',
        accessor: 'student.full_name',
        disableFilters: true
    },
    {
        Header: 'Student ID',
        accessor: 'student.user_id',
        disableFilters: true
    },
    // {
    //     Header: 'Package/Membership ID',
    //     accessor: 'package_membership_id'
    // },
    {
        Header: 'Package/Membership Name',
        accessor: 'package_membership_name'
    },
    {
        Header: 'Buying Date',
        accessor: 'buying_date',
        Cell:({value}) =>{
            if(value == "No payment Yet")
                return value
            else
                return format(new Date(value), "dd/MM/yyyy hh:mm aaaaa'm'")
        }
    },
    {
        Header: 'Start Date',
        accessor: 'start_date',
        Cell:({value}) =>{return format(new Date(value), 'dd/MM/yyyy')}
    },
    {
        Header: 'Expiry Date',
        accessor: 'expiry_date',
        Cell:({value}) =>{return format(new Date(value), 'dd/MM/yyyy')}
    },
    {
        Header: 'Validity Duration',
        accessor: 'duration'
    },
    {
        Header: 'Is Expired',
        accessor: 'is_expired'
    },
    {
        Header: 'Subscription Type',
        accessor: 'is_membership'
    },    
    {
        Header: 'Dance Category',
        accessor: 'dance_category.text'
    },
    {
        Header: 'Package Type',
        accessor: 'package_type'
    },
    {
        Header: 'Price (LE)',
        accessor: 'amount'
    },
    {
        Header: 'Discount',
        accessor: 'discount'
    },
    {
        Header: 'Price After Discount (LE)',
        accessor: 'amount_after_discount'
    },
    {
        Header: 'Paid Amount',
        accessor: 'paid'
    },
    {
        Header: 'Remaining Amount',
        accessor: 'remaining'
    },
    {
        Header: 'Number of Classes',
        accessor: 'number_classes'
    },
    {
        Header: 'Number of Remaining Classes',
        accessor: 'number_classes_remaining'
    }
]