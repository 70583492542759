import React, { Component } from "react";
import {
  Header,
  Image,
  Button,
  Form,
  Segment,
  Dropdown,
  Table,
  Divider,
  Icon,
} from "semantic-ui-react";
import { BasicTable } from "./BasicTable";
import subscription_icon from "../../media/Images/subscription.png";
import "../../stylesheets/subscriptionEdit.css";
import $ from "jquery";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import DetailedSubscription from "./DetailedSubscription/DetailedSubscription";
import track from "../track";
import { decodeToken } from "react-jwt";

class StudentSubscription extends Component {
  constructor(props) {
    super(...arguments);
    this.state = {
      decodedToken: decodeToken(sessionStorage.getItem("access_token")),
      //New Subscription
      studentId: this.props.studentId,
      subscriptionTypeNew: "Package",
      danceCatId: 1,
      startDate: new Date(),
      packageChosen: {},
      membershipChosen: {},
      discountNew: 0,
      amountAfterDiscount: 0,
      paidAmount: 0,
      remainingAmount: 0,

      categories: [],
      memberships: [],
      membershipsOptions: [],
      packages: [],
      packagesOptions: [],

      subscriptions: [],
      subscriptionName: "",
      paymentMethod: "Cash",
      paymentMethodEdit: "Cash",
      paymentMethodOption: [
        {
          key: "Cash",
          text: "Cash",
          value: "Cash",
        },
        {
          key: "Credit Card",
          text: "Credit Card",
          value: "Credit Card",
        },
      ],
      subscriptionTypes: [
        {
          key: "Package",
          text: "Package",
          value: "Package",
        },
        {
          key: "Membership",
          text: "Membership",
          value: "Membership",
        },
      ],
      editSubscriptionID: 0,
      editSubscriptionObj: {},
      editSubscriptionStartDate: new Date(),
      editSubscriptionExpiryDate: new Date(),
      remainingAmountEdit: 0,
      editSubscriptionReleaseAmount: 0,
      payRest: 0,
      subscriptionsDetails: [],
    };
    this.setEditSubscription = this.setEditSubscription.bind(this);
  }

  setEditSubscription(e) {
    this.setState({ editSubscriptionID: e }, async () => {
      if (this.state.editSubscriptionID !== 0) {
        for (var i = 0; i < this.state.subscriptions.length; i++) {
          if (
            this.state.subscriptions[i].id === this.state.editSubscriptionID
          ) {
            this.setState(
              {
                editSubscriptionObj: this.state.subscriptions[i],
                remainingAmountEdit: this.state.subscriptions[i].remaining,
                editSubscriptionExpiryDate: new Date(
                  this.state.subscriptions[i].expiry_date
                ),
                editSubscriptionStartDate: new Date(
                  this.state.subscriptions[i].start_date
                ),
              },
              async () => {
                this.handleEdit();
                console.log(typeof this.state.editSubscriptionObj);
                console.log(this.state.editSubscriptionObj);
              }
            );
            break;
          }
        }
      } else {
        this.setState(
          {
            editSubscriptionObj: {},
            remainingAmountEdit: 0,
          },
          async () => {
            this.handleEdit();
          }
        );
      }
    });
  }

  componentDidMount() {
    document.getElementById("addSubscriptionEdit").hidden = true;
    document.getElementById("edit_subscription").hidden = true;
    document.getElementById("add_new").hidden = false;

    document.getElementById("could_refund").hidden = true;
    document.getElementById("could_pay_rest").hidden = true;
    document.getElementById("could_turn_walk_in").hidden = true;
    document.getElementById("could_view_details").hidden = true;
    document.getElementById("could_edit_start_date").hidden = true;
    document.getElementById("could_extend_expiry_date").hidden = true;
    document.getElementById("could_release_amount").hidden = true;

    document.getElementById("view_details_comp").hidden = true;
    document.getElementById("changeSubStartDate").hidden = true;
    document.getElementById("changeSubExpiryDate").hidden = true;
    document.getElementById("changeReleaseAmount").hidden = true;

    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/categories`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ categories: result.dance_categories });
          return;
        },
        error: (error) => {
          alert(
            "Unable to load dance categories. Please try your request again"
          );
          return;
        },
      });

      $.ajax({
        url: `/api/packages`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ packages: result.packages }, async () => {
            let pckOptList = [];
            for (var i = 0; i < this.state.packages.length; i++) {
              let pckopt = {};
              // eslint-disable-next-line
              if (
                this.state.packages[i].Dance_Category.key ==
                this.state.danceCatId
              ) {
                pckopt.key = this.state.packages[i].id;
                pckopt.value = this.state.packages[i].id;
                pckopt.text = this.state.packages[i].name;
                pckOptList.push(pckopt);
              }
            }
            this.setState({ packagesOptions: pckOptList });
          });
          return;
        },
        error: (error) => {
          alert("Unable to load dance packages. Please try your request again");
          return;
        },
      });

      $.ajax({
        url: `/api/memberships`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ memberships: result.memberships }, async () => {
            let memOptList = [];
            for (var i = 0; i < this.state.memberships.length; i++) {
              let memopt = {};
              memopt.key = this.state.memberships[i].id;
              memopt.value = this.state.memberships[i].id;
              memopt.text = this.state.memberships[i].name;
              memOptList.push(memopt);
            }
            this.setState({ membershipsOptions: memOptList });
          });
          return;
        },
        error: (error) => {
          alert(
            "Unable to load dance memberships. Please try your request again"
          );
          return;
        },
      });

      $.ajax({
        url: `/api/students/${this.state.studentId}/subscriptions`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ subscriptions: result.subscriptions });
          return;
        },
        error: (error) => {
          alert("Unable to load subscriptions. Please try your request again");
          return;
        },
      });
    };
    secondFunction();
  }

  viewDetails = (event) => {
    event.preventDefault();
    if (document.getElementById("view_details_comp").hidden) {
      const secondFunction = async () => {
        const result = await this.props.refreshToken();
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/students/${this.state.studentId}/subscriptions/${this.state.editSubscriptionID}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {
            //Include the bearer token in header
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + sessionStorage.getItem("access_token")
            );
          },
          success: (result) => {
            this.setState(
              { subscriptionsDetails: result.subscriptions_details },
              async () => {
                document.getElementById("addSubscriptionEdit").hidden = true;
                document.getElementById("changeSubStartDate").hidden = true;
                document.getElementById("changeSubExpiryDate").hidden = true;
                document.getElementById("changeReleaseAmount").hidden = true;
                document.getElementById("view_details_comp").hidden = false;
                document.getElementById("view_details_comp").scrollIntoView();
              }
            );
            return;
          },
          error: (error) => {
            alert(
              "Unable to load detailed subscription. Please try your request again"
            );
            return;
          },
        });
      };
      secondFunction();
    } else {
      document.getElementById("view_details_comp").hidden = true;
    }
  };

  new_subscription = () => {
    if (document.getElementById("addSubscriptionEdit").hidden) {
      document.getElementById("view_details_comp").hidden = true;
      document.getElementById("changeSubStartDate").hidden = true;
      document.getElementById("changeSubExpiryDate").hidden = true;
      document.getElementById("changeReleaseAmount").hidden = true;
      document.getElementById("edit_subscription").hidden = true;
      document.getElementById("addSubscriptionEdit").hidden = false;
      document.getElementById("addSubscriptionEdit").scrollIntoView();
    } else {
      document.getElementById("addSubscriptionEdit").hidden = true;
    }
  };

  change_start_date = () => {
    if (document.getElementById("changeSubStartDate").hidden) {
      document.getElementById("view_details_comp").hidden = true;
      document.getElementById("addSubscriptionEdit").hidden = true;
      document.getElementById("changeSubExpiryDate").hidden = true;
      document.getElementById("changeReleaseAmount").hidden = true;
      document.getElementById("edit_subscription").hidden = true;
      document.getElementById("changeSubStartDate").hidden = false;
      document.getElementById("changeSubStartDate").scrollIntoView();
    } else {
      document.getElementById("changeSubStartDate").hidden = true;
    }
  };

  change_expiry_date = () => {
    if (document.getElementById("changeSubExpiryDate").hidden) {
      document.getElementById("view_details_comp").hidden = true;
      document.getElementById("addSubscriptionEdit").hidden = true;
      document.getElementById("changeSubStartDate").hidden = true;
      document.getElementById("changeReleaseAmount").hidden = true;
      document.getElementById("edit_subscription").hidden = true;
      document.getElementById("changeSubExpiryDate").hidden = false;
      document.getElementById("changeSubExpiryDate").scrollIntoView();
    } else {
      document.getElementById("changeSubExpiryDate").hidden = true;
    }
  };

  release_amount = () => {
    if (document.getElementById("changeReleaseAmount").hidden) {
      document.getElementById("view_details_comp").hidden = true;
      document.getElementById("addSubscriptionEdit").hidden = true;
      document.getElementById("changeSubStartDate").hidden = true;
      document.getElementById("changeSubExpiryDate").hidden = true;
      document.getElementById("edit_subscription").hidden = true;
      document.getElementById("changeReleaseAmount").hidden = false;
      document.getElementById("changeReleaseAmount").scrollIntoView();
    } else {
      document.getElementById("changeReleaseAmount").hidden = true;
    }
  };

  edit_subscription = () => {
    if (document.getElementById("edit_subscription").hidden) {
      document.getElementById("view_details_comp").hidden = true;
      document.getElementById("addSubscriptionEdit").hidden = true;
      document.getElementById("changeSubStartDate").hidden = true;
      document.getElementById("changeSubExpiryDate").hidden = true;
      document.getElementById("changeReleaseAmount").hidden = true;
      document.getElementById("edit_subscription").hidden = false;
      document.getElementById("edit_subscription").scrollIntoView();
    } else {
      document.getElementById("edit_subscription").hidden = true;
    }
  };

  addSubscription = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/subscriptions`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          student_id: this.state.studentId,
          subscription_type: this.state.subscriptionTypeNew,
          dance_cat_id: this.state.danceCatId,
          start_date: this.state.startDate,
          package_id: this.state.packageChosen?.id,
          membership_id: this.state.membershipChosen?.id,
          discount: this.state.discountNew,
          amount_after_discount: this.state.amountAfterDiscount,
          payment_method: this.state.paymentMethod,
          paid_amount: this.state.paidAmount,
          remaining_amount: this.state.remainingAmount,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `added Subscription to student ${this.state.studentId} and received payment of amount ${this.state.paidAmount} in ${this.state.paymentMethod}`,
            `Subscription`,
            "Payment Received"
          );
          document.getElementById("addSubscriptionEdit").hidden = true;
          $.ajax({
            url: `/api/students/${this.state.studentId}/subscriptions`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {
              //Include the bearer token in header
              xhr.setRequestHeader(
                "Authorization",
                "Bearer " + sessionStorage.getItem("access_token")
              );
            },
            success: (result) => {
              this.setState({ subscriptions: result.subscriptions });
              return;
            },
            error: (error) => {
              alert(
                "Unable to load subscriptions. Please try your request again"
              );
              return;
            },
          });
          return;
        },
        error: (error) => {
          alert(
            "Unable to add the subscription. Please check your inputs & try again"
          );
          return;
        },
      });
    };
    secondFunction();
  };

  postChangeSubStartDate = (event) => {
    //please write changes
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/subscriptions/${this.state.editSubscriptionID}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          edit_action: "edit_start_Date",
          pay_rest: this.state.payRest,
          payment_method_edit: this.state.paymentMethodEdit,
          edit_subscription_start_date: this.state.editSubscriptionStartDate,
          edit_subscription_expiry_date: this.state.editSubscriptionExpiryDate,
          edit_subscription_release_amount:
            this.state.editSubscriptionReleaseAmount,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `edited start date of the Subscription ${this.state.editSubsc} to be ${this.state.editSubscriptionStartDate}`,
            `Subscription`,
            "Edit-Start-Date"
          );

          document.location.reload();
          return;
        },
        error: (error) => {
          alert(
            "Unable to turn to edit the start date of the package subscription"
          );
          return;
        },
      });
    };
    secondFunction();
  };

  postChangeSubExpiryDate = (event) => {
    //please write changes
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/subscriptions/${this.state.editSubscriptionID}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          edit_action: "edit_expiry_Date",
          pay_rest: this.state.payRest,
          payment_method_edit: this.state.paymentMethodEdit,
          edit_subscription_start_date: this.state.editSubscriptionStartDate,
          edit_subscription_expiry_date: this.state.editSubscriptionExpiryDate,
          edit_subscription_release_amount:
            this.state.editSubscriptionReleaseAmount,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `edited expiry date of the Subscription ${this.state.editSubsc} to be ${this.state.editSubscriptionExpiryDate}`,
            `Subscription`,
            "Edit-Expiry-Date"
          );

          document.location.reload();
          return;
        },
        error: (error) => {
          alert(
            "Unable to turn to edit the start date of the package subscription"
          );
          return;
        },
      });
    };
    secondFunction();
  };

  handleSubscriptionName = (event) => {
    this.setState({ subscriptionName: event.target.value });
  };

  handleChangeCategory = (event, { value }) => {
    this.setState({ danceCatId: value }, async () => {
      let pckOptList = [];
      for (var i = 0; i < this.state.packages.length; i++) {
        let pckopt = {};
        // eslint-disable-next-line
        if (
          this.state.packages[i].Dance_Category.key == this.state.danceCatId
        ) {
          pckopt.key = this.state.packages[i].id;
          pckopt.value = this.state.packages[i].id;
          pckopt.text = this.state.packages[i].name;
          pckOptList.push(pckopt);
        }
      }
      this.setState({ packagesOptions: pckOptList });
    });
  };

  handleStartDate = (event) => {
    this.setState({
      startDate: event.target.value,
    });
  };

  handleSubscriptionTypes = (event, { value }) => {
    this.setState(
      {
        subscriptionTypeNew: value,
        packageChosen: {},
        membershipChosen: {},
        discountNew: 0,
        amountAfterDiscount: 0,
        paidAmount: 0,
        remainingAmount: 0,
      },
      async () => {
        // eslint-disable-next-line
        document.getElementById("discount_reset").value =
          this.state.discountNew;
        document.getElementById("paid_reset").value = this.state.paidAmount;
        if (this.state.subscriptionTypeNew === "Membership") {
          document.getElementById("cat").hidden = true;
        } else {
          document.getElementById("cat").hidden = false;
        }
      }
    );
  };

  handleDiscount = (event) => {
    let discount = event.target.value / 100;
    let priceAfterDiscount = 0;
    if (this.state.subscriptionTypeNew === "Package") {
      priceAfterDiscount = this.state.packageChosen.amount * (1 - discount);
    } else {
      priceAfterDiscount = this.state.membershipChosen.amount * (1 - discount);
    }
    this.setState({
      discountNew: discount,
      amountAfterDiscount: priceAfterDiscount,
      remainingAmount: priceAfterDiscount - this.state.paidAmount,
    });
  };

  handleAmountPaid = (event) => {
    const paid = Number(event.target.value);
    const remaining = this.state.amountAfterDiscount - paid;
    this.setState({
      paidAmount: paid,
      remainingAmount: remaining,
    });
  };

  handlePackagesMemberhips = (event, { value }) => {
    // eslint-disable-next-line
    if (this.state.subscriptionTypeNew == "Package") {
      for (var i = 0; i < this.state.packages.length; i++) {
        // eslint-disable-next-line
        if (this.state.packages[i].id == value) {
          this.setState(
            {
              packageChosen: this.state.packages[i],
              membershipChosen: { id: null },
              amountAfterDiscount: this.state.packages[i].amount,
              remainingAmount: this.state.packages[i].amount,
              discountNew: 0,
              paidAmount: 0,
            },
            async () => {
              document.getElementById("discount_reset").value =
                this.state.discountNew;
              document.getElementById("paid_reset").value =
                this.state.paidAmount;
            }
          );
          break;
        }
      }
    } else {
      for (i = 0; i < this.state.memberships.length; i++) {
        // eslint-disable-next-line
        if (this.state.memberships[i].id == value) {
          this.setState(
            {
              membershipChosen: this.state.memberships[i],
              packageChosen: { id: null },
              amountAfterDiscount: this.state.memberships[i].amount,
              remainingAmount: this.state.memberships[i].amount,
              discountNew: 0,
              paidAmount: 0,
            },
            async () => {
              document.getElementById("discount_reset").value =
                this.state.discountNew;
              document.getElementById("paid_reset").value =
                this.state.paidAmount;
            }
          );
          break;
        }
      }
    }
  };

  packageShow() {
    //show info of package
    return (
      <div className="info">
        <Divider horizontal />
        <label className="infoLabel">Chosen Package Info</label>
        <Divider horizontal />
        <div className="infoForm">
          <Form.Group>
            <Form.Field className="formHalfWidthSubscriptionEdit">
              <label className="labelSubscriptionEdit">Package Price</label>
              <input
                className="input"
                defaultValue={this.state.packageChosen.amount}
                readOnly
              />
            </Form.Field>

            <Form.Field className="formHalfWidthSubscriptionEdit" id="cat">
              <label className="labelSubscriptionEdit">
                Validity Duration (weeks)
              </label>
              <input
                className="input"
                defaultValue={this.state.packageChosen.duration_weeks}
                readOnly
              />
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field className="formHalfWidthSubscriptionEdit">
              <label className="labelSubscriptionEdit">Number of Classes</label>
              <input
                className="input"
                defaultValue={this.state.packageChosen.number_classes}
                readOnly
              />
            </Form.Field>

            <Form.Field className="formHalfWidthSubscriptionEdit" id="cat">
              <label className="labelSubscriptionEdit">Package Type</label>
              <input
                className="input"
                defaultValue={this.state.packageChosen.package_type}
                readOnly
              />
            </Form.Field>
          </Form.Group>
        </div>
      </div>
    );
  }

  membershipShow() {
    //show info of membership
    return (
      <div className="info">
        <Divider horizontal />
        <label className="infoLabel">Chosen Membership Info</label>
        <Divider horizontal />
        <div className="infoForm">
          <Form.Group>
            <Form.Field className="formHalfWidthSubscriptionEdit">
              <label className="labelSubscriptionEdit">Membership Price</label>
              <input
                className="input"
                defaultValue={this.state.membershipChosen.amount}
                readOnly
              />
            </Form.Field>

            <Form.Field className="formHalfWidthSubscriptionEdit" id="cat">
              <label className="labelSubscriptionEdit">
                Validity Duration (months)
              </label>
              <input
                className="input"
                defaultValue={this.state.membershipChosen.duration_months}
                readOnly
              />
            </Form.Field>
          </Form.Group>
        </div>
      </div>
    );
  }

  handleEdit() {
    // console.log("this.state.editSubscriptionObj = ", this.state.editSubscriptionObj);
    document.getElementById("could_refund").hidden = true;
    document.getElementById("could_pay_rest").hidden = true;
    document.getElementById("could_turn_walk_in").hidden = true;
    document.getElementById("could_view_details").hidden = true;
    document.getElementById("edit_subscription").hidden = true;
    document.getElementById("view_details_comp").hidden = true;
    document.getElementById("could_edit_start_date").hidden = true;
    document.getElementById("could_extend_expiry_date").hidden = true;
    document.getElementById("could_release_amount").hidden = true;
    document.getElementById("changeSubStartDate").hidden = true;
    document.getElementById("changeSubExpiryDate").hidden = true;
    document.getElementById("changeReleaseAmount").hidden = true;

    if (this.state.editSubscriptionID !== 0) {
      document.getElementById("could_view_details").hidden = false;
      document.getElementById("could_view_details").scrollIntoView();

      let isPartner = this.state.decodedToken.is_partner ?? false;

      if (isPartner) {
        document.getElementById("could_extend_expiry_date").hidden = false;
        document.getElementById("could_extend_expiry_date").scrollIntoView();
      } else {
        document.getElementById("could_extend_expiry_date").hidden = true;
      }

      if (this.state.editSubscriptionObj.remaining != 0 && isPartner) {
        document.getElementById("could_release_amount").hidden = false;
        document.getElementById("could_release_amount").scrollIntoView();
      } else {
        document.getElementById("could_release_amount").hidden = true;
      }

      if (this.state.editSubscriptionObj.could_refund === "yes") {
        document.getElementById("could_refund").hidden = false;
        document.getElementById("could_edit_start_date").hidden = false;
        document.getElementById("could_edit_start_date").scrollIntoView();
      } else {
        document.getElementById("could_refund").hidden = true;
        document.getElementById("could_edit_start_date").hidden = true;
      }

      if (this.state.editSubscriptionObj.could_pay_rest === "yes") {
        document.getElementById("could_pay_rest").hidden = false;
        document.getElementById("could_pay_rest").scrollIntoView();
      } else {
        document.getElementById("could_pay_rest").hidden = true;
      }

      if (this.state.editSubscriptionObj.could_turn_walk_in === "yes") {
        document.getElementById("could_turn_walk_in").hidden = false;
        document.getElementById("could_turn_walk_in").scrollIntoView();
      } else {
        document.getElementById("could_turn_walk_in").hidden = true;
      }
    } else {
      document.getElementById("could_refund").hidden = true;
      document.getElementById("could_pay_rest").hidden = true;
      document.getElementById("could_turn_walk_in").hidden = true;
      document.getElementById("could_view_details").hidden = true;
      document.getElementById("edit_subscription").hidden = true;
      document.getElementById("view_details_comp").hidden = true;
      document.getElementById("could_edit_start_date").hidden = true;
      document.getElementById("could_extend_expiry_date").hidden = true;
      document.getElementById("could_release_amount").hidden = true;
    }
  }

  noActionAlert() {
    if (this.state.editSubscriptionID !== 0) {
      return (
        <input
          style={{
            width: "50%",
            margin: "1%",
            padding: "1%",
            textAlign: "center",
          }}
          readOnly
          defaultValue="No remaining amount to be paid & cannot refund subscription (already used)"
        />
      );
    } else {
      return <></>;
    }
  }

  handlePayRest = (event) => {
    this.setState({
      payRest: Number(event.target.value),
    });
  };

  postWalkin = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/subscriptions/${this.state.editSubscriptionID}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          edit_action: "turn_walkin",
          pay_rest: this.state.payRest,
          payment_method_edit: this.state.paymentMethodEdit,
          edit_subscription_start_date: this.state.editSubscriptionStartDate,
          edit_subscription_expiry_date: this.state.editSubscriptionExpiryDate,
          edit_subscription_release_amount:
            this.state.editSubscriptionReleaseAmount,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `turned Subscription ${this.state.editSubsc} to walk-in`,
            `Subscription`,
            "Walk-in"
          );

          document.location.reload();
          return;
        },
        error: (error) => {
          alert("Unable to turn to a walkin package subscription");
          return;
        },
      });
    };
    secondFunction();
  };

  postRefund = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/subscriptions/${this.state.editSubscriptionID}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        dataType: "json",
        contentType: "application/json",
        data: JSON.stringify({
          edit_action: "refund_deactivate",
          pay_rest: this.state.payRest,
          payment_method_edit: this.state.paymentMethodEdit,
          edit_subscription_start_date: this.state.editSubscriptionStartDate,
          edit_subscription_expiry_date: this.state.editSubscriptionExpiryDate,
          edit_subscription_release_amount:
            this.state.editSubscriptionReleaseAmount,
        }),
        xhrFields: {
          withCredentials: true,
        },
        crossDomain: true,
        success: (result) => {
          track(
            `refunded Subscription ${this.state.editSubsc}`,
            `Subscription`,
            "Refund"
          );
          document.location.reload();
          return;
        },
        error: (error) => {
          alert("Unable to refund");
          return;
        },
      });
    };
    secondFunction();
  };

  postPayRest = (event) => {
    event.preventDefault();
    if (this.state.payRest > this.state.editSubscriptionObj.remaining) {
      alert("Error: the amount paid is larger than the remaining amount");
    } else {
      const secondFunction = async () => {
        const result = await this.props.refreshToken();
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/subscriptions/${this.state.editSubscriptionID}`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {
            //Include the bearer token in header
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + sessionStorage.getItem("access_token")
            );
          },
          dataType: "json",
          contentType: "application/json",
          data: JSON.stringify({
            edit_action: "pay_rest",
            pay_rest: this.state.payRest,
            payment_method_edit: this.state.paymentMethodEdit,
            edit_subscription_start_date: this.state.editSubscriptionStartDate,
            edit_subscription_expiry_date:
              this.state.editSubscriptionExpiryDate,
            edit_subscription_release_amount:
              this.state.editSubscriptionReleaseAmount,
          }),
          xhrFields: {
            withCredentials: true,
          },
          crossDomain: true,
          success: (result) => {
            track(
              `received rest of payment for subscription ${this.state.editSubscriptionID} of amount ${this.state.payRest} in ${this.state.paymentMethodEdit}`,
              `Subscription`,
              "Payment Received"
            );

            document.location.reload();
            return;
          },
          error: (error) => {
            alert("Unable to pay rest of price");
            return;
          },
        });
      };
      secondFunction();
    }
  };

  postChangeSubReleaseAmount = (event) => {
    event.preventDefault();
    if (
      this.state.editSubscriptionReleaseAmount >
      this.state.editSubscriptionObj.remaining
    ) {
      alert("Error: the release amount is larger than the remaining amount");
    } else {
      const secondFunction = async () => {
        const result = await this.props.refreshToken();
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/subscriptions/${this.state.editSubscriptionID}`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {
            //Include the bearer token in header
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + sessionStorage.getItem("access_token")
            );
          },
          dataType: "json",
          contentType: "application/json",
          data: JSON.stringify({
            edit_action: "release_amount",
            pay_rest: this.state.payRest,
            payment_method_edit: this.state.paymentMethodEdit,
            edit_subscription_start_date: this.state.editSubscriptionStartDate,
            edit_subscription_expiry_date:
              this.state.editSubscriptionExpiryDate,
            edit_subscription_release_amount:
              this.state.editSubscriptionReleaseAmount,
          }),
          xhrFields: {
            withCredentials: true,
          },
          crossDomain: true,
          success: (result) => {
            track(
              `released amount of payment for subscription ${this.state.editSubscriptionID} of amount ${this.state.editSubscriptionReleaseAmount}`,
              `Subscription`,
              "Amount Released"
            );

            document.location.reload();
            return;
          },
          error: (error) => {
            alert("Unable to release rest of price");
            return;
          },
        });
      };
      secondFunction();
    }
  };

  handlePaymentMethod = (event, { value }) => {
    this.setState({ paymentMethod: value });
  };

  handlePaymentMethodEdit = (event, { value }) => {
    this.setState({ paymentMethodEdit: value });
  };

  handleEditSubscriptionStartDate = (event) => {
    this.setState({
      editSubscriptionStartDate: event.target.value,
    });
  };

  handleEditSubscriptionExpiryDate = (event) => {
    this.setState({
      editSubscriptionExpiryDate: event.target.value,
    });
  };

  handleEditSubscriptionReleaseAmount = (event) => {
    this.setState({
      editSubscriptionReleaseAmount: event.target.value,
    });
  };

  render() {
    // console.log("this.state.decodedToken = ", this.state.decodedToken.is_partner ?? false);

    return (
      <div>
        <div id="headerPartSubscriptionEdit">
          <Header id="HeaderSubscriptionEdit" as="h2" block={true}>
            <Image size="large" circular src={this.props.imageURL} />{" "}
            {this.props.studentName}'s Subscriptions
          </Header>
        </div>
        <Divider horizontal />

        <div id="add_new">
          <Button
            id="subs_button"
            size="mini"
            color="red"
            onClick={this.new_subscription}
          >
            {" "}
            Add a New Subscription{" "}
          </Button>
        </div>
        <Divider horizontal />

        <div id="tableSubscriptionEdit">
          <BasicTable
            key={this.state.subscriptions}
            data={this.state.subscriptions}
            editSubscription={this.state.editSubscriptionID}
            setEditSubscription={this.setEditSubscription}
          />
        </div>
        {/* used */}
        <div id="addFormSubscriptionEdit">
          <Segment id="addSubscriptionEdit" inverted>
            <Form id="formSubscriptionEdit" inverted>
              <Form.Group>
                <Form.Field className="formHalfWidthSubscriptionEdit">
                  <label className="labelSubscriptionEdit">
                    Subscription Type
                  </label>
                  <Dropdown
                    placeholder="Select the subscription type"
                    selection
                    options={this.state.subscriptionTypes}
                    onChange={this.handleSubscriptionTypes}
                  />
                </Form.Field>
                <Form.Field className="formHalfWidthSubscriptionEdit" id="cat">
                  <label className="labelSubscriptionEdit">
                    Dance Category
                  </label>
                  <Dropdown
                    placeholder="Select the dance category"
                    selection
                    search
                    options={this.state.categories}
                    onChange={this.handleChangeCategory}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field className="formHalfWidthSubscriptionEdit" id="date">
                  <label className="labelSubscriptionEdit">Start Date</label>
                  <DateTimePickerComponent
                    CssClass="customClass"
                    id="StartTime"
                    className="e-field"
                    format="dd/MM/yy"
                    value={this.state.startDate}
                    onChange={this.handleStartDate}
                  />
                </Form.Field>
                <Form.Field className="formHalfWidthSubscriptionEdit">
                  {/* eslint-disable-next-line*/}
                  <label className="labelSubscriptionEdit">
                    {this.state.subscriptionTypeNew == "Package"
                      ? "Choose Package Type"
                      : "Choose Membership Type"}
                  </label>
                  <Dropdown
                    placeholder={
                      this.state.subscriptionTypeNew === "Package"
                        ? "Choose Package Type"
                        : "Choose Membership Type"
                    }
                    selection
                    search
                    options={
                      this.state.subscriptionTypeNew === "Package"
                        ? this.state.packagesOptions
                        : this.state.membershipsOptions
                    }
                    onChange={this.handlePackagesMemberhips}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field className="formHalfWidthSubscriptionEdit">
                  <label className="labelSubscriptionEdit">Discount (%)</label>
                  <input
                    id="discount_reset"
                    className="input"
                    type="number"
                    placeholder="Discount (%)"
                    onChange={this.handleDiscount}
                  />
                </Form.Field>
                <Form.Field className="formHalfWidthSubscriptionEdit">
                  <label className="labelSubscriptionEdit">
                    Price After Discount (EGP)
                  </label>
                  <input
                    className="input"
                    type="number"
                    value={this.state.amountAfterDiscount}
                    readOnly
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field className="formHalfWidthSubscriptionEdit">
                  <label className="labelSubscriptionEdit">
                    Payment Method
                  </label>
                  <Dropdown
                    text={this.state.paymentMethod}
                    selection
                    options={this.state.paymentMethodOption}
                    onChange={this.handlePaymentMethod}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field className="formHalfWidthSubscriptionEdit">
                  <label className="labelSubscriptionEdit">
                    Paid Amount (EGP)
                  </label>
                  <input
                    id="paid_reset"
                    className="input"
                    type="number"
                    placeholder="Amount Paid in EGP"
                    onChange={this.handleAmountPaid}
                  />
                </Form.Field>
                <Form.Field className="formHalfWidthSubscriptionEdit">
                  <label className="labelSubscriptionEdit">
                    Remaining Amount (EGP)
                  </label>
                  <input
                    className="input"
                    type="number"
                    value={this.state.remainingAmount}
                    readOnly
                  />
                </Form.Field>
              </Form.Group>

              {this.state.membershipChosen === {} &&
              this.state.packageChosen === {}
                ? {}
                : this.state.subscriptionTypeNew === "Package"
                ? this.packageShow()
                : this.membershipShow()}

              <Button
                id="submitSubscriptionEdit1"
                floated="right"
                type="submit"
                onClick={this.addSubscription}
              >
                Submit
              </Button>
            </Form>
          </Segment>
        </div>
        {/*end of used */}

        <div id="edit_buttons">
          <div className="editButtonsItem" id="could_view_details">
            <Button
              id="view_details"
              size="mini"
              color="red"
              onClick={this.viewDetails}
            >
              View Usage
            </Button>
          </div>
          <div className="editButtonsItem" id="could_pay_rest">
            <Button
              id="pay_rest"
              size="mini"
              color="red"
              onClick={this.edit_subscription}
            >
              Pay Rest of Price
            </Button>
          </div>
          <div className="editButtonsItem" id="could_refund">
            <Button
              id="refund"
              size="mini"
              color="red"
              onClick={this.postRefund}
            >
              Refund
            </Button>
          </div>
          <div className="editButtonsItem" id="could_turn_walk_in">
            <Button
              id="walk_in"
              size="mini"
              color="red"
              onClick={this.postWalkin}
            >
              Turn to Walkin Package
            </Button>
          </div>
          <div className="editButtonsItem" id="could_edit_start_date">
            <Button
              id="change_start_date"
              size="mini"
              color="red"
              onClick={this.change_start_date}
            >
              Edit Start Date
            </Button>
          </div>
          <div className="editButtonsItem" id="could_extend_expiry_date">
            <Button
              id="change_expiry_date"
              size="mini"
              color="red"
              onClick={this.change_expiry_date}
            >
              Extend Expiry Date
            </Button>
          </div>
          <div className="editButtonsItem" id="could_release_amount">
            <Button
              id="release_amount"
              size="mini"
              color="red"
              onClick={this.release_amount}
            >
              Release Remaining Amount
            </Button>
          </div>
        </div>

        {/* new part */}
        <div id="changeFormStartDate">
          <Segment id="changeSubStartDate" inverted>
            <Form id="changeformSubStartDate" inverted>
              <Form.Group>
                <Form.Field className="formHalfWidthSubscriptionEdit" id="date">
                  <label className="labelSubscriptionEdit">Start Date</label>
                  <DateTimePickerComponent
                    CssClass="customClass"
                    id="StartTime"
                    className="e-field"
                    format="dd/MM/yy"
                    value={this.state.editSubscriptionStartDate}
                    onChange={this.handleEditSubscriptionStartDate}
                  />
                </Form.Field>
              </Form.Group>
              <Button
                id="submitSubStartDateEdit"
                floated="right"
                type="submit"
                onClick={this.postChangeSubStartDate}
              >
                Submit
              </Button>
            </Form>
          </Segment>
        </div>

        <div id="changeFormExpiryDate">
          <Segment id="changeSubExpiryDate" inverted>
            <Form id="changeformSubExpiryDate" inverted>
              <Form.Group>
                <Form.Field className="formHalfWidthSubscriptionEdit" id="date">
                  <label className="labelSubscriptionEdit">Expiry Date</label>
                  <DateTimePickerComponent
                    CssClass="customClass"
                    id="StartTime"
                    className="e-field"
                    format="dd/MM/yy"
                    value={this.state.editSubscriptionExpiryDate}
                    onChange={this.handleEditSubscriptionExpiryDate}
                  />
                </Form.Field>
              </Form.Group>
              <Button
                id="submitSubExpiryDateEdit"
                floated="right"
                type="submit"
                onClick={this.postChangeSubExpiryDate}
              >
                Submit
              </Button>
            </Form>
          </Segment>
        </div>

        <div id="changeReleaseAmount">
          <Divider horizontal>
            <h4>
              <Icon name="credit card" />
              Release Pending Amounts
            </h4>
          </Divider>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Amount to be released (EGP)</Table.Cell>
                <Table.Cell>
                  <input
                    type="number"
                    placeholder="Amount to be released (EGP)"
                    onChange={this.handleEditSubscriptionReleaseAmount}
                  />{" "}
                  {" EGP"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Total Remaining Amount</Table.Cell>
                <Table.Cell>
                  {this.state.remainingAmountEdit}
                  {" EGP"}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div id="submit_release_amount_div">
            <Button
              id="submitSubscriptionEdit3"
              floated="right"
              type="submit"
              onClick={this.postChangeSubReleaseAmount}
            >
              Submit
            </Button>
          </div>
        </div>

        {/* end of new part */}

        <div id="edit_subscription">
          <Divider horizontal>
            <h4>
              <Icon name="credit card" />
              Pay Rest of Price
            </h4>
          </Divider>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Payment Method</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    text={this.state.paymentMethodEdit}
                    selection
                    options={this.state.paymentMethodOption}
                    onChange={this.handlePaymentMethodEdit}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Payment Amount</Table.Cell>
                <Table.Cell>
                  <input
                    type="number"
                    placeholder="Payment Amount"
                    onChange={this.handlePayRest}
                  />{" "}
                  {" EGP"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>Total Remaining Amount</Table.Cell>
                <Table.Cell>
                  {this.state.remainingAmountEdit}
                  {" EGP"}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div id="submit_hide_rent_div">
            <Button
              id="submitSubscriptionEdit2"
              floated="right"
              type="submit"
              onClick={this.postPayRest}
            >
              Submit
            </Button>
          </div>
        </div>

        <div id="view_details_comp">
          <DetailedSubscription
            subscriptionsDetails={this.state.subscriptionsDetails}
            history={this.props.history}
          />
        </div>
      </div>
    );
  }
}

export default StudentSubscription;
