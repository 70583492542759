import React, { Component } from 'react';
import { Header, Table, Icon, Divider, Button, Dropdown} from 'semantic-ui-react';
import '../../../stylesheets/monthlyClosingExisting.css';
import $ from 'jquery';
import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';
import {format} from 'date-fns';

class ViewExistingSalary extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      salariesOptions: [],
      salaryId:0,
      salary:{},
      dateFormatOptions: {weekday: 'long', year:'numeric', month:'long', day:'numeric'},

      partners: [],
      admins: [],
      instructors: [],
      
      startDate: new Date(),
      endDate: new Date(),

      totalInstructorsSalary: 0,
      countInstructors: 0,
      
      totalAdminSalary: 0,
      totalAdminBonus: 0,
      officeBoySalary: 0,
      officeBoyBonus: 0,
      instagramStories: 0,
      graphicDesign: 0,
      rent: 0,
      electricity: 0,
      water: 0,
      misc: 0,
      project_pole_revenue: 0
    }
  }

  componentDidMount(){
    document.getElementById("view_salary_details").hidden = true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/salaries`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ salariesOptions: result.salaries})
          return;
        },
        error: (error) => {
          alert('Unable to load any available salaries. Please try your request again')
          return;
        }
      });
    }
    secondFunction();
  }

  handleSalariesOptions = (event,{value}) => {
    event.preventDefault();
    this.setState({salaryId: value}, async ()=>{//console.log(this.state.salaryId)
    })
  }

  

  submitViewSalary = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      if (document.getElementById("view_salary_details").hidden){
        $.ajax({
          url: `/api/salaries/${this.state.salaryId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ salary: result.salary}, async ()=>{
              document.getElementById("view_salary_details").hidden = false;
            })
            return;
          },
          error: (error) => {
            alert('Unable to load any available salaries. Please try your request again')
            return;
          }
        })
      } else{
        document.getElementById("view_salary_details").hidden = true;
      }
      
      $.ajax({
        url: `/api/admins-salaries-total/${this.state.salaryId}`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({
            admins: result.admins
          });
          return;
        },
        error: (error) => {
          alert('Unable to load admins. Please try your request again')
          return;
        }
      });
  
      $.ajax({
        url: `/api/partners-salaries-total/${this.state.salaryId}`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({
            partners: result.partners
          });
          return;
        },
        error: (error) => {
          alert('Unable to load partners. Please try your request again')
          return;
        }
      });
  
      $.ajax({
        url: `/api/instructors-salaries-total/${this.state.salaryId}`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({
            instructors: result.instructors
          });
          return;
        },
        error: (error) => {
          alert('Unable to load instructors. Please try your request again')
          return;
        }
      });
    }
    secondFunction();
  }

  
  handleStartDate = (event) => {
    this.setState({
        startDate: event.target.value
    }, async ()=>{
      //console.log(this.state.startDate)
    });
  }

  handleEndDate = (event) => {
    this.setState({
        endDate: event.target.value
    }, async ()=>{
      //console.log(this.state.endDate)
    });
  }

  removeSalary = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/salaries/${this.state.salaryId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          is_delete: true
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          window.location.reload();
          return;
        },
        error: (error) => {
          alert('Unable to delete Salary. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }


  
  render() {
    return <div>
              <div id='existingTable'>
                <Divider horizontal>
                  <h4>
                    <Icon name='payment' />
                    Filter Existing Months
                  </h4>
                </Divider>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={4}>Salary Filter</Table.Cell>
                      <Table.Cell>
                        <Dropdown
                          className='Dropdown'
                          placeholder='Choose Salary'
                          selection
                          options={this.state.salariesOptions}
                          onChange={this.handleSalariesOptions}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Button id='submitViewSalary' floated='right' size='mini' color='red' onClick={this.submitViewSalary}> Filter </Button>
                
                <Divider horizontal/>

                <div id='view_salary_details'>

                  <Divider horizontal>
                    <h4>
                      Dates
                    </h4>
                  </Divider>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Start Date</Table.Cell>
                        <Table.Cell>
                          {}
                          {this.state.salary.start}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>End Date</Table.Cell>
                        <Table.Cell>
                          {this.state.salary.end}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>

                  <Divider horizontal/>

                  <Divider horizontal>
                    <h2>
                      Expenses
                    </h2>
                  </Divider>

                  <Divider horizontal>
                    <h4>
                      Instructors
                    </h4>
                  </Divider>

                  <Table definition>
                  <Table.Body>
                    {this.state.instructors.map((instructor)=> 
                      <Table.Row >
                        <Table.Cell width={4}>{instructor.text} </Table.Cell>
                        <Table.Cell > Salary (Instructor) = {instructor.salary_month} {' EGP'}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                  </Table>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Count of Instructors</Table.Cell>
                        <Table.Cell>{this.state.salary.number_of_instructors} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Sum of Instructors' Salaries</Table.Cell>
                        <Table.Cell>{this.state.salary.instructors_salary_total}{' EGP'} </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  
                  <Divider horizontal>
                    <h4>
                      Partners
                    </h4>
                  </Divider>

                  <Table definition>
                    <Table.Body>
                    {this.state.partners.map((partner)=> 
                      <Table.Row >
                        <Table.Cell width={4}>{partner.text} </Table.Cell>
                        <Table.Cell > Salary (Partner) = {partner.salary_month} {' EGP'}
                        </Table.Cell>
                      </Table.Row>
                    )}
                    </Table.Body>
                  </Table>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}> Sum of Partners' Salaries</Table.Cell>
                        <Table.Cell>{this.state.salary.partner_salary}{' EGP'} </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>

                  <Divider horizontal>
                    <h4>
                      Administrators
                    </h4>
                  </Divider>

                  <Table definition>
                    <Table.Body>
                    {this.state.admins.map((admin)=> 
                      <Table.Row >
                        <Table.Cell width={4}>{admin.text} </Table.Cell>
                        <Table.Cell > Salary (Admin) = {admin.salary_month} {' EGP'}
                        </Table.Cell>
                      </Table.Row>
                    )}
                    </Table.Body>
                  </Table>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}> Sum of Administrators' Salaries</Table.Cell>
                        <Table.Cell>{this.state.salary.admin_salary}{' EGP'} </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>

                  <Divider horizontal>
                    <h4>
                      Office Boy
                    </h4>
                  </Divider>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Office Boy Salary</Table.Cell>
                        <Table.Cell>{this.state.salary.office_boy_salary}{' EGP'} </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>

                  <div style={{margin:"20px"}}></div>

                  <Divider horizontal>
                    <h4>
                      Others
                    </h4>
                  </Divider>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Instagram Stories</Table.Cell>
                        <Table.Cell>{this.state.salary.instagram_stories}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Graphic Designer</Table.Cell>
                        <Table.Cell>{this.state.salary.graphic_designer}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Media Buying</Table.Cell>
                        <Table.Cell>{this.state.salary.media_buying}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Accountant</Table.Cell>
                        <Table.Cell>{this.state.salary.accountant}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Studio Manager</Table.Cell>
                        <Table.Cell>{this.state.salary.studio_manager}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Rent</Table.Cell>
                        <Table.Cell>{this.state.salary.rent}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Electricity</Table.Cell>
                        <Table.Cell>{this.state.salary.electricity}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Water</Table.Cell>
                        <Table.Cell>{this.state.salary.water}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Misc</Table.Cell>
                        <Table.Cell>{this.state.salary.misc}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Project Pole Expense</Table.Cell>
                        <Table.Cell>{this.state.salary.project_pole_expense}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Kitchen Out</Table.Cell>
                        <Table.Cell>{this.state.salary.kitchen_out}{' EGP'} </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  
                  <Divider horizontal/>
                  
                  <Divider horizontal>
                    <h2>
                      Revenue
                    </h2>
                  </Divider>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Subscriptions (LE)</Table.Cell>
                        <Table.Cell>{this.state.salary.subscriptions}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Room Rentals (LE)</Table.Cell>
                        <Table.Cell>{this.state.salary.room_rentals}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Project Pole Revenue</Table.Cell>
                        <Table.Cell>{this.state.salary.project_pole_revenue}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Kitchen In</Table.Cell>
                        <Table.Cell>{this.state.salary.kitchen_in}{' EGP'} </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                    
                  <Divider horizontal/>

                  <Divider horizontal>
                    <h2>
                      Net Income
                    </h2>
                  </Divider>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Total Revenue (LE)</Table.Cell>
                        <Table.Cell>{this.state.salary.revenue}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Total Expenses (LE)</Table.Cell>
                        <Table.Cell>{this.state.salary.expenses}{' EGP'} </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Net Income (LE)</Table.Cell>
                        <Table.Cell>{this.state.salary.net_income}{' EGP'} </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                    
                  <Button id='submitDeleteSalary' floated='right' size='mini' color='red' onClick={this.removeSalary}> Remove Salary </Button>
                </div>
                  
              </div>
                

              
              
           </div>
  }  
}

export default ViewExistingSalary;
