//import {format} from 'date-fns'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

/*var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}*/
//onClick={()=>navTo(`/instructors/${value}`)} 

export const COLUMNS = [
    {
        width:30,
        Header:()=>'',
        accessor:'id',
        Cell:({value}) => (
            <Link to={`/leaves/${value}`}><Button circular icon='edit' content='Edit'/></Link>
        ),
        disableFilters: true
    },
    {
        Header: 'Name',
        accessor: 'employee.name'
    },
    {
        Header: 'Title',
        accessor: 'employee.title'
    },
    {
        Header: 'Year',
        accessor: 'year'
    },
    {
        Header: 'Yearly Balance',
        accessor: 'balance'
    },
    {
        Header: 'Taken',
        accessor: 'taken'
    },
    {
        Header: 'Remaining',
        accessor: 'remaining'
    }
]