
export const COLUMNSStud = [
    {
        Header: 'ID',
        accessor: 'user_id'
    },
    {
        Header: 'Student Name',
        accessor: 'full_name'
    }
]