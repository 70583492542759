import React, { Component } from 'react';
import { Header, Image, Button, Dropdown, Form, Segment, Divider} from 'semantic-ui-react'
import dance_icon from '../../media/Images/category.png';
import '../../stylesheets/danceEdit.css'
import $ from 'jquery';
import track from '../track';

class DanceEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      dance_categories:[],
      danceCatId:0,
      danceCatName:'',
      danceName: '',
      danceId:0
    }
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  componentDidMount(){
    this.setState({danceId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/categories`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ dance_categories: result.dance_categories }, async ()=> {
              $.ajax({
                url: `/api/dance-edits/${this.state.danceId}`, //TODO: update request URL
                type: "GET",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                success: (result) => {
                  this.setState({
                    danceName: result.dance.name,
                    danceCatId: result.dance.dance_cat_id
                   }, async () =>{
                     //console.log('dance_categories',this.state.dance_categories)
                     //console.log('dance_cat_id',this.state.danceCatId)
                     for(var i=0;i<this.state.dance_categories.length;i++){
                      // eslint-disable-next-line
                      if (this.state.dance_categories[i].key == this.state.danceCatId){
                         this.setState({danceCatName: this.state.dance_categories[i].text}, async ()=>{
                          //console.log('danceCatName',this.state.danceCatName)
                         })
                         
                       }
                     }
                   })
                  return;
                },
                error: (error) => {
                  alert('Unable to load roam to edit. Please try your request again')
                  return;
                }
              })
            })
            return;
          },
          error: (error) => {
            alert('Unable to load dance categories. Please try your request again')
            return;
          }
        })
      }  
      secondFunction();
    })
  }

  
  danceDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/dance-edits/${this.state.danceId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          name: this.state.danceName
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`deleted the dance  named ${this.state.danceName} `,
                    'Dance', 'Delete')        
          
          //this.navTo('/dances');
          this.props.history.push('/dances');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/dance-edits/${this.state.danceId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              name: this.state.danceName
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              track(`deleted the dance  named ${this.state.danceName} `,
                    'Dance', 'Delete')        
          
              //this.navTo('/dances');
              this.props.history.push('/dances');
              return;
            },
            error: (error) => {
              alert('Unable to delete the dance dance. Please try your request again')
              return;
            }
          })
          return;
        }
      })
    }
    secondFunction();
  }

  
  danceEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/dance-edits/${this.state.danceId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          name: this.state.danceName,
          dance_cat_id: this.state.danceCatId
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`updated the dance  named ${this.state.danceName} `,
                    'Dance', 'Update')        
          
          //this.navTo('/dances');
          this.props.history.push('/dances');
          return;
        },
        error: (error) => {
          alert('Unable to update the dance dance. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  cancelId = ()=>{
    //this.navTo(`/dances`)
    this.props.history.push('/dances');
  }

  handleDanceName = (event) => {
    this.setState({danceName: event.target.value})
  }

  handleChangeCategory2 = (event, {value}) => {
    this.setState({danceCatId: value})
  }

  render() {

    return <div>
                <div id='headerPartDanceEdit'>
                  <Header id='HeaderDanceEdit' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={dance_icon} /> Dance
                  </Header>
                </div>
                <Divider horizontal/>

                <div id='addFormDanceEdit'>{/*id='form-div'*/}
                  <Segment id="addDanceEdit" inverted>{/*id="addDanceEdit"*/}
                    <Form id='formDanceEdit' inverted>{/*id='form'*/}
                      <Form.Group>
                        <Form.Field className='formHalfWidthDanceEdit'>
                          <label className="labelDanceEdit">Dance Name</label>
                          <input className ='input' defaultValue={this.state.danceName} onChange={this.handleDanceName}/>
                        </Form.Field>
                        <Form.Field className='formHalfWidthDanceEdit'>
                        <label className="labelDanceEdit">Dance Category</label>
                        <Dropdown
                          text={this.state.danceCatName}
                          selection
                          options={this.state.dance_categories}
                          onChange={this.handleChangeCategory2}
                        />
                      </Form.Field>
                      </Form.Group>
                      <div id = 'submitDanceEditContainer'>
                        <Button id='submitDanceEdit1' floated='right' type='submit' onClick={this.danceEditId}>Update</Button>
                        <Button id='submitDanceEdit2' floated='right' type='submit' onClick={this.danceDeleteId}>Delete</Button>
                        <Button id='submitDanceEdit3' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                      </div>
                    </Form>
                  </Segment>
                </div>
           </div>
  }
  
}


export default DanceEdit;
