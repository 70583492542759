import React, { Component } from 'react';
import { Header, Image, Button, Dropdown, Form, Segment, Divider} from 'semantic-ui-react'
import CardTemplate from '../CardTemplate';
import inst_icon from '../../media/Images/instructor_icon.png';
import no_profile_picture from '../../media/Images/no_profile_picture.png';
import '../../stylesheets/instructorEdit.css';
import $ from 'jquery';
import track from '../track';



class InstructorEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      instructorId:0,
      userId:0,
      username:'',
      firstName: '',
      lastName: '',
      address: '',
      email: '',
      phone: '',
      role:'',
      Password:'',
      PasswordRe:'',

      profile_picture: null,
      imageURL: '',

      instructor: {},
      dance_categories:[],
      dances_options:[],
      dances:[],

      

      danceCategoryIds:[],
      dancesInstIds:[],
      danceCategoryNames:[],
      dancesInstNames:[],
      salaryBase:'Hourly Based',
      isHoulyBased:true,
      salaryHourAlone: 0,
      salaryHourWithPartner: 0,
      salaryPercentage:0,

      values:[],
      
      salaryBaseOptions: [
        {
          key: 'Hourly Based',
          text: 'Hourly Based',
          value: 'Hourly Based'
        },
        {
          key: 'Percentage Based',
          text: 'Percentage Based',
          value: 'Percentage Based'
        }
      ]
    }
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  componentDidMount(){
    //document.getElementById('profile_picture_change').hidden= true;
    document.getElementById("resetInstructorEdit").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/categories`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ dance_categories: result.dance_categories })
          return;
        },
        error: (error) => {
          alert('Unable to load dance categories. Please try your request again')
          return;
        }
      })
  
      $.ajax({
        url: `/api/dances`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ dances: result.dances })
          return;
        },
        error: (error) => {
          alert('Unable to load dances. Please try your request again')
          return;
        }
      })
  
      this.setState({instructorId: this.props.match.params.id}, async () => {
        $.ajax({
          url: `/api/instructors/${this.state.instructorId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              instructor: result.instructor,
              userId: result.instructor.user_id,
              username: result.instructor.username,
              firstName: result.instructor.first_name,
              lastName: result.instructor.last_name,
              address: result.instructor.address,
              email: result.instructor.email,
              phone: result.instructor.phone,
              role: result.instructor.role,
              danceCategoryIds:result.instructor.categories_id,
              dancesInstIds: result.instructor.dances_id,
              danceCategoryNames:result.instructor.categories_name,
              dancesInstNames: result.instructor.dances_name,
              values: result.instructor.values,
              
              salaryBase: result.instructor.is_hourly_based 
            }, async ()=> {
              if (this.state.salaryBase === 'Hourly Based'){
                document.getElementById("salary_partner").hidden = false;
                this.setState({
                  isHoulyBased: true,
                  salaryHourAlone: result.instructor.salary_hour_alone,
                  salaryHourWithPartner: result.instructor.salary_hour_with_partner,
                  salaryPercentage: 0
                })
              } else{
                document.getElementById("salary_partner").hidden = true;
                this.setState({isHoulyBased: false,
                  salaryHourAlone: 0,
                  salaryHourWithPartner: 0,
                  salaryPercentage: result.instructor.percentage
                })
              }
              
              $.ajax({
                url: `/uploads/${this.state.instructorId}/instructors`, //TODO: update request URL
                type: "GET",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                processData: false,
                contentType: false,
                cache: false,
                timeout: 800000,
                              
                success: (result) => {
                  this.setState({ imageURL: `data:image/jpeg;base64,${result}`  })
                  return;
                },
                error: (error) => {
                  this.setState({ imageURL: no_profile_picture })
                  return;
                }
              })
            })
            return ;
          },
          error: (error) => {
            alert('Unable to load instructor. Please try your request again')
            return;
          }
        })
      })
    }
    secondFunction();
  }

  createUI(){
    return this.state.values.map((el, i) => 
        <div key={i}>
            <Divider horizontal/>
              <Form.Group>
                <Form.Field id='danceCatDropdownEdit'>
                  <label className="labelInstructor">Dance Category</label>
                  <Dropdown compact
                    text={this.state.danceCategoryNames[i]}
                    selection
                    options={this.state.dance_categories}
                    onChange={this.handleChangeCategory.bind(this,i)}
                  />    
                </Form.Field>
                
                <Form.Field id='danceDropdownEdit'>
                  <label className="labelInstructor">Dance</label>
                  <Dropdown compact
                    text={this.state.dancesInstNames[i]}
                    selection
                    options={this.state.dances_options[i]}
                    onChange={this.handleChangeDance.bind(this,i)}
                  />
                </Form.Field>
                <Form.Field id='removeFieldEdit'>
                  <Button id = 'removeEdit' circular icon='circular remove' onClick={this.removeClick.bind(this, i)}/>
                </Form.Field>
              </Form.Group>
            <Divider horizontal/>
        </div>          
    )
  }

  handleUsername = (event) =>{
    this.setState({username:event.target.value})
  }

  handleFirstName = (event) =>{
    this.setState({firstName:event.target.value})
  }

  handleLastName = (event) =>{
    this.setState({lastName:event.target.value})
  }

  handleAddress = (event) =>{
    this.setState({address:event.target.value})
  }

  handleEmail = (event) =>{
    this.setState({email:event.target.value})
  }

  handlePhone = (event) =>{
    this.setState({phone:event.target.value})
  }

  handleChangeIsHourlyBased = (event, {value}) =>{
    // eslint-disable-next-line
    if (value == 'Hourly Based'){
      document.getElementById("salary_partner").hidden = false;
      this.setState({
        salaryBase: value,
        isHoulyBased: true
      })
    }else{
      document.getElementById("salary_partner").hidden = true;
      this.setState({
        salaryBase: value,
        isHoulyBased: false
      })
    }
   }

  handleSalary = (event) => {
    // eslint-disable-next-line
    if (this.state.isHoulyBased==true){
      this.setState({
        salaryHourAlone:event.target.value,
        salaryPercentage: 0
      })
    } else{
      this.setState({
        salaryHourAlone:0,
        salaryPercentage: event.target.value
      })
    }
  }

  handleSalaryPartner = (event) => {
    this.setState({
      salaryHourWithPartner:event.target.value
    })
  }

  addClick(){
    this.setState(prevState => ({ values: [...prevState.values, '']}))
  }
  
  removeClick(i){
     let values = [...this.state.values];
     values.splice(i,1);

     let d = [...this.state.dancesInstIds];
     d.splice(i,1);

     let dn = [...this.state.dancesInstNames];
     dn.splice(i,1);

     let c = [...this.state.danceCategoryIds];
     c.splice(i,1);

     let cn = [...this.state.danceCategoryNames];
     cn.splice(i,1);

     this.setState({ values , dancesInstIds: d, dancesInstNames:dn, danceCategoryIds: c, danceCategoryNames: cn});
  }

  handleChangeCategory = (i,event, {value}) => {
    let danceCategoryIds = [...this.state.danceCategoryIds];
    danceCategoryIds[i] = value;


    this.setState({danceCategoryIds: danceCategoryIds}, async () => {
      let dances_options = [...this.state.dances_options];
      dances_options[i] = this.state.dances[this.state.danceCategoryIds[i]]; 
      this.setState({
      dances_options:dances_options
    })})
  }

  handleChangeDance = (i, event,{value}) => {
    let d = [...this.state.dancesInstIds];
    d[i] = value;
    
    this.setState({ dancesInstIds: d});
  }



  instructorDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/instructors/${this.state.instructorId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          user_id: this.state.userId,
          username: this.state.username,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          address: this.state.address,
          email: this.state.email,
          phone: this.state.phone,
          dance_cat_ids: this.state.danceCategoryIds,
          dances_instructor: this.state.dancesInstIds,
          salary_base: this.state.salaryBase,
          salary_hour_alone: this.state.salaryHourAlone,
          salary_hour_with_partner: this.state.salaryHourWithPartner,
          salary_percentage: this.state.salaryPercentage / 100
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          //this.navTo('/instructors');
          track(`deleted instructor, username = ${this.state.username} `,
                    'Instructor', 'Delete')        
          
          this.props.history.push('/instructors');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/instructors/${this.state.instructorId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              user_id: this.state.userId,
              username: this.state.username,
              first_name: this.state.firstName,
              last_name: this.state.lastName,
              address: this.state.address,
              email: this.state.email,
              phone: this.state.phone,
              dance_cat_ids: this.state.danceCategoryIds,
              dances_instructor: this.state.dancesInstIds,
              salary_base: this.state.salaryBase,
              salary_hour_alone: this.state.salaryHourAlone,
              salary_hour_with_partner: this.state.salaryHourWithPartner,
              salary_percentage: this.state.salaryPercentage /100
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              //this.navTo('/instructors');
              track(`deleted instructor, username = ${this.state.username} `,
                    'Instructor', 'Delete')        
              this.props.history.push('/instructors');
              return;
            },
            error: (error) => {
              alert('Unable to delete instructor. Please try your request again')
              return;
            }
          })
          return;
        }
      })
    }
    secondFunction();
        
  }

  
  instructorEditId = (event) => {
    event.preventDefault();
    if(this.state.salaryBase ==='Percentage Based' && (this.state.salaryPercentage > 100 || this.state.salaryPercentage < 0)){
      alert('Salary percentage must be between 0 to 100%. Please correct it and try your request again')
    } 
    else {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/instructors/${this.state.instructorId}`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            delete: 'edit',
            user_id: this.state.userId,
            username: this.state.username,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            address: this.state.address,
            email: this.state.email,
            phone: this.state.phone,
            dance_cat_ids: this.state.danceCategoryIds,
            dances_instructor: this.state.dancesInstIds,
            salary_base: this.state.salaryBase,
            salary_hour_alone: this.state.salaryHourAlone,
            salary_hour_with_partner: this.state.salaryHourWithPartner,
            salary_percentage: this.state.salaryPercentage / 100
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            //this.navTo('/instructors');
            track(`Updated instructor, username = ${this.state.username} `,
                    'Instructor', 'Update')
          
            this.props.history.push('/instructors');
            return;
          },
          error: (error) => {
            alert(`Unable to update instructor - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
          }
        })
      }  
      secondFunction();
    }
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  cancelId = ()=>{
    //this.navTo(`/instructors`)
    this.props.history.push('/instructors');
  }

  editProfilePictureShow = () =>{
    //document.getElementById('profile_picture_change').hidden= false;
  }

  handleProfilePicture = (event)=>{
    this.setState({profile_picture: event.target.files[0]})
  }



  changeProfilePicture = (e)=>{
    e.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      var data1 = new FormData();
      data1.append('file', this.state.profile_picture);
      data1.append('filename', this.state.username);
      $.ajax({
        url: `/uploads/${this.state.instructorId}/instructors`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        enctype: 'multipart/form-data',
        data: data1,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 800000,
                      
        success: (result) => {
          track(`Updated instructor's picture of username = ${this.state.username} `,
                    'Instructor', 'Update')
          
          this.setState({ imageURL: URL.createObjectURL(this.state.profile_picture) })
          return;
        },
        error: (error) => {
          alert('Unable to change profile picture. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }


  handlePassword = (event) =>{
    this.setState({Password:event.target.value})
  }

  handlePasswordRe = (event) =>{
    this.setState({PasswordRe:event.target.value})
  }

  reset_password = ()=>{
    if (document.getElementById("resetInstructorEdit").hidden){
      document.getElementById("resetInstructorEdit").hidden=false;
      document.getElementById("resetInstructorEdit").scrollIntoView();
    }else{
      document.getElementById("resetInstructorEdit").hidden=true;
    } 
  }


  resetPassword = (event) =>{
    event.preventDefault();
    if(this.state.Password === '' || this.state.PasswordRe === '' || this.state.Password !== this.state.PasswordRe ){
      alert("Unable to change password, please enter a valid password")
    } else{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/instructors/${this.state.instructorId}/resetPassword`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            password: this.state.Password,
            re_password: this.state.PasswordRe
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            track(`Reset Password of instructor, username = ${this.state.username} `,
                    'Instructor', 'Update')          
            document.getElementById("resetInstructorEdit").hidden=true;
            return;
          },
          error: (error) => {
            alert(`Unable to reset Password - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
          }
        })
      }  
      secondFunction();
    }
  }

  render() {
    
    
    return <div>
                <div id='headerPartInstructorEdit'>  
                  <Header id='HeaderInstructorEdit' as='h2' block={true}>
                      <Image size='large' circular src={this.state.imageURL} /> {this.state.firstName +" "+ this.state.lastName}
                  </Header>
                </div>
                <Divider horizontal/>
                
                <div id ='containerInstructorEdit'>
                  <div id='cardInstructorEdit'>
                    <CardTemplate
                    name={this.state.firstName +" "+ this.state.lastName}
                    img ={this.state.imageURL}
                    role = {this.state.role}
                    email = {this.state.email}
                    phoneNumber = {this.state.phone} 
                    onClick= {this.editProfilePictureShow}
                    ></CardTemplate>
                  </div>
                  <div id='addFormInstructorEdit'>
                    <Segment id="addInstructorEdit" inverted>
                      <Form id='formInstructorEdit' inverted>
                        <Form.Group>
                          <Form.Field className='formHalfWidthInstructorEdit'>
                              <label className="labelInstructorEdit">Username</label>
                              <input className ='input' defaultValue={this.state.username} onChange={this.handleUsername} readOnly/>
                          </Form.Field>
                          <Form.Field className='formHalfWidthInstructorEdit'>
                              <label className="labelInstructorEdit">User ID</label>
                              <input className ='input' type='number' value={this.state.userId} readOnly/>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='formHalfWidthInstructorEdit'>
                              <label className="labelInstructorEdit">First Name</label>
                              <input className ='input' defaultValue={this.state.firstName} onChange={this.handleFirstName}/>
                          </Form.Field>
                          <Form.Field className='formHalfWidthInstructorEdit'>
                              <label className="labelInstructorEdit">Last Name</label>
                              <input className ='input' defaultValue={this.state.lastName} onChange={this.handleLastName}/>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='fullWidthFormInstructorEdit'>
                              <label className="labelInstructorEdit">Address</label>
                              <input className ='input' defaultValue={this.state.address} onChange={this.handleAddress}/>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='formHalfWidthInstructorEdit'>
                              <label className="labelInstructorEdit">E-Mail</label>
                              <input className ='input' defaultValue={this.state.email} onChange={this.handleEmail}/>
                          </Form.Field>
                          <Form.Field className='formHalfWidthInstructorEdit'>
                              <label className="labelInstructorEdit">Phone</label>
                              <input className ='input' defaultValue={this.state.phone} onChange={this.handlePhone}/>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='fullWidthFormInstructorEdit'>
                            <label className="labelInstructorEdit">Is Hourly Based?</label>
                            <Dropdown
                              text={this.state.salaryBase}
                              selection
                              options={this.state.salaryBaseOptions}
                              onChange={this.handleChangeIsHourlyBased}
                            />
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='formHalfWidthInstructorEdit'>
                            <label className="labelInstructorEdit">{this.state.salaryBase === 'Hourly Based' ? 'Salary - Holds Class Alone (EGP/Hour)' : 'Salary (%)'}</label>
                            <input className ='input' type='number' value={this.state.salaryBase === 'Hourly Based' ? this.state.salaryHourAlone : this.state.salaryPercentage}
                            onChange={this.handleSalary}/>
                          </Form.Field>
                          <Form.Field className='formHalfWidthInstructorEdit' id='salary_partner'>
                            <label className="labelInstructorEdit"> Salary - Holds Class With Partner (EGP/Hour)</label>
                            <input className ='input' type='number' value= {this.state.salaryHourWithPartner}
                            onChange={this.handleSalaryPartner}/>
                          </Form.Field>
                        </Form.Group>
                                        
                        {this.createUI()}
                        <Button id="add_more_button_edit" className={'formHalfWidthInstructorEdit'} primary onClick={this.addClick.bind(this)}>add more</Button>
                        <Divider horizontal/>
                        <div>
                          <Button id='submitInstructorEdit1' content='Primary' floated='right' type='submit' onClick={this.instructorEditId}>Update</Button>
                          <Button id='submitInstructorEdit2' content='Secondary' floated='right' type='submit' onClick={this.instructorDeleteId}>Delete</Button>
                          <Button id='submitInstructorEdit3' content='Primary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                        </div>
                      </Form>
                    </Segment>
                  </div>
                </div>
                <Divider horizontal/>
                <div id='profilePictureChangeInstructorEdit'>
                  <Segment>
                    <Form>
                      <input type='file' accept="image/*" onChange={this.handleProfilePicture}></input>
                      <Button id='submitInstructorEdit4' onClick={this.changeProfilePicture}>Change Profile Picture</Button>
                    </Form>
                  </Segment>
                </div>
                <Divider horizontal/>
                
                <div >
                  <Button id='instructor_reset_password' size='mini' color='red' onClick={this.reset_password}> Reset Password </Button>
                </div>
                
                <div id='resetPasswordInstructorEdit'>
                <Segment id="resetInstructorEdit" inverted>
                  <Form id='formResetPasswordInstructor' inverted>
                    <Form.Group>
                      <Form.Field className='formHalfWidthInstructor'>
                        <label className="labelInstructor">Password</label>
                        <input className='input' type='password' placeholder='Password' onChange={this.handlePassword}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthInstructor'>
                        <label className="labelInstructor">Password Re-enter</label>
                        <input className='input' type='password' placeholder='Password Re-enter' onChange={this.handlePasswordRe}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitResetPasswordInstructor' floated='right' type='submit' onClick={this.resetPassword}>Submit</Button>
                  </Form>
                </Segment>
              </div>
              
           </div>
  }
  
}


export default InstructorEdit;
