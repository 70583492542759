import {format} from 'date-fns'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

/*var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}*/
//onClick={()=>navTo(`/students/${value}`)}

export const COLUMNS = [
    {
        width:30,
        Header:()=>'',
        accessor:'student.id',
        Cell:({value}) => (
            <Link to={`/students/${value}`}><Button circular icon='edit'/></Link>
        ),
        disableFilters: true
    },
    {
        Header: 'Student Name',
        accessor: 'student.full_name'
    },
    {
        Header: 'Student ID',
        accessor: 'student.user_id'
    },
    {
        Header: 'Subscription Type',
        accessor: 'is_membership'
    },
    {
        Header: 'Start Date',
        accessor: 'start_date',
        Cell:({value}) =>{return format(new Date(value), 'dd/MM/yyyy')}
    },
    {
        Header: 'Expiry Date',
        accessor: 'expiry_date',
        Cell:({value}) =>{return format(new Date(value), 'dd/MM/yyyy')}
    },
    {
        Header: 'Validity Duration',
        accessor: 'duration'
    },
    {
        Header: 'Is Expired',
        accessor: 'is_expired'
    },
    
    {
        Header: 'Package/Membership Name',
        accessor: 'package_membership_name'
    },
    {
        Header: 'Dance Category',
        accessor: 'dance_category.text'
    },
    {
        Header: 'Package Type',
        accessor: 'package_type'
    },
    {
        Header: 'Price (LE)',
        accessor: 'amount'
    },
    {
        Header: 'Discount',
        accessor: 'discount'
    },
    {
        Header: 'Price After Discount (LE)',
        accessor: 'amount_after_discount'
    },
    {
        Header: 'Paid Amount',
        accessor: 'paid'
    },
    {
        Header: 'Remaining Amount',
        accessor: 'remaining'
    },
    {
        Header: 'Number of Classes',
        accessor: 'number_classes'
    },
    {
        Header: 'Number of Remaining Classes',
        accessor: 'number_classes_remaining'
    }
]