//import {format} from 'date-fns'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
//import history from './history';

//var navTo = (uri) =>{
    //window.location.href = window.location.origin + uri;
  //  history.push(uri)
//}

//onClick={()=>navTo(`/students/${value}`)}

export const COLUMNS = [
    {
        width:30,
        Header:()=>'',
        accessor:'id',
        Cell:({value}) => (
            <Link to={`/students/${value}`}><Button circular icon='edit' /></Link>
        ),
        disableFilters: true
    },
    {
        maxWidth:10,
        Header: 'ID',
        accessor: 'user_id'
    },
    {
        Header: 'Name',
        accessor: 'full_name'
    },
    {
        Header: 'Address',
        accessor: 'address'
    },
    {
        Header: 'E-Mail',
        accessor: 'email'
    },
    {
        Header: 'Phone Number',
        accessor: 'phone'
    }
]