import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Dropdown, Icon, Table, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import subscription_icon from '../../../media/Images/subscription.png';
import '../../../stylesheets/studentSubscriptionPriv.css'
import $ from 'jquery';
import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';
import DetailedSubscription from './DetailedSubscription/DetailedSubscription';

class StudentSubscriptionPriv extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      //New Subscription
      userId:0,
      studentId:0,
      subscriptionTypeNew: 'Package',
      danceCatId:1,
      startDate: new Date(),
      packageChosen: {},
      membershipChosen: {},
      discountNew:0,
      amountAfterDiscount:0,
      paidAmount:0,
      remainingAmount:0,

      categories: [],
      memberships:[],
      membershipsOptions:[],
      packages:[],
      packagesOptions:[],
      
      subscriptions: [],
      subscriptionName: '',
      paymentMethod: 'Cash',
      paymentMethodEdit:'Cash',
      paymentMethodOption: [{
                  "key": "Cash",
                  "text": "Cash",
                  "value": "Cash"
              },
              {
                  "key": "Credit Card",
                  "text": "Credit Card",
                  "value": "Credit Card"
              }
              ],
      subscriptionTypes : [{
                  "key": "Package",
                  "text": "Package",
                  "value": "Package"
              },
              {
                  "key": "Membership",
                  "text": "Membership",
                  "value": "Membership"
              }],
      editSubscription:0,
      payRest:0,
      remainingAmountEdit:0,
      subscriptionsDetails: []
    }
    this.setEditSubscription = this.setEditSubscription.bind(this)
  }

  setEditSubscription(e){
    this.setState({editSubscription: e}, async()=>{
      this.handleEdit();
    });
  }

  componentDidMount(){
    this.setState({userId: this.props.match.params.user_id}, async () => {
      document.getElementById("addStudentSubscription").hidden=true;
      document.getElementById("edit_subscription").hidden=true;
      document.getElementById("addNew").hidden=true;

      document.getElementById("could_refund").hidden = true;
      document.getElementById("could_pay_rest").hidden = true;
      document.getElementById("could_turn_walk_in").hidden = true;
      document.getElementById("could_view_details").hidden = true;
      document.getElementById("view_details_comp").hidden = true;

      
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/categories/${this.state.userId}/users`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ categories: result.dance_categories })
            return;
          },
          error: (error) => {
            alert('Unable to load dance categories. Please try your request again')
            return;
          }
        });
  
        $.ajax({
          url: `/api/packages/${this.state.userId}/users`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ packages: result.packages }, async ()=>{
              let pckOptList = []
              for (var i=0;i<this.state.packages.length;i++){
                let pckopt = {}
                // eslint-disable-next-line
                if(this.state.packages[i].Dance_Category.key == this.state.danceCatId){
                  pckopt.key = this.state.packages[i].id
                  pckopt.value = this.state.packages[i].id
                  pckopt.text = this.state.packages[i].name
                  pckOptList.push(pckopt)
                }
              }
              this.setState({packagesOptions: pckOptList})
            })
            return;
          },
          error: (error) => {
            alert('Unable to load dance packages. Please try your request again')
            return;
          }
        });
        
        $.ajax({
          url: `/api/memberships/${this.state.userId}/users`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ memberships: result.memberships }, async ()=>{
              let memOptList = []
              for (var i=0;i<this.state.memberships.length;i++){
                let memopt = {}
                memopt.key = this.state.memberships[i].id
                memopt.value = this.state.memberships[i].id
                memopt.text = this.state.memberships[i].name
                memOptList.push(memopt)
                //console.log('memopt',memopt)
                //console.log('memOptList',memOptList)
              }
              this.setState({membershipsOptions: memOptList})
            })
            return;
          },
          error: (error) => {
            alert('Unable to load dance memberships. Please try your request again')
            return;
          }
        });
  
        $.ajax({
          url: `/api/students/${this.state.userId}/subs`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              subscriptions: result.subscriptions,
              studentId: result.student_id
            })
            return;
          },
          error: (error) => {
            alert('Unable to load subscriptions. Please try your request again')
            return;
          }
        });
      }
      secondFunction();
    });
  }
  
  new_subscription = ()=>{
    if (document.getElementById("addStudentSubscription").hidden){
      document.getElementById("addStudentSubscription").hidden=false;
    }else{
      document.getElementById("addStudentSubscription").hidden=true;
    } 
  }

  addSubscription = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/subscriptions/${this.state.userId}/students`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          student_id: this.state.studentId,
          subscription_type: this.state.subscriptionTypeNew,
          dance_cat_id: this.state.danceCatId,
          start_date: this.state.startDate,
          package_id: this.state.packageChosen.id,
          membership_id: this.state.membershipChosen.id,
          discount: this.state.discountNew,
          amount_after_discount: this.state.amountAfterDiscount,
          payment_method:this.state.paymentMethod,
          paid_amount: this.state.paidAmount,
          remaining_amount: this.state.remainingAmount
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          document.getElementById("addStudentSubscription").hidden=true;
          $.ajax({
            url: `/api/students/${this.state.userId}/subs`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              this.setState({ subscriptions: result.subscriptions })
              return;
            },
            error: (error) => {
              alert('Unable to load subscriptions. Please try your request again')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the subscription. Please check your inputs & try again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleSubscriptionName = (event) => {
    this.setState({subscriptionName: event.target.value})
  }
  
  

  handleChangeCategory = (event, {value}) => {
    this.setState({danceCatId: value}, async ()=>{
      let pckOptList = []
      for (var i=0;i<this.state.packages.length;i++){
        let pckopt = {}
        // eslint-disable-next-line
        if(this.state.packages[i].Dance_Category.key == this.state.danceCatId){
          pckopt.key = this.state.packages[i].id
          pckopt.value = this.state.packages[i].id
          pckopt.text = this.state.packages[i].name
          pckOptList.push(pckopt)
        }
      }
      this.setState({packagesOptions: pckOptList})
    })
  }

  handleStartDate = (event) => {
    this.setState({
        startDate: event.target.value
    }, async ()=>{
      //console.log(this.state.startDate)
    });
  }

  handleSubscriptionTypes = (event, {value}) => {
    this.setState({
      subscriptionTypeNew: value,
      packageChosen:{},
      membershipChosen:{},
      discountNew:0,
      amountAfterDiscount:0,
      paidAmount:0,
      remainingAmount:0
    }, async () =>{
      // eslint-disable-next-line
      document.getElementById("discount_reset").value = this.state.discountNew
      document.getElementById("paid_reset").value = this.state.paidAmount
      if (this.state.subscriptionTypeNew === 'Membership'){
        document.getElementById("cat").hidden=true;
      }else{
        document.getElementById("cat").hidden=false;
      }
    })
  }

  handleDiscount = (event) => {
    let discount = event.target.value/100
    let priceAfterDiscount = 0
    if (this.state.subscriptionTypeNew === 'Package'){
      priceAfterDiscount = this.state.packageChosen.amount * (1-discount)
    }else{
      priceAfterDiscount = this.state.membershipChosen.amount * (1-discount)
    }
    //console.log('discount', discount ,'priceAfterDiscount',priceAfterDiscount )
    this.setState({
      discountNew: discount,
      amountAfterDiscount: priceAfterDiscount,
      remainingAmount:priceAfterDiscount - this.state.paidAmount
    })
  }

  handleAmountPaid = (event) => {
    const paid = Number(event.target.value)
    const remaining = this.state.amountAfterDiscount - paid
    this.setState({
      paidAmount: paid,
      remainingAmount: remaining
    })
  }
  

  handlePackagesMemberhips = (event,{value}) => {
    //console.log('chosen membership/package',value)
    
      
    // eslint-disable-next-line
    if (this.state.subscriptionTypeNew =='Package'){
      for (var i=0;i<this.state.packages.length;i++){
        // eslint-disable-next-line
        if(this.state.packages[i].id == value){
          this.setState({
            packageChosen :this.state.packages[i], 
            membershipChosen:{id: null},
            amountAfterDiscount:this.state.packages[i].amount,
            remainingAmount:this.state.packages[i].amount,
            discountNew:0,
            paidAmount:0
          }, async ()=>{
            document.getElementById("discount_reset").value = this.state.discountNew
            document.getElementById("paid_reset").value = this.state.paidAmount
          })
          break
        }
      }
    }else{
      for (i=0;i<this.state.memberships.length;i++){
        // eslint-disable-next-line
        if(this.state.memberships[i].id == value){
          this.setState({
            membershipChosen :this.state.memberships[i], 
            packageChosen:{id: null},
            amountAfterDiscount:this.state.memberships[i].amount,
            remainingAmount:this.state.memberships[i].amount,
            discountNew:0,
            paidAmount:0
          }, async ()=>{
            document.getElementById("discount_reset").value = this.state.discountNew
            document.getElementById("paid_reset").value = this.state.paidAmount
          })
          break
        }
      }
    }
  }

  packageShow(){
    //show info of package
    return (
      <div className='info'>
        <label className ='infoLabel'>Chosen Package Info</label>
        <div className='infoForm'>
          <Form.Group>
            <Form.Field className='form1'>
              <label id="label">Package Price</label>
              <input defaultValue={this.state.packageChosen.amount}
                          readOnly/>
            </Form.Field>

            <Form.Field className='form1' id = 'cat'>
              <label id="label">Validity Duration (weeks)</label>
              <input defaultValue={this.state.packageChosen.duration_weeks}
                          readOnly/>
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field className='form1'>
              <label id="label">Number of Classes</label>
              <input defaultValue={this.state.packageChosen.number_classes}
                          readOnly/>
            </Form.Field>

            <Form.Field className='form1' id = 'cat'>
              <label id="label">Package Type</label>
              <input defaultValue={this.state.packageChosen.package_type}
                          readOnly/>
            </Form.Field>
          </Form.Group>
        </div>
      </div>
      )
  }

  membershipShow(){
    //show info of membership
      return (
      <div className='info'>
        <label className ='infoLabel'>Chosen Membership Info</label>
        <div className='infoForm'>
          <Form.Group>
            <Form.Field className='form1'>
              <label id="label">Membership Price</label>
              <input defaultValue={this.state.membershipChosen.amount}
                          readOnly/>
            </Form.Field>

            <Form.Field className='form1' id = 'cat'>
              <label id="label">Validity Duration (months)</label>
              <input defaultValue={this.state.membershipChosen.duration_months}
                          readOnly/>
            </Form.Field>
          </Form.Group>
        </div>
      </div>
      )
  }

  handleEdit(){
    let editSubscription = {}
    for(var i =0;i< this.state.subscriptions.length;i++){
      if(this.state.subscriptions[i].id === this.state.editSubscription){
        editSubscription = this.state.subscriptions[i];
        break;
      }
    }
    document.getElementById("could_refund").hidden = true;
    document.getElementById("could_pay_rest").hidden = true;
    document.getElementById("could_turn_walk_in").hidden = true;
    document.getElementById("could_view_details").hidden = true;
    document.getElementById("edit_subscription").hidden = true;
    document.getElementById("view_details_comp").hidden = true;
    
    
    if (this.state.editSubscription !== 0){
      document.getElementById("could_view_details").hidden = false;
      document.getElementById("could_view_details").scrollIntoView();
    }
    else{
      document.getElementById("could_refund").hidden = true;
      document.getElementById("could_pay_rest").hidden = true;
      document.getElementById("could_turn_walk_in").hidden = true;
      document.getElementById("could_view_details").hidden = true;
      document.getElementById("edit_subscription").hidden = true;
      document.getElementById("view_details_comp").hidden = true;
    }
  }

  noActionAlert(){
    if(this.state.editSubscription !==0){
      return(
      <input style={{width:'50%' , margin:'1%', padding:'1%', textAlign:'center'}} 
          readOnly defaultValue= 'No remaining amount to be paid & cannot refund subscription (already used)' />
      )
    }else{
      return(<></>)
    }
  }

  edit_subscription = ()=>{
    let editSubscription = {}
    for(var i =0;i< this.state.subscriptions.length;i++){
      if(this.state.subscriptions[i].id === this.state.editSubscription){
        editSubscription = this.state.subscriptions[i]
        break;
      }
    }
    this.setState({remainingAmountEdit:editSubscription.remaining}, async ()=>{
      if (document.getElementById("edit_subscription").hidden){
        document.getElementById("edit_subscription").hidden=false;
        document.getElementById("edit_subscription").scrollIntoView();
      }else{
        document.getElementById("edit_subscription").hidden=true;
      }
    })
  }

  handlePayRest = (event) => {
    this.setState({
      payRest: Number(event.target.value)
    }, async ()=> {
      //console.log('payrest after insersion',this.state.payRest)
    })
  }

  postWalkin = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/subscriptions/${this.state.editSubscription}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          edit_action: 'turn_walkin',
          pay_rest: this.state.payRest,
          payment_method_edit: this.state.paymentMethodEdit
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          document.location.reload()
          return;
        },
        error: (error) => {
          alert('Unable to turn to a walkin package subscription')
          return;
        }
      });
    }
    secondFunction();
  }


  postRefund = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/subscriptions/${this.state.editSubscription}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          edit_action: 'refund_deactivate',
          pay_rest: this.state.payRest,
          payment_method_edit: this.state.paymentMethodEdit
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          document.location.reload()
          return;
        },
        error: (error) => {
          alert('Unable to refund')
          return;
        }
      });
    }
    secondFunction();
  }

  postPayRest = (event) => {
    event.preventDefault();
    //console.log('payRest in post',this.state.payRest)
    let editSubscription = {}
    for(var i =0;i< this.state.subscriptions.length;i++){
      if(this.state.subscriptions[i].id === this.state.editSubscription){
        editSubscription = this.state.subscriptions[i]
        break
      }
    }
    //console.log('remaining',editSubscription.remaining)
    
    if(this.state.payRest > editSubscription.remaining){
      alert('Error: the amount paid is larger than the remaining amount')
    }
    else{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/subscriptions/${this.state.editSubscription}`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            edit_action: 'pay_rest',
            pay_rest: this.state.payRest,
            payment_method_edit: this.state.paymentMethodEdit
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            document.location.reload()
            return;
          },
          error: (error) => {
            alert('Unable to pay rest of price')
            return;
          }
        })
      }  
      secondFunction();
    }
  }

  handlePaymentMethod = (event,{value}) => {
    this.setState({paymentMethod: value},async ()=>{
      //console.log(this.state.paymentMethod)
    })
  }
  
  handlePaymentMethodEdit = (event,{value}) => {
    this.setState({paymentMethodEdit: value},async ()=>{
      //console.log(this.state.paymentMethodEdit)
    })
  }

  viewDetails = (event) => {
    event.preventDefault();
    if (document.getElementById("view_details_comp").hidden){
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/students/${this.state.studentId}/subscriptions/${this.state.editSubscription}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ subscriptionsDetails: result.subscriptions_details }, async ()=>{
              document.getElementById('view_details_comp').hidden=false;
              document.getElementById('view_details_comp').scrollIntoView();
            })
            return;
          },
          error: (error) => {
            alert('Unable to load detailed subscription. Please try your request again')
            return;
          }
        })
      }
      secondFunction();
    }else{
      document.getElementById("view_details_comp").hidden=true;
    }
  }

  render() {
    
    return <div>

              <div id='headerPartStudentSubscription'>
                <Header id='HeaderStudentSubscription' as='h2' block={true}>
                  <Image size='large' circular src={subscription_icon} /> Subscriptions
                </Header>
              </div>
              <Divider horizontal/>

              <div id = 'tableStudentSubscription'>
                  <BasicTable key ={this.state.subscriptions}  data = {this.state.subscriptions} editSubscription={this.state.editSubscription} setEditSubscription={this.setEditSubscription}/>
              </div>
              <Divider horizontal/>

              <div id='editButtons'>
                <Button.Group widths='1'>
                  <div id='could_view_details'>
                    <Button id='view_details' size='mini' color='black' onClick={this.viewDetails}>View Usage</Button>
                  </div>
                  <div id='could_pay_rest'>
                    <Button id='pay_rest' size='mini' color='black' onClick={this.edit_subscription}>Pay Rest of Price</Button>
                  </div>
                  <div id='could_refund'>
                    <Button id='refund' size='mini' color='black' onClick={this.postRefund}>Refund</Button>
                  </div>
                  <div id='could_turn_walk_in'>
                    <Button id='walk_in' size='mini' color='black' onClick={this.postWalkin}>Turn to Walkin Package</Button>
                  </div>
                </Button.Group>
              </div>


              <div id ='addNew'>
                <Button.Group widths='1'>
                  <Button id='calendar_button1' size='mini' color='red' onClick={this.new_subscription}> Add New Subscription </Button>
                </Button.Group>
              </div>


              <div id='addFormStudentSubscription'>
                <Segment id="addStudentSubscription" >
                  <Form id='formStudentSubscription' >
                    <Form.Group>
                      <Form.Field className='formHalfWidthStudentSubscription'>
                        <label id="label">Subscription Type</label>
                        <Dropdown
                          placeholder='Select the subscription type'
                          selection
                          options={this.state.subscriptionTypes}
                          onChange={this.handleSubscriptionTypes}
                        />
                      </Form.Field>

                      <Form.Field className='formHalfWidthStudentSubscription' id = 'cat'>
                        <label id="label">Dance Category</label>
                        <Dropdown
                          placeholder='Select the dance category'
                          selection
                          options={this.state.categories}
                          onChange={this.handleChangeCategory}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      
                      
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthStudentSubscription' id="date">
                        <label id="label">Start Date</label>
                        <DateTimePickerComponent
                          CssClass="customClass"
                          id='StartTime'
                          className ="e-field"
                          format='dd/MM/yy'
                          value={this.state.startDate}
                          onChange={this.handleStartDate}
                        />
                      </Form.Field>
                      <Form.Field className='formHalfWidthStudentSubscription'>
                        {/* eslint-disable-next-line*/}
                        <label id="label">{this.state.subscriptionTypeNew=='Package' ? 'Choose Package Type' : 'Choose Membership Type'}</label>
                        <Dropdown
                          placeholder={this.state.subscriptionTypeNew==='Package' ? 'Choose Package Type' : 'Choose Membership Type'}
                          selection
                          options={this.state.subscriptionTypeNew==='Package' ? this.state.packagesOptions : this.state.membershipsOptions}
                          onChange={this.handlePackagesMemberhips}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthStudentSubscription'>
                        <label id="label">Discount (%)</label>
                        <input id='discount_reset' type='number' placeholder='Discount (%)' onChange={this.handleDiscount}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthStudentSubscription'>
                        <label id="label">Price After Discount (EGP)</label>
                        <input type='number' value={this.state.amountAfterDiscount} readOnly/>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field className='formHalfWidthStudentSubscription'>
                        <label id="label">Payment Method</label>
                        <Dropdown
                          placeholder='Payment Method'
                          selection
                          options={this.state.paymentMethodOption}
                          onChange={this.handlePaymentMethod}
                        />
                      </Form.Field>
                      <Form.Field className='formHalfWidthStudentSubscription'>
                        <label id="label">Paid Amount (EGP)</label>
                        <input id='paid_reset' type='number' placeholder='Amount Paid in EGP' onChange={this.handleAmountPaid}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthStudentSubscription'>
                        <label id="label">Remaining Amount (EGP)</label>
                        <input type='number' value={this.state.remainingAmount} readOnly/>
                      </Form.Field>
                    </Form.Group>
                    
                    {this.state.membershipChosen === {} && this.state.packageChosen === {} ? {}: (this.state.subscriptionTypeNew ==='Package' ? this.packageShow() : this.membershipShow())}


                    <Button id='submit' floated='right' type='submit' onClick={this.addSubscription}>Submit</Button>
                  </Form>
                </Segment>
              </div>


              
                

                <div id = "edit_subscription">
                  <Divider horizontal>
                    <h4>
                      <Icon name='credit card' />
                      Pay Rest of Price
                    </h4>
                  </Divider>
                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Payment Method</Table.Cell>
                        <Table.Cell>
                          <Dropdown
                            placeholder='Payment Method'
                            selection
                            options={this.state.paymentMethodOption}
                            onChange={this.handlePaymentMethodEdit}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Payment Amount</Table.Cell>
                        <Table.Cell>
                          <input type='number' placeholder='Payment Amount' onChange={this.handlePayRest}/> {' EGP'}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>Total Remaining Amount</Table.Cell>
                        <Table.Cell>
                          {this.state.remainingAmountEdit}{' EGP'}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <div id='submit_hide_rent_div' >
                    <Button id='submit' floated='right' type='submit' onClick={this.postPayRest}>Submit</Button>
                  </div>
                </div>
                <div id='view_details_comp'>
                  <DetailedSubscription subscriptionsDetails={this.state.subscriptionsDetails}/>
                </div>    
      </div>
  }
  
}


export default StudentSubscriptionPriv;
