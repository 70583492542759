//import {format} from 'date-fns'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import {format} from 'date-fns'

/*var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}*/
//onClick={()=>navTo(`/instructors/${value}`)} 

export const COLUMNS = [
    {
        width:30,
        Header:()=>'',
        accessor:'id',
        Cell:(props) => {
            return(
                <div><Button circular icon='delete' content='Remove' onClick={()=> props.removeLeaves(props.row.values.id)}/></div>
            )
        },
        disableFilters: true
    },
    {
        Header: 'Start Day',
        accessor: 'start_day'
    },
    {
        Header: 'Start Date',
        accessor: 'start_date',
        Cell:({value}) =>{return format(new Date(value), 'dd/MM/yyyy')}
    },
    {
        Header: 'End Day',
        accessor: 'end_day'
    },
    {
        Header: 'End Date',
        accessor: 'end_date',
        Cell:({value}) =>{return format(new Date(value), 'dd/MM/yyyy')}
    },
    {
        Header: 'Number of Days',
        accessor: 'number_days'
    }
    // {
    //     Header: 'Name',
    //     accessor: 'leave.employee.name'
    // },
    // {
    //     Header: 'Title',
    //     accessor: 'leave.employee.title'
    // },
    // {
    //     Header: 'Year',
    //     accessor: 'leave.year'
    // },
    // {
    //     Header: 'Yearly Balance',
    //     accessor: 'leave.balance'
    // },
    // {
    //     Header: 'Taken',
    //     accessor: 'leave.taken'
    // },
    // {
    //     Header: 'Remaining',
    //     accessor: 'leave.remaining'
    // }
]