import React, { Component } from 'react';
import { Header, Image, Button, Divider, Form, Segment} from 'semantic-ui-react'
import '../../stylesheets/employeeEdit.css'
import $ from 'jquery';
import track from '../track';



class EmployeeEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      employeeId:0,
      name:'',
      balanceYear:21,
      email: '',
      phone: '',
      title:'',
      address:'',
      employee: {}      
    }
  }


  componentDidMount(){
    //document.getElementById('profile_picture_change').hidden= true;
    
    this.setState({employeeId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/employees/${this.state.employeeId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              employee: result.employee,
              name: result.employee.name,
              title: result.employee.title,
              balanceYear: result.employee.balance_year,
              address: result.employee.address,
              email: result.employee.email,
              phone: result.employee.phone
            })
            return ;
          },
          error: (error) => {
            alert('Unable to load employee. Please try your request again')
            return;
          }
        })
      }
      secondFunction();
    }) 
  }

  handleName = (event) =>{
    this.setState({name:event.target.value})
  }

  handleAddress = (event) =>{
    this.setState({address:event.target.value})
  }

  handleEmail = (event) =>{
    this.setState({email:event.target.value})
  }

  handlePhone = (event) =>{
    this.setState({phone:event.target.value})
  }

  handleBalanceYear = (event) =>{
    this.setState({balanceYear:event.target.value})
  }

  handleTitle = (event) =>{
    this.setState({title:event.target.value})
  }
  
  employeeDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/employees/${this.state.employeeId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          name: this.state.name,
          address: this.state.address,
          email: this.state.email,
          phone: this.state.phone,
          balance_year: this.state.balanceYear,
          title: this.state.title
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`deleted employee, username = ${this.state.username} `,
                    'Employee', 'Delete')        
          
          this.props.history.push('/employees');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/employees/${this.state.employeeId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              name: this.state.name,
              address: this.state.address,
              email: this.state.email,
              phone: this.state.phone,
              balance_year: this.state.balanceYear,
              title: this.state.title
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              track(`deleted employee, username = ${this.state.username} `,
                    'Employee', 'Delete')        
              this.props.history.push('/employees');
              return;
            },
            error: (error) => {
              alert('Unable to delete employee. Please try your request again')
              return;
            }
          })
          return;
        }
      })  
    }
    secondFunction();
  }

  
  employeeEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/employees/${this.state.employeeId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          name: this.state.name,
          address: this.state.address,
          email: this.state.email,
          phone: this.state.phone,
          balance_year: this.state.balanceYear,
          title: this.state.title
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Updated employee, username = ${this.state.username} `,
                    'Employee', 'Update')
          
          this.props.history.push('/employees');
          return;
        },
        error: (error) => {
          alert(`Unable to update employee - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
        }
      })
    }  
    secondFunction();
  }


  cancelId = ()=>{
    //this.navTo(`/employees`)
    this.props.history.push('/employees');
  }

  
  render() {
    
    return <div>
                <div id='headerPartEmployeeEdit'>  
                  <Header id='HeaderEmployeeEdit' as='h2' block={true}>
                      {this.state.name}
                  </Header>
                </div>
                <Divider horizontal/>
                
                <div id ='containerEmployeeEdit'>
                  <div id='addFormEmployeeEdit'>
                    <Segment id="addEmployeeEdit" inverted>
                      <Form id='formEmployeeEdit' inverted>
                      <Form.Group>
                        <Form.Field className='fullWidthFormEmployeeEdit'>
                            <label className="labelEmployeeEdit">Name</label>
                            <input className ='input' defaultValue={this.state.name} onChange={this.handleName} readOnly/>
                        </Form.Field>
                        </Form.Group>
                        <Form.Group>
                        <Form.Field className='formHalfWidthEmployeeEdit'>
                          <label className="labelEmployeeEdit">Title</label>
                          <input className ='input' defaultValue={this.state.title}
                          onChange={this.handleTitle}/>
                        </Form.Field>
                        <Form.Field className='formHalfWidthEmployeeEdit'>
                            <label className="labelEmployeeEdit">Yearly Balance</label>
                            <input className ='input' type='number' value={this.state.balanceYear} onChange={this.handleBalanceYear}/>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field className='fullWidthFormEmployeeEdit'>
                            <label className="labelEmployeeEdit">Address</label>
                            <input className ='input' defaultValue={this.state.address} onChange={this.handleAddress}/>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field className='formHalfWidthEmployeeEdit'>
                            <label className="labelEmployeeEdit">E-Mail</label>
                            <input className ='input' defaultValue={this.state.email} onChange={this.handleEmail}/>
                        </Form.Field>
                        <Form.Field className='formHalfWidthEmployeeEdit'>
                            <label className="labelEmployeeEdit">Phone</label>
                            <input className ='input' defaultValue={this.state.phone} onChange={this.handlePhone}/>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        
                      </Form.Group>
                      <Divider horizontal/>  
                        <div>
                          <Button id='submitEmployeeEdit1' content='Primary' floated='right' type='submit' onClick={this.employeeEditId}>Update</Button>
                          <Button id='submitEmployeeEdit2' content='Secondary' floated='right' type='submit' onClick={this.employeeDeleteId}>Delete</Button>
                          <Button id='submitEmployeeEdit3' content='Primary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                        </div>
                      </Form>
                    </Segment>
                  </div>
                </div>
                <Divider horizontal/>             
           </div>
  }
  
}


export default EmployeeEdit;
