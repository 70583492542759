//import {format} from 'date-fns'
import { Button} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

/*var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}*/
//onClick={()=>navTo(`/memberships/${value}`)}

export const COLUMNS = [
    {
        width:30,
        Header:()=>'',
        accessor:'id',
        Cell:({value}) => (
            <Link to={`/memberships/${value}`}><Button circular icon='edit' content='Edit'/></Link>
        ),
        disableFilters: true
    },
    {
        Header: 'Membership Name',
        accessor: 'name'
    },
    {
        Header: 'Membership Price',
        accessor: 'amount'
    },
    {
        Header: 'Validity (Months)',
        accessor: 'duration_months'
    }
]