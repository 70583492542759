import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import membership_icon from '../../media/Images/membership.png';
import '../../stylesheets/App.css';
import '../../stylesheets/membership.css'
import $ from 'jquery';
import track from '../track';

class Membership extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      memberships: [],
      membershipName: '',
      amount: 0,
      durationMonths: 0,
      membershipShare:0
    }
  }

  componentDidMount(){
    document.getElementById("addMembership").hidden=true;
    document.getElementById("edit_membership_share").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/membership-shares`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ membershipShare: Number(result.membership_share.membership_class_share) })
          return;
        },
        error: (error) => {
          alert('Unable to load membership class share. Please try your request again')
          return;
        }
      })
      $.ajax({
        url: `/api/memberships`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ memberships: result.memberships })
          return;
        },
        error: (error) => {
          alert('Unable to load dance memberships. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  new_membership = ()=>{
    if (document.getElementById("addMembership").hidden){
      document.getElementById("addMembership").hidden=false;
      document.getElementById("addMembership").scrollIntoView();
    }else{
      document.getElementById("addMembership").hidden=true;
      document.getElementById("tableMembership").scrollIntoView();
    } 
  }

  show_membership_share = ()=>{
    if (document.getElementById("edit_membership_share").hidden){
      document.getElementById("edit_membership_share").hidden=false;
      document.getElementById("edit_membership_share").scrollIntoView();
    }else{
      document.getElementById("edit_membership_share").hidden=true;
      document.getElementById("tableMembership").scrollIntoView();
    }
  }

  editMembershipShare = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/membership-shares`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          membership_share: this.state.membershipShare
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Updated membership class share to be ${this.state.membershipShare}`,
                    'Membership', 'Update')
          document.getElementById("edit_membership_share").hidden=true;
          document.getElementById("tableMembership").scrollIntoView();
          return;
        },
        error: (error) => {
          alert('Unable to update the membership class share. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  addMembership = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/memberships`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: this.state.membershipName,
          amount: this.state.amount,
          duration_months: this.state.durationMonths
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Added new membership type named ${this.state.membershipName}`,
                    'Membership', 'Add')
          
          $.ajax({
            url: `/api/memberships`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              this.setState({ memberships: result.memberships },async ()=> {
                document.getElementById("addMembership").hidden=true;
                document.getElementById("tableMembership").scrollIntoView();
              })
              return;
            },
            error: (error) => {
              alert('Unable to load roams. Please try your request again')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the membership. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleMembershipName = (event) => {
    this.setState({membershipName: event.target.value})
  }
  
  handleAmount = (event) => {
    this.setState({amount: event.target.value})
  }
  
  handleValidity = (event) => {
    this.setState({durationMonths: event.target.value})
  }

  handleMembershipShare = (event) => {
    this.setState({membershipShare: Number(event.target.value)})
  }

  
  render() {
    
    return <div>
              <div id='headerPartMembership'>
                <Header id='HeaderMembership' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={membership_icon} /> Dance Memberships
                </Header>
              </div>
              <Divider horizontal/>

              <div >
                <Button id='membership_button1' size='mini' color='red' onClick={this.new_membership}> Add a Membership </Button>
                <Button id='membership_button2' size='mini' color='red' onClick={this.show_membership_share}> Edit Membership Class Share</Button>
              </div>
              <Divider horizontal/>

              <div id = 'tableMembership'>
                  <BasicTable key ={this.state.memberships}  data = {this.state.memberships} />
              </div>
              <Divider horizontal/>

              <div id='addFormMembership'>
                <Segment id="addMembership" inverted>
                  <Form id='formMembership' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormMembership'>
                        <label className="labelMembership">Membership Name</label>
                        <input className ='input' placeholder='Membership Name' onChange={this.handleMembershipName}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthMembership'>
                        <label className="labelMembership">Membership Price</label>
                        <input className ='input' type='number' placeholder='Membership Price' onChange={this.handleAmount}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthMembership'>
                        <label className="labelMembership">Validity (Months)</label>
                        <input className ='input' type='number' placeholder='Validity (Months)' onChange={this.handleValidity}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitMembership' floated='right' type='submit' onClick={this.addMembership}>Submit</Button>
                  </Form>
                </Segment>
              </div>

              <div id='addFormMembership2'>
                <Segment id="edit_membership_share" inverted>
                  <Form id='formMembership2' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormMembership'>
                        <label className="labelMembership">Membership Class Share</label>
                        <input className ='input' type='number' value={this.state.membershipShare} onChange={this.handleMembershipShare}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitMembership2' floated='right' type='submit' onClick={this.editMembershipShare}>Submit</Button>
                  </Form>
                </Segment>
              </div>
           </div>
  }
  
}


export default Membership;
