import React, { Component } from 'react';
import { Divider, Image, Button} from 'semantic-ui-react'
import {BasicTableStud} from './BasicTableStud';
import inst_icon from '../../../media/Images/student.png';
import '../../../stylesheets/classInfoStudent.css'
//import $ from 'jquery';


class MainStudentsClass extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      //instructorsToAdd:[]
    }
    //this.setInstructorsToAdd = this.setInstructorsToAdd.bind(this)
  }

  /*setInstructorsToAdd(e){
    this.setState({instructorsToAdd: e})
  }*/

  componentDidMount(){
    
  }
  navTo = (uri) =>{
    this.props.history.push(uri);
  }

  render() {
    
    return <div>
                <div id = 'headerPartClassInfoStudent'>
                  <Divider horizontal>
                    <h4 id='HeaderClassInfoStudent'>
                      <Image id='iconClassInfoStudent' size='large' src={inst_icon} />
                      Students
                    </h4>
                  </Divider>
                </div>
                <Divider horizontal/>

                <div id='tableClassInfoStudent'>
                    <BasicTableStud key ={this.props.mainReg}  data = {this.props.mainReg} classId={this.props.classId} history={this.props.history} refreshToken={this.props.refreshToken} reloadClass={this.props.reloadClass}/>
                    <Divider horizontal/>
                    <Button id='stud_add_button1' size='mini' color='red' onClick={() => { this.navTo(`/classes/${this.props.classId}/students`)}}> Register Student </Button>
                </div>
                <Divider horizontal/>
                
           </div>
  }
  
}


export default MainStudentsClass;
