import React, { Component } from 'react';
import { Divider, Image} from 'semantic-ui-react'
import {BasicTableStud} from './BasicTableStud';
import inst_icon from '../../../../media/Images/student.png';
import '../../../../stylesheets/classInfoInstPrivStudent.css'
//import $ from 'jquery';


class MainStudentsClass extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      
    }
  }

  componentDidMount(){
    
  }
  navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }

  render() {
    
    return <div>
                <div id = 'headerPartClassInfoInstPrivStudent'>
                  <Divider horizontal>
                    <h4 id='HeaderClassInfoInstPrivStudent'>
                      <Image id='iconClassInfoInstPrivStudent' size='large' src={inst_icon} />
                      Registered Students
                    </h4>
                  </Divider>
                </div>
                <Divider horizontal/>

                <div id='tableClassInfoInstPrivStudent'>
                    <BasicTableStud key ={this.props.mainReg}  data = {this.props.mainReg} classId={this.props.classId}/>
                </div>
                <Divider horizontal/>
           </div>
  } 
}

export default MainStudentsClass;
