import React from 'react'
import { Menu } from 'semantic-ui-react'
import '../../stylesheets/Header.css';
import { AdminMenuAuth } from './AdminMenuAuth';
import { PartnerMenuAuth } from './PartnerMenuAuth';
import {InstructorMenuAuth} from './InstructorMenuAuth'
import {StudentMenuAuth} from './StudentMenuAuth'
import {PublicMenuAuth} from './PublicMenuAuth'
import {PartnerInstructorMenuAuth} from './PartnerInstructorMenuAuth'

export const LogedInMenu = (props) =>{
    /*const navTo = (uri) =>{
        window.location.href = window.location.origin + uri;
    }*/
    //id='dot_style'
            
    return(
        <>
            <StudentMenuAuth token ={props.token}/>
            <AdminMenuAuth token ={props.token}/>
            <PartnerMenuAuth token ={props.token}/>
            <PartnerInstructorMenuAuth token ={props.token}/>
            <InstructorMenuAuth token ={props.token}/>
            <PublicMenuAuth token ={props.token}/>
        </>
    )
}