import React from 'react'
//import { Dropdown, Icon, Input, Menu } from 'semantic-ui-react'
import '../../stylesheets/Header.css';
//import {useAuth0} from '@auth0/auth0-react';
import {PartnerMenu} from './PartnerMenu' 
import { useJwt } from "react-jwt";


export const PartnerMenuAuth = (props) =>{
    let { decodedToken, isExpired } = useJwt(props.token);
    
    let isAuthenticatedPartner = false;

    if (typeof decodedToken !== 'undefined' && decodedToken !== null && isExpired === false){
        if(decodedToken.is_partner === true && decodedToken.is_admin === false && decodedToken.is_instructor === false && decodedToken.is_student === false){
            isAuthenticatedPartner = true;
            sessionStorage.setItem("user_id",decodedToken.user_id);
        }
        else{
            isAuthenticatedPartner = false
        }
    }else{
        isAuthenticatedPartner = false
    }

    return (isAuthenticatedPartner ? <PartnerMenu user_id={decodedToken.user_id} /> :<></>);
}