import React, { Component } from 'react';
import { Header, Image, Table, Divider, Icon, Dropdown, Button } from 'semantic-ui-react'
import '../../stylesheets/classInfo.css';
import $ from 'jquery';
import class_icon from '../../media/Images/class.png';
import InstructorsClass from './InstructorsClass/InstructorsClass'
import MainStudentsClass from './MainStudentsClass/MainStudentsClass'
import StudentsRequestsClass from './StudentsRequestsClass/StudentsRequestsClass'

class ClassInfo extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      classId:0,
      classInfo:{},
      classInstructors:[],
      mainReg: [],
      studentsRequests: [],

      requestUserId:0,
      responseOptions: [{
        key: 'Accept',
        text: 'Accept',
        value: 'accept'
      },
      {
        key: 'Reject',
        text: 'Reject',
        value: 'reject'
      }],
      response:'',
      rejectionReason:'',

      level_new:0,
      level:{},
      levelOptions:[]
    }
    this.setResponse = this.setResponse.bind(this);
    this.removeResponse = this.removeResponse.bind(this);
  }

  setResponse(classId_i, userId){
    document.getElementById('response').hidden = false;
    document.getElementById('response').scrollIntoView();
    this.setState({
      requestUserId: userId,
      classId:classId_i
    })
  }

  removeResponse(){
    document.getElementById('response').hidden = true;
    //document.getElementById('requestComp').scrollIntoView();
    this.setState({
      requestUserId: 0
    });
  }

  reloadClass = async () => {
    const result = await this.props.refreshToken()
    // do something else here after firstFunction completes
    $.ajax({
      url: `/api/classes/${this.state.classId}`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {   //Include the bearer token in header
        xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
      },
      success: (result) => {
        this.setState({
          classInfo:result.class_info,
          classInstructors: result.instructors,
          mainReg: result.main_students,
          studentsRequests: result.student_requests
        })
        return;
      },
      error: (error) => {
        alert('Unable to load class info. Please try your request again')
        return;
      }
    });

    $.ajax({
      url: `/api/levels`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {   //Include the bearer token in header
        xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
      },
      success: (result) => {
        this.setState({
          levelOptions:result.levels
        })
        return;
      },
      error: (error) => {
        alert('Unable to load the levels info. Please try your request again')
        return;
      }
    });

    $.ajax({
      url: `/api/classes/${this.state.classId}/levels`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {   //Include the bearer token in header
        xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
      },
      success: (result) => {
        this.setState({
          level:result.level,
          level_new: result.level.key
        })
        return;
      },
      error: (error) => {
        alert('Unable to load the class level info. Please try your request again')
        return;
      }
    });  
  }



  componentDidMount(){
    document.getElementById('response').hidden = true;
    document.getElementById('rejection').hidden = true;
    document.getElementById('requestComp').hidden = true;
    this.setState({
      classId: this.props.match.params.id
    }, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/classes/${this.state.classId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              classInfo:result.class_info,
              classInstructors: result.instructors,
              mainReg: result.main_students,
              studentsRequests: result.student_requests
            })
            return;
          },
          error: (error) => {
            alert('Unable to load class info. Please try your request again')
            return;
          }
        });
  
        $.ajax({
          url: `/api/levels`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              levelOptions:result.levels
            })
            return;
          },
          error: (error) => {
            alert('Unable to load the levels info. Please try your request again')
            return;
          }
        });
  
        $.ajax({
          url: `/api/classes/${this.state.classId}/levels`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              level:result.level,
              level_new: result.level.key
            })
            return;
          },
          error: (error) => {
            alert('Unable to load the class level info. Please try your request again')
            return;
          }
        });  
      }  
      secondFunction();
    });
    document.getElementById('headerPartClassInfo').scrollIntoView();
  }

  handleResponse = (event, {value}) => {
    this.setState({response: value},async ()=>{
      if(this.state.response === 'accept'){
        document.getElementById('rejection').hidden = true;
      }else{
        document.getElementById('rejection').hidden = false;
      }
    })
  }

  handleLevel = (event, {value}) => {
    this.setState({level_new: value},async ()=>{})
  }

  handleRejection = (event) => {
    this.setState({rejectionReason: event.target.value})
  }

  handleSubmitLevel = () => {
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/classes/${this.state.classId}/levels`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          level: this.state.level_new
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          $.ajax({
            url: `/api/classes/${this.state.classId}/levels`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              this.setState({
                level:result.level,
                level_new: result.level.key
              })
              return;
            },
            error: (error) => {
              alert('Unable to load the class level info. Please try your request again')
              return;
            }
          });
          return;
        },
        error: (error) => {
          alert("Unable to update the level of class. Please try again")
          return;
        }
      })
    }
    secondFunction();
  }
  

  handleSubmitResponse = () => {
    //document.getElementById('response').hidden = true;
    //document.getElementById('requestComp').scrollIntoView();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/classes/${this.state.classId}/studentsreqs/${this.state.requestUserId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type: this.state.response,
          rejection_reason: this.state.rejectionReason
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          document.getElementById('response').hidden = true;
          document.getElementById('requestComp').scrollIntoView();
          return;
        },
        error: (error) => {
          alert("Unable to respond to student's registeration request of this class. Please try again")
          return;
        }
      })  
    }
    secondFunction();
    window.location.reload();
  }

  render() {
      
    return  <div>
              <div id='headerPartClassInfo'>
                <Header id='HeaderClassInfo' as='h2' block={true} >
                  <Image size='large' circular src={class_icon} /> Class Information
                </Header>
              </div>
              <Divider horizontal/>

              <div id ='class_description'>
                <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={2}>Dance</Table.Cell>
                        <Table.Cell>
                          {this.state.classInfo?.dance?.text}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Description</Table.Cell>
                        <Table.Cell>
                          {this.state.classInfo.description}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Time{this.state.classInfo?.slots_count === 2 ? ' 1':''}</Table.Cell>
                        <Table.Cell>
                          {this.state.classInfo?.slot1}
                        </Table.Cell>
                      </Table.Row>
                      {this.state.classInfo?.slots_count === 2 ? <>
                        <Table.Row>
                        <Table.Cell width={2}>Time 2</Table.Cell>
                        <Table.Cell>
                          {this.state.classInfo?.slot2}
                        </Table.Cell>
                      </Table.Row>
                      </>:''}
                      <Table.Row>
                        <Table.Cell width={2}>Duration</Table.Cell>
                        <Table.Cell>
                          {this.state.classInfo.duration_minutes} minutes
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Is Fixed</Table.Cell>
                        <Table.Cell>
                          {this.state.classInfo.Is_fixed? "Fixed Class":"Onetime Class"}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Weekly Slots</Table.Cell>
                        <Table.Cell>
                          {this.state.classInfo.slots_count == 2? "Twice a week":this.state.classInfo.slots_count == 1? "Once a week":'-'}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={2}>Level</Table.Cell>
                        <Table.Cell>
                          {this.state.level.text}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                </Table>
              </div>
              <Divider horizontal/>

              <div>
                <InstructorsClass instructors={this.state.classInstructors} classId={this.state.classId} history={this.props.history} refreshToken={this.props.refreshToken} reloadClass={this.reloadClass.bind(this)}/>
              </div>

              <div>
                <MainStudentsClass mainReg={this.state.mainReg} classId={this.state.classId} history={this.props.history} refreshToken={this.props.refreshToken} reloadClass={this.reloadClass.bind(this)}/>
              </div>

              <div id='requestComp'>
                <StudentsRequestsClass studentsRequests={this.state.studentsRequests} classId={this.state.classId} history={this.props.history} refreshToken={this.props.refreshToken} setResponse={this.setResponse} removeResponse={this.removeResponse}/>
              </div>

              <div id='response'>
                <Divider horizontal>
                  <Header as='h4'>
                    <Icon name='registered' />
                    Response to Student Request
                  </Header>
                </Divider>
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={2}>Response</Table.Cell>
                      <Table.Cell>
                      <Dropdown
                        placeholder='Accept / Reject'
                        selection
                        options={this.state.responseOptions}
                        onChange={this.handleResponse}
                      />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <div id='rejection'>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={2}>Rejection reason</Table.Cell>
                        <Table.Cell>
                          <input id ='input' placeholder='Rejection reason' onChange={this.handleRejection}/>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div >
                  <Button id='submit_response' size='mini' color='red' onClick={this.handleSubmitResponse}> Confirm </Button>
                </div>
              </div>

              
              <div id='levelTable'>
                <Divider horizontal>
                  <h4>
                    <Icon name='level up alternate' />
                    Edit Class Level
                  </h4>
                </Divider>
                <Table definition>
                  <Table.Body>
                  <Table.Row>
                      <Table.Cell width={2}>Current Level</Table.Cell>
                      <Table.Cell>
                        {this.state.level.text}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={2}>Choose The New Level</Table.Cell>
                      <Table.Cell>
                      <Dropdown
                        placeholder='Choose The New Level'
                        selection
                        options={this.state.levelOptions}
                        onChange={this.handleLevel}
                      />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Button id='classInfo_button1' size='mini' color='red' onClick={this.handleSubmitLevel}> Update Level </Button>
              </div>
            </div>
  }
  
}


export default ClassInfo;
