import React, { Component } from 'react';
import { Header, Image, Button, Table, Divider, Icon } from 'semantic-ui-react'
import '../../../stylesheets/App.css';
import '../../../stylesheets/student.css';
import $ from 'jquery';
import InstructorsClass from './InstructorsClass/InstructorsClass'
import inst_icon from '../../../media/Images/instructor_icon.png';

class ClassInfoPublic extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      classId:1,
      classInfo:{},
      classInstructors:[],
      firstName:'',
      lastName:'',
      email:'',
      phone:'',
      password:'',
      passwordRe:'',
      //login
      usernameLogin:'',
      passwordLogin:''
    }
  }

  componentDidMount(){
    document.getElementById("register").hidden=true;
    document.getElementById("quick_register").hidden=true;
    document.getElementById("quick_login").hidden=true;
    this.setState({
      classId: this.props.match.params.id
    }, async () => {
      $.ajax({
        url: `/classes/${this.state.classId}`, //TODO: update request URL
        type: "GET",
        success: (result) => {
          this.setState({
            classInfo:result.class_info,
            classInstructors: result.instructors
            //mainReg: result.main_students
          })
          return;
        },
        error: (error) => {
          alert('Unable to load class info. Please try your request again')
          return;
        }
      })
    })
  }

  handleFirstName = (event) => {
    this.setState({firstName: event.target.value},async ()=>{});
  }

  handleLastName = (event) => {
    this.setState({lastName: event.target.value},async ()=>{});
  }

  handleEmail = (event) => {
    this.setState({email: event.target.value},async ()=>{});
  }

  handlePhone = (event) => {
    this.setState({phone: event.target.value},async ()=>{});
  }
  
  handlePassword = (event) =>{
    this.setState({password:event.target.value})
  }

  handlePasswordRe = (event) =>{
    this.setState({passwordRe:event.target.value})
  }
  
  requestClassReg = ()=>{
    if (document.getElementById("register").hidden){
      document.getElementById("register").hidden=false;
      document.getElementById("register").scrollIntoView();
    }else{
      document.getElementById("register").hidden=true;
      document.getElementById("quick_login").hidden=true;
      document.getElementById("quick_register").hidden=true;
      document.getElementById("header_id").scrollIntoView();
    }
  }

  logIn = ()=>{
    if (document.getElementById("quick_login").hidden){
      document.getElementById("quick_login").hidden=false;
      document.getElementById("quick_login").scrollIntoView();
      document.getElementById("quick_register").hidden=true;
    }else{
      document.getElementById("quick_login").hidden=true;
      document.getElementById("register").scrollIntoView();
    }
  }

  requestClassRegQuick = ()=>{
    if (document.getElementById("quick_register").hidden){
      document.getElementById("quick_register").hidden=false;
      document.getElementById("quick_register").scrollIntoView();
      document.getElementById("quick_login").hidden=true;
    }else{
      document.getElementById("quick_register").hidden=true;
      document.getElementById("register").scrollIntoView();
    }
  }

  handleSubmitQuickRegistration = (event)=>{
    event.preventDefault();
    let x = 0;
    if(this.state.firstName !== ''){x = x+1;}
    if(this.state.lastName !== ''){x = x+1;}
    if(x < 1){alert("Please enter your name to continue with the registeration request in class")}
    else if(this.state.email === '' ){
      alert("Please enter your E-mail to continue with the registeration request in class")
    }
    else if(this.state.phone === '' ){
      alert("Please enter your phone number to continue with the registeration request in class")
    } 
    else if(this.state.password === '' || this.state.passwordRe === '' || this.state.password !== this.state.passwordRe ){
      alert("Please check your password to continue with the registeration request in class")
    } else {
      $.ajax({
        url: `/students`, //TODO: update request URL
        type: "POST",
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          username: this.state.email,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          password: this.state.password,
          re_password: this.state.passwordRe,
          address: '',
          email: this.state.email,
          phone: this.state.phone
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          $.ajax({
            url: `/logins`, //TODO: update request URL
            type: "POST",
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              username: this.state.email,
              password: this.state.password
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              sessionStorage.setItem("access_token",result.access_token);
              this.props.setToken(result.access_token)
              let y = sessionStorage.getItem("user_id");
              $.ajax({
                url: `/classes/${this.state.classId}/studentsreqs/${y}`, //TODO: update request URL
                type: "POST",
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify({
                  type:'add',
                  rejection_reason:''
                }),
                xhrFields: {
                  withCredentials: true
                },
                crossDomain: true,
                success: (result) => {
                  this.props.history.push(`/students/${y}/classes/${this.state.classId}`);
                  return;
                },
                error: (error) => {
                  alert('Unable to request registeration of this class. Please try your request again')
                  return;
                }
              })
              
              return;
            },
            error: (error) => {
              alert('Unable to Login. Please check your username / password')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the student. Please try your request again')
          return;
        }
      })
    }
  }

  //login
  handleUsernameLogin = (event) => {
    this.setState({usernameLogin:event.target.value})
  }

  handlePasswordLogin = (event) => {
    this.setState({passwordLogin: event.target.value})
  }

  handleLogin = () => {
    $.ajax({
      url: `/logins`, //TODO: update request URL
      type: "POST",
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({
        username: this.state.usernameLogin,
        password: this.state.passwordLogin
      }),
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true,
      success: (result) => {
        sessionStorage.setItem("access_token",result.access_token);
        this.props.setToken(result.access_token)
        let y = sessionStorage.getItem("user_id");
        $.ajax({
          url: `/classes/${this.state.classId}/studentsreqs/${y}`, //TODO: update request URL
          type: "GET",
          success: (result) => {
            if (result.student_in_class || result.is_rejected)
            {
              //console.log("here")
              this.props.history.push(`/students/${y}/classes/${this.state.classId}`);

            }else if (result.request_exist){
              this.props.history.push(`/students/${y}/classes/${this.state.classId}`);
            } else{
              //
              //console.log("here but should not")
              $.ajax({
                url: `/classes/${this.state.classId}/studentsreqs/${y}`, //TODO: update request URL
                type: "POST",
                dataType: 'json',
                contentType: 'application/json',
                data: JSON.stringify({
                  type:'add',
                  rejection_reason:''
                }),
                xhrFields: {
                  withCredentials: true
                },
                crossDomain: true,
                success: (result) => {
                  this.props.history.push(`/students/${y}/classes/${this.state.classId}`);
                  return;
                },
                error: (error) => {
                  alert('Unable to request registeration of this class. Please try your request again')
                  return;
                }
              })
              // 
            }
            return;
          },
          error: (error) => {
            alert('Unable to check if class in reg request. Please try your request again')
            return;
          }
        })
        return;
      },
      error: (error) => {
        alert('Unable to Login. Please check your username / password')
        return;
      }
    })    
  }



  render() {
    
    return  <div>
              <div></div> 
              <Header id='header_id' as='h2'>
                  <Image circular src={inst_icon} /> Class Instructors
              </Header>
              
              <div style={{margin:"40px"}}></div>
              <div>
                <InstructorsClass instructors={this.state.classInstructors} classId={this.state.classId}/>
              </div>

              <div style={{margin:"40px"}}></div>

              <div id='studReg'>
                <Button id='studRegButton' size='mini' color='red' onClick={this.requestClassReg}> Request to Register in Class</Button>
              </div>

              <div id ='register'>
              <Divider horizontal>
                  <Header as='h4'>
                    <Icon name='signup' />
                    Registeration Options
                  </Header>
                </Divider>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell textAlign='center' width={2}>
                      <Button id='login_signup' size='mini' color='red' onClick={this.logIn}> Log In</Button>
                    </Table.Cell>
                    <Table.Cell textAlign='center' width={2}>
                      <Button id='quick_reg' size='mini' color='red' onClick={this.requestClassRegQuick}> Quick Registeration</Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              </div>
              
              <div id ='quick_register'>
              <Divider horizontal>
                  <Header as='h4'>
                    <Icon name='signup' />
                    Quick Registeration
                  </Header>
                </Divider>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={3}>
                      First Name
                    </Table.Cell>
                    <Table.Cell>
                      <input id ='input' placeholder='Enter your first name' onChange={this.handleFirstName}/>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={3}>
                      Last Name
                    </Table.Cell>
                    <Table.Cell>
                      <input id ='input' placeholder='Enter your last name' onChange={this.handleLastName}/>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={3}>
                      E-mail
                    </Table.Cell>
                    <Table.Cell>
                      <input id ='input' placeholder='e.g. johnsmith@gmail.com' onChange={this.handleEmail}/>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={3}>
                      Phone
                    </Table.Cell>
                    <Table.Cell>
                      <input id ='input' placeholder='Enter your Phone Number' onChange={this.handlePhone}/>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={3}>
                      Password
                    </Table.Cell>
                    <Table.Cell>
                      <input id ='input' type='password' placeholder='Password' onChange={this.handlePassword}/>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={3}>
                      Password Re-enter
                    </Table.Cell>
                    <Table.Cell>
                      <input id ='input' type='password' placeholder='Password Re-enter' onChange={this.handlePasswordRe}/>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
                <div id='submit_div'>
                  <Button id='submit_quick_respond' size='mini' color='red' onClick={this.handleSubmitQuickRegistration}> Sign Up </Button>
                </div>
              </div>

              <div id ='quick_login'>
              <Divider horizontal>
                  <Header as='h4'>
                    <Icon name='sign in' />
                    Log In
                  </Header>
                </Divider>
              <Table definition>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={3}>
                      Username / E-mail
                    </Table.Cell>
                    <Table.Cell>
                      <input id ='input' placeholder='Enter username / email' onChange={this.handleUsernameLogin}/>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={3}>
                      Password
                    </Table.Cell>
                    <Table.Cell>
                      <input id ='input' type='password' placeholder='Password' onChange={this.handlePasswordLogin}/>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
                <div id='submit_div_login'>
                  <Button id='submit_quick_login' size='mini' color='red' onClick={this.handleLogin}> Log In </Button>
                </div>
              </div>
                  

            </div>
            
  }
  
}


export default ClassInfoPublic;
