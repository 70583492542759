import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment,Dropdown, Divider} from 'semantic-ui-react'
import package_icon from '../../media/Images/package.png';
import '../../stylesheets/packageEdit.css'
import $ from 'jquery';
import track from '../track';

class PackageEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      categories: [],
      packageName: '',
      amount: 0,
      durationWeeks: 0,
      numberClasses: 0,
      danceCatId:0,
      danceCatName:'',
      type:'',
      types : [{
                  "key": 1,
                  "text": "Group Classes",
                  "value": 1
              },
              {
                  "key": 2,
                  "text": "Private Classes",
                  "value": 2
              },
              {
                  "key": 3,
                  "text": "Workshops",
                  "value": 3
              }],
      isPrivate:false,
      isWorkshop:false,
      packageId:0
    }
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  componentDidMount(){
    this.setState({packageId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/categories`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ categories: result.dance_categories })
            return;
          },
          error: (error) => {
            alert('Unable to load dance categories. Please try your request again')
            return;
          }
        })
        $.ajax({
          url: `/api/packages/${this.state.packageId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              packageName: result.package.name,
              amount:result.package.amount,
              durationWeeks : result.package.duration_weeks,
              numberClasses: result.package.number_classes,
              danceCatId: result.package.Dance_Category.value,
              danceCatName:result.package.Dance_Category.text,
              type:result.package.package_type
             }, async ()=>{
               // eslint-disable-next-line
               if(this.state.type =='Group Classes'){
                 this.setState({isPrivate: false, isWorkshop:false})
               }// eslint-disable-next-line
               else if(this.state.type =='Private Classes'){
                 this.setState({isPrivate: true, isWorkshop:false})
               }// eslint-disable-next-line
               else if(this.state.type =='Workshops'){
                 this.setState({isPrivate: false, isWorkshop:true})
               }
             })
            return;
          },
          error: (error) => {
            alert('Unable to load roam to edit. Please try your request again')
            return;
          }
        })
      }  
      secondFunction();
    })
  }

  
  packageDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/packages/${this.state.packageId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          name: this.state.packageName,
          amount: this.state.amount,
          duration_weeks: this.state.durationWeeks,
          number_classes: this.state.numberClasses,
          dance_cat_id: this.state.danceCatId,
          is_private: this.state.isPrivate,
          is_workshop: this.state.isWorkshop
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`deleted the package named ${this.state.packageName} `,
                    'Package', 'Delete')        
          
          //this.navTo('/packages');
          this.props.history.push('/packages');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/packages/${this.state.packageId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              name: this.state.packageName,
              amount: this.state.amount,
              duration_weeks: this.state.durationWeeks,
              number_classes: this.state.numberClasses,
              dance_cat_id: this.state.danceCatId,
              is_private: this.state.isPrivate,
              is_workshop: this.state.isWorkshop
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              track(`deleted the package named ${this.state.packageName} `,
                    'Package', 'Delete')        
          
              //this.navTo('/packages');
              this.props.history.push('/packages');
              return;
            },
            error: (error) => {
              alert('Unable to delete the dance package. Please try your request again')
              return;
            }
          })
          return;
        }
      })
    }
    secondFunction();
  }

  
  packageEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/packages/${this.state.packageId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          name: this.state.packageName,
          amount: this.state.amount,
          duration_weeks: this.state.durationWeeks,
          number_classes: this.state.numberClasses,
          dance_cat_id: this.state.danceCatId,
          is_private: this.state.isPrivate,
          is_workshop: this.state.isWorkshop
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`updated the package named ${this.state.packageName} `,
                    'Package', 'Update')        
          
          //this.navTo('/packages');
          this.props.history.push('/packages');
          return;
        },
        error: (error) => {
          alert('Unable to update the dance package. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handlePackageName = (event) => {
    this.setState({packageName: event.target.value})
  }
  
  handleAmount = (event) => {
    this.setState({amount: event.target.value})
  }

  handleChangeCategory = (event, {value}) => {
    this.setState({danceCatId: value}, async ()=> {
      //console.log(this.state.danceCatId);
    }
    )
  }

  handleChangeTypes = (event, {value}) => {
    // eslint-disable-next-line
    if(value == 1){
      this.setState({isPrivate: false , isWorkshop: false})
    }// eslint-disable-next-line
    else if (value == 2){
      this.setState({isPrivate: true , isWorkshop: false})
    }// eslint-disable-next-line
    else if (value == 3){
      this.setState({isPrivate: false , isWorkshop: true})
    }
  }
  
  handleValidity = (event) => {
    this.setState({durationWeeks: event.target.value})
  }

  handleNumberClasses = (event) => {
    this.setState({numberClasses: event.target.value})
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  cancelId = ()=>{
    //this.navTo(`/packages`)
    this.props.history.push('/packages');
  }

  render() {

    return <div>
                <div id='headerPartPackageEdit'>
                  <Header id='HeaderPackageEdit' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={package_icon} /> Dance Package
                  </Header>
                </div>
                <Divider horizontal/>

                 <div id='addFormPackageEdit'>
                  <Segment id="addPackageEdit" inverted>
                    <Form id='formPackageEdit' inverted>
                      <Form.Group>
                        <Form.Field className='formHalfWidthPackageEdit'>
                            <label className="labelPackageEdit">Dance Category</label>
                            <Dropdown
                              text={this.state.danceCatName}
                              selection
                              options={this.state.categories}
                              onChange={this.handleChangeCategory}
                            />
                        </Form.Field>

                        <Form.Field className='formHalfWidthPackageEdit'>
                          <label className="labelPackageEdit">Package Type</label>
                          <Dropdown
                            text={this.state.type}
                            selection
                            options={this.state.types}
                            onChange={this.handleChangeTypes}
                          />
                        </Form.Field>
                        
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthPackageEdit'>
                        <label className="labelPackageEdit">Package Name</label>
                        <input className ='input' defaultValue={this.state.packageName} onChange={this.handlePackageName}/>
                      </Form.Field>
                    <Form.Field className='formHalfWidthPackageEdit'>
                        <label className="labelPackageEdit">Package Price</label>
                        <input className ='input' type='number' value={this.state.amount} onChange={this.handleAmount}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthPackageEdit'>
                        <label className="labelPackageEdit">Validity (weeks)</label>
                        <input className ='input' type='number' value={this.state.durationWeeks} onChange={this.handleValidity}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthPackageEdit'>
                        <label className="labelPackageEdit">Number of Classes</label>
                        <input className ='input' type='number' value={this.state.numberClasses} onChange={this.handleNumberClasses}/>
                      </Form.Field>
                    </Form.Group>
                      <div id = 'submitPackageEditContainer'>
                        <Button id='submitPackageEdit1' content='Primary' floated='right' type='submit' onClick={this.packageEditId}>Update</Button>
                        <Button id='submitPackageEdit2' content='Secondary' floated='right' type='submit' onClick={this.packageDeleteId}>Delete</Button>
                        <Button id='submitPackageEdit3' content='Primary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                      </div>
                    </Form>
                  </Segment>
                </div>
           </div>
  }
  
}


export default PackageEdit;
