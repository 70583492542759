import React, { useState } from 'react'
import { Menu, Image, Dropdown } from 'semantic-ui-react'
import '../../stylesheets/Header.css';
import $ from 'jquery';
import no_profile_picture from '../../media/Images/no_profile_picture.png';
import { Link } from 'react-router-dom';


export const StudentMenu = (props) =>{
    //const [activeItem, setActiveItem] = useState('Home');
    const [firstName, setFirstName] = useState('');
    const [imageURL, setImageURL] = useState(no_profile_picture);

    //const handleItemClick = (e, { name }) => setActiveItem(name)

    /*const navTo = (uri) =>{
        window.location.href = window.location.origin + uri;
    }*/

    $.ajax({
        url: `/api/users/${props.user_id}`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
            setFirstName(result.user.first_name) 
            return ;
        },
        error: (error) => {
          alert('Unable to load username. Please try your request again')
          return;
        }
    })
    $.ajax({
        url: `/uploads/${props.user_id}/users`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        processData: false,
        contentType: false,
        cache: false,
        timeout: 800000,
                      
        success: (result) => {
            setImageURL(`data:image/jpeg;base64,${result}`)
          return;
        },
        error: (error) => {
          setImageURL(no_profile_picture)
          return;
        }
      })

    return(
        <>
            <Menu id='menu_2_id' widths={5} size='mini' fluid={true} inverted ={true}>    
                <Menu.Item
                    className='menu_2_item'
                    name='Home'
                    as={Link}
                    to={'/'}
                >
                    Home
                    {/*<img src={Site_Logo} alt="Soul motion logo" ></img>*/}
                </Menu.Item>
                <Menu.Item
                    className='menu_2_item'
                    name='mySchedule'
                    //active={activeItem === 'mySchedule'}
                    as={Link}
                    to={`/students/${props.user_id}/schedules`}
                    //onClick={() => {handleItemClick()}}
                >
                    My Schedule
                </Menu.Item>

                <Menu.Item
                    className='menu_2_item'
                    name='full_schedule'
                    //active={activeItem === 'full_schedule'}
                    as={Link}
                    to={`/students/${props.user_id}/fullschedules`}
                    //onClick={() => {handleItemClick()}}
                >
                    Full Schedule
                </Menu.Item>


                <Menu.Item
                    className='menu_2_item'
                    name='my_subscription'
                    //active={activeItem === 'my_subscription'}
                    as={Link}
                    to={`/students/${props.user_id}/subs`}
                    //onClick={() => {handleItemClick()}}
                >
                    My Subscriptions
                </Menu.Item>

                <Menu.Item
                    className='menu_2_item'
                    name='users'
                    //active={activeItem === 'users'}
                    as={Link}
                    to={`/users/${props.user_id}`}
                    //onClick={() => {handleItemClick()}}
                >
                    <div>
                        <Image src={imageURL} avatar />
                        <span>{firstName}</span>
                    </div>
                    
                </Menu.Item>
            </Menu>
            
            <Dropdown
            id='menu_2_id_phone_part_inst'
            text='Menu'
            floating
            labeled
            button
            className='icon'>
                <Dropdown.Menu>
                    <Dropdown.Item icon='home' text='Home' as={Link}
                        to={`/`}
                    />
                <Dropdown.Divider/>
                    <Dropdown.Item icon='calendar check' text='My Schedule' as={Link}
                        to={`/students/${props.user_id}/schedules`}
                    />
                    <Dropdown.Item icon='calendar' text='Full Schedule' as={Link}
                        to={`/students/${props.user_id}/fullschedules`}
                    />
                <Dropdown.Divider/>
                    <Dropdown.Item icon='money bill alternate outline' text='My Subscriptions' as={Link}
                        to={`/students/${props.user_id}/subs`}
                    />
                <Dropdown.Divider/>
                    <Dropdown.Item icon='user' content= {`${firstName}'s profile'`} as={Link}
                        to={`/users/${props.user_id}`}
                    /> 
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}
/*
<div>
    <Image src={imageURL} avatar />
    <span>{firstName}</span>
</div>
*/

/*
<Image src={imageURL} size='tiny' verticalAlign='middle' />{' '}
<span>{firstName}</span>
*/