import {format} from 'date-fns'

import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export const COLUMNS = [
    {
        Header:()=>'',
        accessor:'id',
        Cell:({value}) => (
            <Link to={`/kitchens/${value}`}><Button circular icon='edit' content='Edit'/></Link>
        ),
        disableFilters: true
    },
    {
        Header: 'Description',
        accessor: 'description'
    },
    {
        Header: 'Date',
        accessor: 'date',
        Cell:({value}) =>{return format(new Date(value), "eeee, dd/MM/yyyy hh:mm aaaaa'm'")}
    },
    {
        Header: 'Amount In (LE)',
        accessor: 'amount_in'
    },
    {
        Header: 'Amount Out (LE)',
        accessor: 'amount_out'
    }
]