import React, { Component } from 'react';
import { Header, Image, Button, Dropdown, Segment, Form} from 'semantic-ui-react'
import inst_icon from '../../../media/Images/instructor_icon.png';
import '../../../stylesheets/dance.css'
import $ from 'jquery';
import track from '../../track';

class NewInstructor extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      instructors: [],
      dance_categories:[],
      dances_options:[],
      dances:[],

      UserName: '',
      FirstName: '',
      LastName: '',
      Password: '',
      PasswordRe: '',
      ProfilePicture: null,
      Email: '',
      Address: '',
      Phone: '',
      dance_category_ids:[],
      dancesInst:[],
      salaryBase:'Hourly Based',
      isHoulyBased:true,
      salaryHourAlone:0,
      salaryHourWithPartner:0,
      salaryPercentage:0,

      values:[''],
      
      salaryBaseOptions: [
        {
          key: 'Hourly Based',
          text: 'Hourly Based',
          value: 'Hourly Based'
        },
        {
          key: 'Percentage Based',
          text: 'Percentage Based',
          value: 'Percentage Based'
        }
      ]
    }
  }

  componentDidMount(){
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/categories`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ dance_categories: result.dance_categories })
          return;
        },
        error: (error) => {
          alert('Unable to load dance categories. Please try your request again')
          return;
        }
      })
      $.ajax({
        url: `/api/dances`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ dances: result.dances })
          return;
        },
        error: (error) => {
          alert('Unable to load dances. Please try your request again')
          return;
        }
      })
      $.ajax({
        url: `/api/instructors`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ instructors: result.instructors })
          return;
        },
        error: (error) => {
          alert('Unable to load instructors. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  

  handleUserName = (event) =>{
    this.setState({UserName:event.target.value})
  }

  handleFirstName = (event) =>{
    this.setState({FirstName:event.target.value})
  }

  handleLastName = (event) =>{
    this.setState({LastName:event.target.value})
  }

  handleAddress = (event) =>{
    this.setState({Address:event.target.value})
  }

  handleEmail = (event) =>{
    this.setState({Email:event.target.value})
  }

  handlePhone = (event) =>{
    this.setState({Phone:event.target.value})
  }

  handlePassword = (event) =>{
    this.setState({Password:event.target.value})
  }

  handlePasswordRe = (event) =>{
    this.setState({PasswordRe:event.target.value})
  }

  handleProfilePicture = (event)=>{
    this.setState({ProfilePicture: event.target.files[0]})
  }

  handleChangeIsHourlyBased = (event, {value}) =>{
    // eslint-disable-next-line
    if (value == 'Hourly Based'){
      document.getElementById("salary_partner").hidden = false;
      this.setState({
        salaryBase: value,
        isHoulyBased: true
      })
    }else{
      document.getElementById("salary_partner").hidden = true;
      this.setState({
        salaryBase: value,
        isHoulyBased: false
      })
    }
   }

  handleSalary = (event) => {
    // eslint-disable-next-line
    if (this.state.isHoulyBased==true){
      this.setState({
        salaryHourAlone:event.target.value,
        salaryPercentage: 0
      })
    } else{
      this.setState({
        salaryHourAlone:0,
        salaryPercentage: event.target.value
      })
    }
  }
  
  handleSalaryPartner = (event) => {
    this.setState({
      salaryHourWithPartner:event.target.value
    })
  }

  handleChangeCategory = (i,event, {value}) => {
    let dance_category_ids = [...this.state.dance_category_ids];
    dance_category_ids[i] = value;
    this.setState({dance_category_ids: dance_category_ids}, async () => {
      let dances_options = [...this.state.dances_options];
      dances_options[i] = this.state.dances[this.state.dance_category_ids[i]]; 
      this.setState({
      dances_options:dances_options
    }, async () => {
      //console.log(this.state.dances_options)
    })})
  }

  handleChangeDance = (i, event,{value}) => {
    let d = [...this.state.dancesInst];
    d[i] = value;
    this.setState({ dancesInst: d});
  }

  addInstructor  = (id) =>{
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/dances/${id}/instructors`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          username: this.state.UserName,
          first_name: this.state.FirstName,
          last_name: this.state.LastName,
          password: this.state.Password,
          re_password: this.state.PasswordRe,
          address: this.state.Address,
          email: this.state.Email,
          phone: this.state.Phone,
          dance_cat_ids: this.state.dance_category_ids,
          dances_instructor: this.state.dancesInst,
          salary_base: this.state.salaryBase,
          salary_hour_alone: this.state.salaryHourAlone,
          salary_hour_with_partner: this.state.salaryHourWithPartner,
          salary_percentage: this.state.salaryPercentage
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          if (this.state.ProfilePicture!== null){
            var data1 = new FormData();
            data1.append("file", this.state.ProfilePicture)
            data1.append("filename", this.state.UserName)
            
            $.ajax({
              url: `/api/instructors/${result.instructor.id}/uploads`, //TODO: update request URL
              type: "POST",
              beforeSend: function (xhr) {   //Include the bearer token in header
                xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
              },
              enctype: 'multipart/form-data',
              data: data1,
              processData: false,
              contentType: false,
              cache: false,
              timeout: 800000,
            
              xhrFields: {
                withCredentials: true
              },
              crossDomain: true,
              success: (result) => {
                track(`Added new instructor, username = ${this.state.UserName} with picture while creating dance ${id}`,
                    'Instructor', 'Add')
                  
                return;
              },
              error: (error) => {
                alert('Unable to add the profile picture. Please try your request again')
                return;
              }
            })
          } else{
            track(`Added new instructor, username = ${this.state.UserName} without picture while creating dance ${id}`,
                    'Instructor', 'Add')
                
          }
        },
        error: (error) => {
          alert('Unable to add instructor - '+error.description)
          return;
        }
      })
    }
    secondFunction();
  }
 


  render() {
    
    return <div id='addFormDanceNewInst'>
                <Segment id="addDanceNewInst" inverted>
                  <Form id='formDanceNewInst' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormDance'>
                        <label className="labelDanceNewInst">User Name</label>
                        <input className ='input' placeholder='User Name' onChange={this.handleUserName}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthDance'>
                        <label className="labelDanceNewInst">First Name</label>
                        <input className ='input' placeholder='First Name' onChange={this.handleFirstName}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthDance'>
                        <label className="labelDanceNewInst">Last Name</label>
                        <input className ='input' placeholder='Last Name' onChange={this.handleLastName}/>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field className='formHalfWidthDance'>
                        <label className="labelDanceNewInst">Password</label>
                        <input className ='input' type='password' placeholder='Password' onChange={this.handlePassword}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthDance'>
                        <label className="labelDanceNewInst" id='passwordReEnterPort'>Password Re-enter</label>
                        <input className ='input' type='password' placeholder='Password Re-enter' onChange={this.handlePasswordRe}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='fullWidthFormDance'>
                        <label className="labelDanceNewInst">Profile Picture</label>
                        <input className ='input' type='file' placeholder="Choose Instructor's Profile Picture" accept="image/*" onChange={this.handleProfilePicture}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='fullWidthFormDance'>
                        <label className="labelDanceNewInst">Address</label>
                        <input className ='input' placeholder='Address' onChange={this.handleAddress}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthDance'>
                        <label className="labelDanceNewInst">E-Mail</label>
                        <input className ='input' placeholder='E-Mail' onChange={this.handleEmail}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthDance'>
                        <label className="labelDanceNewInst">Phone</label>
                        <input className ='input' placeholder='Phone' onChange={this.handlePhone}/>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field className='fullWidthFormDance'>
                        <label className="labelDanceNewInst">Is Hourly Based?</label>
                        <Dropdown
                          placeholder='Is Hourly Based?'
                          selection
                          options={this.state.salaryBaseOptions}
                          onChange={this.handleChangeIsHourlyBased}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthDance' id='holdAlonePort'>
                        <label className="labelDanceNewInst" >{this.state.salaryBase === 'Hourly Based' ? 'Salary - Holds Alone (EGP/Hour)' : 'Salary (%)'}</label>
                        <input className ='input' type='number' placeholder={this.state.salaryBase === 'Hourly Based' ? 'Salary - Holds Class Alone (EGP/Hour)' : 'Salary (%)'}
                         onChange={this.handleSalary}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthDance' id='salary_partner'>
                        <label className="labelDanceNewInst"> Salary - With Partner (EGP/Hour)</label>
                        <input className ='input' type='number' placeholder= 'Salary - Holds Class With Partner (EGP/Hour)'
                         onChange={this.handleSalaryPartner}/>
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Segment>
              </div>
  }
  
}


export default NewInstructor;
