import React from "react";
import { useJwt } from "react-jwt";

import {LoginButton} from "./LoginButton";
import {LogoutButton} from "./LogoutButton";

//import { useAuth0 } from "@auth0/auth0-react";

export const AuthenticationButton = (props) => {
  let { isExpired } = useJwt(props.token);
  return ((typeof props.token !== 'undefined' && props.token !== null  && isExpired === false) ? <LogoutButton token ={props.token} setToken={props.setToken}/> : <LoginButton token ={props.token}/>);
};
 
