import React, { Component } from 'react';
import { Header, Image, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import misc_icon from '../../../media/Images/misc.png';
import '../../../stylesheets/dailyPaymentMiscs.css';
//import $ from 'jquery';
//import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';

class MiscPaymentComponent extends Component {
  constructor(props){
    super(...arguments);
    this.state = { 
    }
  }

  componentDidMount(){
    
  }

  
  render() {
    return <div >
              <div id = 'headerPartPaymentMiscs'>
                <Divider horizontal>
                  <h4 id='HeaderPaymentMiscs'>
                  <Image id='iconPaymentMiscs' size='large' src={misc_icon} />
                  Misc Payments
                  </h4>
                </Divider>
              </div>
              
              <Divider horizontal/>

              <div id = 'tablePaymentMiscs'>
                <BasicTable key ={this.props.miscPayment}  data = {this.props.miscPayment}/>
              </div>

              
              
           </div>
  }  
}

export default MiscPaymentComponent;
