import React, { Component } from 'react';
import { Header, Image, Button, Dropdown, Segment, Form} from 'semantic-ui-react'
import inst_icon from '../../../media/Images/instructor_icon.png';
import '../../../stylesheets/dance.css'
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import track from '../../track';

class ExistingInstructor extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      instructors: []
    }
  }

  componentDidMount(){
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/insts`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ instructors: result.instructors })
          return;
        },
        error: (error) => {
          alert('Unable to load instructors. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  


  render() {
    
    return <div>
                <Form.Field className='fullWidthFormDance'>
                  <label className="labelDance">Dance Category</label>
                  <Autocomplete
                      id="combo-box-demo"
                      className='fullWidthFormDance'
                      options={this.state.instructors}
                      getOptionLabel={(option) => option.full_name}
                      renderInput={(params) => <TextField {...params} label="select your instructor" variant="outlined" />}
                      onChange={(event, optionSelected)=>{
                        this.props.setInstructor(typeof optionSelected !=='undefined' && optionSelected !== null ? optionSelected.id :null);
                        //console.log('optionSelected.id = ',typeof optionSelected !=='undefined' && optionSelected !== null ? optionSelected.id :null)
                      }}
                  />
                </Form.Field>
           </div>
  }
  
}


export default ExistingInstructor;
