import React, { Component } from 'react';
import { Header, Image, Divider, Table} from 'semantic-ui-react'
import summary_icon from '../../../media/Images/summary_payments.png';

import '../../../stylesheets/dailyPaymentSummary.css';
//import $ from 'jquery';
//import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';

class SummaryPaymentComponent extends Component {
  constructor(props){
    super(...arguments);
    this.state = { 
    }
  }

  componentDidMount(){
    
  }

  
  render() {
    return <div>
              <div id = 'headerPartPaymentSummary'>
                <Divider horizontal>
                  <h4 id='HeaderPaymentSummary'>
                  <Image id='iconPaymentSummary' size='large' src={summary_icon} />
                  Summary of Payments
                  </h4>
                </Divider>
              </div>
              
              <Divider horizontal/>
              
              <div id='tablePaymentSummary'>
                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={6}>Subscription Payment (EGP) - Cash  </Table.Cell>
                      <Table.Cell> {this.props.subscriptionPaymentCash} </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={6}>Subscription Payment (EGP) - Credit Card</Table.Cell>
                      <Table.Cell> {this.props.subscriptionPaymentCredit} </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={6}> Total Subscription Payment (EGP)</Table.Cell>
                      <Table.Cell> {this.props.subscriptionPaymentTotal} </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={6}>Rent Payment (EGP) - Cash  </Table.Cell>
                      <Table.Cell> {this.props.rentPaymentCash} </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={6}>Rent Payment (EGP) - Credit Card</Table.Cell>
                      <Table.Cell> {this.props.rentPaymentCredit} </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={6}> Total Rent Payment (EGP)</Table.Cell>
                      <Table.Cell> {this.props.rentPaymentTotal} </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={6}> Kitchen In Payments (EGP) - Cash </Table.Cell>
                      <Table.Cell> {this.props.kitchenInPaymentCash} </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={6}> Kitchen Out Payments (EGP) - Cash </Table.Cell>
                      <Table.Cell> {this.props.kitchenOutPaymentCash} </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={6}> Misc Payment (EGP) - Cash </Table.Cell>
                      <Table.Cell> {this.props.miscPaymentCash} </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={6}> Total Cash (EGP) </Table.Cell>
                      <Table.Cell> {this.props.cashAll} </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={6}> Total Credit (EGP) </Table.Cell>
                      <Table.Cell> {this.props.creditAll} </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={6}> Grand Total (EGP) </Table.Cell>
                      <Table.Cell> {this.props.total} </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
           </div>
  }  
}

export default SummaryPaymentComponent;
