import React, { Component } from 'react';
import { Header, Image, Divider, Table } from 'semantic-ui-react'
import '../../../stylesheets/classInfoInstPriv.css';
import $ from 'jquery';
import class_icon from '../../../media/Images/class.png';
import InstructorsClass from './InstructorsClass/InstructorsClass'
import MainStudentsClass from './MainStudentsClass/MainStudentsClass'

class ClassInfoInstPriv extends Component {
  constructor(props) {
    super(...arguments);
    this.state = {
      classId: 1,
      classInfo: {},
      classInstructors: [],
      mainReg: [],
      level: {}
    }
  }

  componentDidMount() {
    this.setState({ classId: this.props.match.params.id }, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/classes/${this.state.classId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              classInfo: result.class_info,
              classInstructors: result.instructors,
              mainReg: result.main_students
            })
            return;
          },
          error: (error) => {
            alert('Unable to load class info. Please try your request again')
            return;
          }
        })
        $.ajax({
          url: `/api/classes/${this.state.classId}/levels`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              level: result.level
            })
            return;
          },
          error: (error) => {
            alert('Unable to load the class level info. Please try your request again')
            return;
          }
        });
      }
      secondFunction();
    })
  }


  render() {
    //console.log('in class',this.state.classInstructors)

    return <div>
      <div id='headerPartClassInfoInstPriv'>
        <Header id='HeaderClassInfoInstPriv' as='h2' block={true} >
          <Image size='large' circular src={class_icon} /> Class Information
        </Header>
      </div>
      <Divider horizontal />
      <div id='class_description'>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={2}>Description</Table.Cell>
              <Table.Cell>
                {this.state.classInfo.description}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={2}>Duration</Table.Cell>
              <Table.Cell>
                {this.state.classInfo.duration_minutes} minutes
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={2}>Is Fixed</Table.Cell>
              <Table.Cell>
                {this.state.classInfo.Is_fixed ? "Fixed Class" : "Onetime Class"}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={2}>Weekly Slots</Table.Cell>
              <Table.Cell>
                {this.state.classInfo.slots_count == 2 ? "Twice a week" : this.state.classInfo.slots_count == 1 ? "Once a week" : '-'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={2}>Level</Table.Cell>
              <Table.Cell>
                {this.state.level.text}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <Divider horizontal />



      <div>
        <InstructorsClass instructors={this.state.classInstructors} classId={this.state.classId} />
      </div>

      <div>
        <MainStudentsClass mainReg={this.state.mainReg} classId={this.state.classId} />
      </div>


    </div>
  }

}


export default ClassInfoInstPriv;
