import React, { Component } from 'react';
import { Header, Table, Icon, Divider, Button} from 'semantic-ui-react';
import '../../../stylesheets/monthlyClosingNew.css';
import $ from 'jquery';
import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';

class GenerateNewSalary extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),

      totalInstructorsSalary: 0,
      countInstructors: 0,
      
      totalAdminSalary: 0,
      officeBoySalary: 0,
      
      instagramStories: 0,
      graphicDesigner: 0,
      mediaBuying:0,
      accountant:0,
      studioManager:0,

      rent: 0,
      electricity: 0,
      water: 0,
      projectPoleRevenue: 0,
      projectPoleExpense: 0
    }
  }

  componentDidMount(){
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/salaries-default`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({
            officeBoySalary: result.salary.office_boy_salary,
            instagramStories: result.salary.instagram_stories,
            graphicDesigner: result.salary.graphic_designer,
            mediaBuying: result.salary.media_buying,
            accountant: result.salary.accountant,
            studioManager: result.salary.studio_manager,
            rent: result.salary.rent,
            electricity: result.salary.electricity,
            water: result.salary.water,
            projectPoleRevenue:result.salary.project_pole_revenue,
            projectPoleExpense: result.salary.project_pole_expense
          });
          return;
        },
        error: (error) => {
          this.setState({
            officeBoySalary: 0,
            instagramStories: 0,
            graphicDesigner: 0,
            mediaBuying: 0,
            accountant: 0,
            studioManager: 0,
            rent: 0,
            electricity: 0,
            water: 0,
            projectPoleRevenue:0,
            projectPoleExpense: 0
          });
          return;
        }
      });
    }
    secondFunction();
  }

  showSubsPayments = ()=>{
    document.getElementById("sub_payments").hidden=false;
    document.getElementById("sub_payments").scrollIntoView();
  }
  hideSubsPayments = ()=>{
    document.getElementById("sub_payments").hidden=true;
    document.getElementById("salary").scrollIntoView();
  }

  showRentPayments = ()=>{
    document.getElementById("rent_payments").hidden=false;
    document.getElementById("rent_payments").scrollIntoView();
  }
  hideRentPayments = ()=>{
    document.getElementById("rent_payments").hidden=true;
    document.getElementById("salary").scrollIntoView();
  }

  showSummary = ()=>{
    document.getElementById("summary_payments").hidden=false;
    document.getElementById("summary_payments").scrollIntoView();
  }
  hideSummary = ()=>{
    document.getElementById("summary_payments").hidden=true;
    document.getElementById("salary").scrollIntoView();
  }
  

  submitSalary = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/salaries`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          start_date: this.state.startDate,
          end_date: this.state.endDate,
  
          office_boy_salary: this.state.officeBoySalary,
          instagram_stories: this.state.instagramStories,
          graphic_designer: this.state.graphicDesigner,
          media_buying: this.state.mediaBuying,
          accountant: this.state.accountant,
          studio_manager: this.state.studioManager,
          rent: this.state.rent,
          electricity: this.state.electricity,
          water: this.state.water,
          project_pole_revenue: this.state.projectPoleRevenue,
          project_pole_expense: this.state.projectPoleExpense
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          window.location.reload();
          return;
        },
        error: (error) => {
          alert('Unable to generate Salary. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  
  handleStartDate = (event) => {
    this.setState({
        startDate: event.target.value
    }, async ()=>{
      //console.log(this.state.startDate)
    });
  }

  handleEndDate = (event) => {
    this.setState({
        endDate: event.target.value
    }, async ()=>{
      //console.log(this.state.endDate)
    });
  }

  handleOfficeBoySalary = (event) => {
    this.setState({officeBoySalary: event.target.value});
  }

  handleInstagramStories = (event) => {
    this.setState({instagramStories: event.target.value});
  }

  handleGraphicDesigner = (event) => {
    this.setState({graphicDesigner: event.target.value});
  }

  handleMediaBuying = (event) => {
    this.setState({mediaBuying: event.target.value});
  }

  handleAccountant = (event) => {
    this.setState({accountant: event.target.value});
  }

  handleStudioManager = (event) => {
    this.setState({studioManager: event.target.value});
  }

  handleRent = (event) => {
    this.setState({rent: event.target.value});
  }

  handleElectricity = (event) => {
    this.setState({electricity: event.target.value});
  }

  handleWater = (event) => {
    this.setState({water: event.target.value});
  }

  handleProjectPoleRevenue = (event) => {
    this.setState({projectPoleRevenue: event.target.value});
  }

  handleProjectPoleExpense = (event) => {
    this.setState({projectPoleExpense: event.target.value});
  }

  render() {
    return <div>
              <div id='newTable'>
                <Divider horizontal>
                  <h4>
                    <Icon name='payment' />
                    Finalizing Current Month
                  </h4>
                </Divider>

                <Divider horizontal/>

                <Divider horizontal>
                  <h4>
                    Dates
                  </h4>
                </Divider>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={4}>Start Date</Table.Cell>
                      <Table.Cell>
                        <DateTimePickerComponent
                          CssClass="customClass"
                          id='StartTime'
                          className ="e-field"
                          format='dd/MM/yy'
                          value={this.state.startDate}
                          onChange={this.handleStartDate}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={4}>End Date</Table.Cell>
                      <Table.Cell>
                        <DateTimePickerComponent
                          CssClass="customClass"
                          id='EndTime'
                          className ="e-field"
                          format='dd/MM/yy'
                          value={this.state.endDate}
                          onChange={this.handleEndDate}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider horizontal/>
                
                <Divider horizontal>
                  <h4>
                    Office Boy
                  </h4>
                </Divider>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={4}>Office Boy Salary</Table.Cell>
                        <Table.Cell>
                          <input id='amount-pay' type='number' value={this.state.officeBoySalary} onChange={this.handleOfficeBoySalary}/>
                          {' EGP'}
                        </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Divider horizontal>
                  <h4>
                    Others
                  </h4>
                </Divider>

                <Table definition>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell width={4}>Instagram Stories</Table.Cell>
                      <Table.Cell>
                        <input id='amount-pay' type='number' value={this.state.instagramStories} onChange={this.handleInstagramStories}/>
                          {' EGP'}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={4}>Graphic Designer</Table.Cell>
                      <Table.Cell>
                        <input id='amount-pay' type='number' value={this.state.graphicDesigner} onChange={this.handleGraphicDesigner}/>
                          {' EGP'}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={4}>Media Buying</Table.Cell>
                      <Table.Cell>
                        <input id='amount-pay' type='number' value={this.state.mediaBuying} onChange={this.handleMediaBuying}/>
                          {' EGP'}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={4}>Accountant</Table.Cell>
                      <Table.Cell>
                        <input id='amount-pay' type='number' value={this.state.accountant} onChange={this.handleAccountant}/>
                          {' EGP'}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={4}>Studio Manager</Table.Cell>
                      <Table.Cell>
                        <input id='amount-pay' type='number' value={this.state.studioManager} onChange={this.handleStudioManager}/>
                          {' EGP'}
                      </Table.Cell>
                    </Table.Row>
                    
                    <Table.Row>
                      <Table.Cell width={4}>Rent</Table.Cell>
                      <Table.Cell>
                        <input id='amount-pay' type='number' value={this.state.rent} onChange={this.handleRent}/>
                          {' EGP'}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={4}>Electricity</Table.Cell>
                      <Table.Cell>
                        <input id='amount-pay' type='number' value={this.state.electricity} onChange={this.handleElectricity}/>
                          {' EGP'}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={4}>Water</Table.Cell>
                      <Table.Cell>
                        <input id='amount-pay' type='number' value={this.state.water} onChange={this.handleWater}/>
                          {' EGP'}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={4}>Project Pole Revenue</Table.Cell>
                      <Table.Cell>
                        <input id='amount-pay' type='number' value={this.state.projectPoleRevenue} onChange={this.handleProjectPoleRevenue}/>
                          {' EGP'}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell width={4}>Project Pole Expense</Table.Cell>
                      <Table.Cell>
                        <input id='amount-pay' type='number' value={this.state.projectPoleExpense} onChange={this.handleProjectPoleExpense}/>
                          {' EGP'}
                        </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Button id='submitNewSalary' floated='right' size='mini' color='red' onClick={this.submitSalary}> Submit </Button>
              </div>
           </div>
  }  
}

export default GenerateNewSalary;
