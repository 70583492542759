import React, { useState } from 'react'
import { Menu, Image } from 'semantic-ui-react'
import '../../stylesheets/Header.css';
import $ from 'jquery';
import no_profile_picture from '../../media/Images/no_profile_picture.png';
import { Link } from 'react-router-dom';


export const PublicMenu = (props) =>{
    //const [activeItem, setActiveItem] = useState('Home');
    const [firstName, setFirstName] = useState('');
    const [imageURL, setImageURL] = useState(no_profile_picture);

    //const handleItemClick = (e, { name }) => setActiveItem(name)

    /*const navTo = (uri) =>{
        window.location.href = window.location.origin + uri;
    }*/

    
    return(
        <>
            
        </>
    )
}
/*
<div>
    <Image src={imageURL} avatar />
    <span>{firstName}</span>
</div>
*/

/*
<Image src={imageURL} size='tiny' verticalAlign='middle' />{' '}
<span>{firstName}</span>
*/

/*
<Menu.Item
                name='public_schedule'
                //active={activeItem === 'full_schedule'}
                as={Link}
                to={`/public-schedules`}
                //onClick={() => {handleItemClick()}}
            >
                Schedule
            </Menu.Item>
            */