import React, { Component } from 'react';
import { Header, Image, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../../media/Images/instructor_icon.png';
import '../../../stylesheets/instructorAP.css'
import $ from 'jquery';


class Instructor extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      instructors: [],
      dance_categories:[],
      dances_options:[],
      dances:[],

      UserName: '',
      FirstName: '',
      LastName: '',
      Password: '',
      PasswordRe: '',
      ProfilePicture: null,
      Email: '',
      Address: '',
      Phone: '',
      dance_category_ids:[],
      dancesInst:[],
      salaryBase:'Hourly Based',
      isHoulyBased:true,
      salaryHourAlone:0,
      salaryHourWithPartner:0,
      salaryPercentage:0,

      values:[''],
      
      salaryBaseOptions: [
        {
          key: 'Hourly Based',
          text: 'Hourly Based',
          value: 'Hourly Based'
        },
        {
          key: 'Percentage Based',
          text: 'Percentage Based',
          value: 'Percentage Based'
        }
      ]
    }
  }

  componentDidMount(){
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/instructors`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ instructors: result.instructors })
          return;
        },
        error: (error) => {
          alert('Unable to load instructors. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  

  render() {
    
    return <div>
                <div id='headerPartInstructorAP'>
                  <Header id='HeaderInstructorAP' as='h2' block={true}>
                      <Image size='large' circular src={inst_icon} /> Instructors
                  </Header>
                </div>
                <Divider horizontal/>

                <div id="tableInstructorAP">
                    <BasicTable key ={this.state.instructors}  data = {this.state.instructors} />
                </div>       
           </div>
  }
  
}


export default Instructor;
