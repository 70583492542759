import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import track_icon from '../../media/Images/track.png';
import '../../stylesheets/track.css'
import $ from 'jquery';

class Track extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      tracks: []
    }
  }

  componentDidMount(){
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/tracks`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ tracks: result.tracks })
          return;
        },
        error: (error) => {
          alert('Unable to load Track. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  render() {
    
    return <div>
              <div id='headerPartTrack'>
                <Header id='HeaderTrack' as='h2' block={true} value={this.state.track}>
                  <Image size='large' circular src={track_icon} /> Tracks
                </Header>
              </div>
              <Divider horizontal/>


              <div id = 'tableTrack'>
                <BasicTable key ={this.state.tracks}  data = {this.state.tracks} />
              </div>
              <Divider horizontal/>

           </div>
  }
  
}


export default Track;
