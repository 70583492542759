import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import misc_icon from '../../media/Images/misc.png';
import '../../stylesheets/misc.css'
import $ from 'jquery';
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
import track from '../track';

class Misc extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      miscs: [],
      miscDescription: '',
      amount: 0,
      date: new Date()
    }
  }

  componentDidMount(){
    document.getElementById("addMisc").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/miscs`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ miscs: result.miscs })
          return;
        },
        error: (error) => {
          alert('Unable to load dance miscs. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  new_misc = ()=>{
    if (document.getElementById("addMisc").hidden){
      document.getElementById("addMisc").hidden=false;
      document.getElementById("addMisc").scrollIntoView();
    }else{
      document.getElementById("addMisc").hidden=true;
      document.getElementById("tableMisc").scrollIntoView();
    } 
  }

  addMisc = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/miscs`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          misc_description: this.state.miscDescription,
          amount: this.state.amount,
          date: this.state.date
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Added new Misc = ${this.state.miscDescription}`,
                    'Misc', 'Add')
          
          $.ajax({
            url: `/api/miscs`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              this.setState({ miscs: result.miscs },async ()=> {
                document.getElementById("addMisc").hidden=true;
                document.getElementById("tableMisc").scrollIntoView();
              })
              return;
            },
            error: (error) => {
              alert('Unable to load roams. Please try your request again')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the misc. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleMiscDescription = (event) => {
    this.setState({miscDescription: event.target.value})
  }
  
  handleAmount = (event) => {
    this.setState({amount: event.target.value})
  }

  handleDate = (event) => {
    this.setState({
        date: event.target.value
    });
  }

  
  
  render() {
    
    return <div>
              <div id='headerPartMisc'>
                <Header id='HeaderMisc' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={misc_icon} /> Miscs
                </Header>
              </div>
              <Divider horizontal/>
              
              <div >
                <Button id='misc_button1' size='mini' color='red' onClick={this.new_misc}> Add a Misc </Button>
              </div>
              <Divider horizontal/>
              
              <div id = 'tableMisc'>
                  <BasicTable key ={this.state.miscs}  data = {this.state.miscs} />
              </div>

 
              <div id='addFormMisc'>
                <Segment id="addMisc" inverted>
                  <Form id='formMisc' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormMisc'>
                        <label className="labelMisc">Description</label>
                        <input className ='input' placeholder='Description' onChange={this.handleMiscDescription}/>   
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthMisc'>
                        <label className="labelMisc">Price (LE)</label>
                        <input className ='input' type='number' placeholder='Price (LE)' onChange={this.handleAmount}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthMisc' id="date">
                        <label id="labelMisc">Date</label>
                        <DatePickerComponent
                          id='StartTime'
                          className ="e-field"
                          format='dd/MM/yy'
                          value={this.state.date}
                          onChange={this.handleDate}
                        />
                      </Form.Field>  
                    </Form.Group>
                    <Button id='submitMisc' floated='right' type='submit' onClick={this.addMisc}>Submit</Button>
                  </Form>
                </Segment>
              </div>
           </div>
  }
  
}


export default Misc;
