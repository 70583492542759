import React, { Component } from 'react';
import { Header, Image, Button, Divider, Icon, Table, Dropdown} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import salary_icon from '../../media/Images/salary.png';
import '../../stylesheets/salaryPayslip.css'
import $ from 'jquery';

class SalaryPayslip extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      userId: 0,
      salariesOptions: [],
      
      salaryId:0,
      startDate: '',
      endDate: '',
      
      partner: {},
      admin: {},
      instructor: {},

      instructorAttendances: []
    }
  }

  componentDidMount(){
    document.getElementById("view_salary_details").hidden = true;
    document.getElementById("instructor_info_div").hidden = true;
    document.getElementById("admin_info_div").hidden = true;
    document.getElementById("partner_info_div").hidden = true;
    document.getElementById("tablePayslip2").hidden = true;
    
    this.setState({userId: this.props.match.params.user_id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/salaries`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ salariesOptions: result.salaries})
            return;
          },
          error: (error) => {
            alert('Unable to load any available salaries. Please try your request again')
            return;
          }
        });
      }  
      secondFunction();
    });
  }

  handleSalariesOptions = (event,{value}) => {
    event.preventDefault();
    this.setState({salaryId: value})
  }

  submitViewSalary = (event) =>{
    event.preventDefault();
    if (document.getElementById("view_salary_details").hidden){
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/users/${this.state.userId}/salaries/${this.state.salaryId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ 
              instructor: result.instructor,
              instructorAttendances: result.attendances,
              partner: result.partner,
              admin: result.admin,
              startDate: result.start,
              endDate: result.end
            }, async ()=>{
              //console.log(result);
              document.getElementById("view_salary_details").hidden = false;
              if (typeof (this.state.instructor.text) !=='undefined'){
                document.getElementById("instructor_info_div").hidden = false;
                document.getElementById("tablePayslip2").hidden = false;
              }else{
                document.getElementById("instructor_info_div").hidden = true;
                document.getElementById("tablePayslip2").hidden = true;
              }
              if (typeof (this.state.admin.text) !=='undefined'){
                document.getElementById("admin_info_div").hidden = false;
              }else{
                document.getElementById("admin_info_div").hidden = true;
              }
              if (typeof (this.state.partner.text) !=='undefined'){
                document.getElementById("partner_info_div").hidden = false;
              }else{
                document.getElementById("partner_info_div").hidden = true;
              }
            })
            return;
          },
          error: (error) => {
            alert('Unable to load payslip details. Please try your request again')
            document.getElementById("instructor_info_div").hidden = true;
            document.getElementById("tablePayslip2").hidden = true;
            document.getElementById("partner_info_div").hidden = true;
            document.getElementById("admin_info_div").hidden = true;
            return;
          }
        });
      }
      secondFunction();
    } else{
      document.getElementById("view_salary_details").hidden = true;
      document.getElementById("instructor_info_div").hidden = true;
      document.getElementById("partner_info_div").hidden = true;
      document.getElementById("admin_info_div").hidden = true;
      document.getElementById("tablePayslip2").hidden = true;
    }
    
    
   
  }

 

  render() {
    
    return  <div>
                <div id='headerPartPayslip'>
                  <Header id='HeaderPayslip' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={salary_icon} /> Payslip
                  </Header>
                </div>
                <Divider horizontal/>

                <div id='tablePayslip1'>
                  <Divider horizontal>
                    <h4>
                      <Icon name='payment' />
                      Filter Existing Salaries
                    </h4>
                  </Divider>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Salary Filter</Table.Cell>
                        <Table.Cell>
                          <Dropdown
                            className='Dropdown'
                            placeholder='Choose Salary'
                            selection
                            options={this.state.salariesOptions}
                            onChange={this.handleSalariesOptions}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Button id='payslip_button1' floated='right' size='mini' color='red' onClick={this.submitViewSalary}> Filter </Button>
                </div>

                <Divider horizontal/>

                <div id='view_salary_details'>
                  <Divider horizontal>
                    <h4>
                      Info
                    </h4>
                  </Divider>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Name</Table.Cell>
                        <Table.Cell>
                          {typeof (this.state.instructor.text) !=='undefined' ? this.state.instructor.text : typeof (this.state.partner.text) !=='undefined' ? this.state.partner.text : this.state.admin.text}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  
                  <Divider horizontal>
                    <h4>
                      Dates
                    </h4>
                  </Divider>

                  <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={4}>Start Date</Table.Cell>
                        <Table.Cell>
                          {this.state.startDate}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell width={4}>End Date</Table.Cell>
                        <Table.Cell>
                          {this.state.endDate}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  
                  <div id='partner_info_div'>
                    <Divider horizontal>
                      <h4>
                      Partner Salary
                      </h4>
                    </Divider>

                    <Table definition>
                      <Table.Body>
                        <Table.Row >
                          <Table.Cell width={4}>Salary (Partner)</Table.Cell>
                          <Table.Cell >
                            {typeof (this.state.partner.salary_month) !=='undefined' ? this.state.partner.salary_month : 0} {' EGP'}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>

                  <div id='admin_info_div'>
                    <Divider horizontal>
                      <h4>
                        Administrator Salary
                      </h4>
                    </Divider>

                    <Table definition>
                      <Table.Body>
                        <Table.Row >
                          <Table.Cell width={4}>Salary (Admin)</Table.Cell>
                          <Table.Cell >
                            {typeof (this.state.admin.salary_month) !=='undefined' ? this.state.admin.salary_month : 0} {' EGP'}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>

                  <div id='instructor_info_div'>
                    <Divider horizontal>
                      <h4>
                        Instructor Salary
                      </h4>
                    </Divider>

                    <Table definition>
                      <Table.Body>
                        <Table.Row >
                          <Table.Cell width={4}> Salary (Instructor) </Table.Cell>
                          <Table.Cell >
                            {this.state.instructor.salary_month} {' EGP'}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                  <div id = 'tablePayslip2'>
                      <BasicTable key ={this.state.instructorAttendances}  data = {this.state.instructorAttendances} />
                  </div>
                </div>
               
                

              
            </div>
  }
  
}


export default SalaryPayslip;
