import React, { Component } from 'react';
import { Header, Image, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import stud_icon from '../../../media/Images/student.png';
import '../../../stylesheets/attendanceStudentInvalid.css'
//import $ from 'jquery';


class MainStudInvalidSub extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      attendanceId:0,
      students: [],
      studentsToAdd:[]
    }
    this.setStudentsToAdd = this.setStudentsToAdd.bind(this)
  }

  setStudentsToAdd(e){
    this.setState({studentsToAdd: e})
  }

  componentDidMount(){
    this.setState({
      students: this.props.students,
      attendanceId: this.props.attendanceId
    })
    
  }
  
  navTo = (uri) =>{
    this.props.history.push(uri);
  }

  addStudents  = (event) =>{
    event.preventDefault();
    /*$.ajax({
      url: `/classes/${this.state.classId}/students`, //TODO: update request URL
      type: "POST",
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({
        type:'add',
        students: this.state.studentsToAdd,
        studentId:0
      }),
      xhrFields: {
        withCredentials: true
      },
      crossDomain: true,
      success: (result) => {
        this.navTo(`/classes/${this.state.classId}`)
        return;
      },
      error: (error) => {
        alert('Unable to add the dance. Please try your request again')
        return;
      }
    })*/
  }
 


  render() {
    console.log('studentsToAdd',this.state.studentsToAdd)
    
    return <div>
                <div id='headerPartAttendanceStudInvalid'>
                  <Header id='HeaderAttendanceStudInvalid' as='h2' block={true} >
                    <Image size='large' circular src={stud_icon} /> Students Without a Valid Subscription
                  </Header>
                </div>
                <Divider horizontal/>
                
                <div id='tableStudAttInvalid'>
                    <BasicTable key ={this.props.students}  data = {this.props.students} studentsToAdd={this.state.studentsToAdd} setStudentsToAdd={this.setStudentsToAdd} attendanceId={this.props.attendanceId} history={this.props.history}/>
                </div>
                <Divider horizontal/>
                
           </div>
  }
  
}


export default MainStudInvalidSub;
