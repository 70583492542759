import React, { Component } from 'react';
import { Header, Image, Button, Divider, Form, Segment} from 'semantic-ui-react'
import CardTemplate from '../CardTemplate';
import inst_icon from '../../media/Images/admin_icon.png';
import no_profile_picture from '../../media/Images/no_profile_picture.png';
import '../../stylesheets/adminEdit.css'
import $ from 'jquery';
import track from '../track';



class AdminEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      adminId:0,
      userId:0,
      username:'',
      firstName: '',
      lastName: '',
      address: '',
      email: '',
      phone: '',
      role:'',
      Password:'',
      PasswordRe:'',

      profile_picture: null,
      imageURL: '',

      admin: {},
      
      salaryMonth: 0,

      values:[],
    }
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  componentDidMount(){
    //document.getElementById('profile_picture_change').hidden= true;
    document.getElementById("resetAdminEdit").hidden=true;
    
    this.setState({adminId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/admins/${this.state.adminId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              admin: result.admin,
              userId: result.admin.user_id,
              username: result.admin.username,
              firstName: result.admin.first_name,
              lastName: result.admin.last_name,
              address: result.admin.address,
              email: result.admin.email,
              phone: result.admin.phone,
              salaryMonth: result.admin.salary_month,
              role: result.admin.role,
              values: result.admin.values
            }, async ()=> {
              
              $.ajax({
                url: `/uploads/${this.state.adminId}/admins`, //TODO: update request URL
                type: "GET",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                processData: false,
                contentType: false,
                cache: false,
                timeout: 800000,
                              
                success: (result) => {
                  this.setState({ imageURL: `data:image/jpeg;base64,${result}`  })
                  return;
                },
                error: (error) => {
                  this.setState({ imageURL: no_profile_picture })
                  return;
                }
              })
            })
            return ;
          },
          error: (error) => {
            alert('Unable to load admin. Please try your request again')
            return;
          }
        })
      }
      secondFunction();
    }) 
  }

  handleUsername = (event) =>{
    this.setState({username:event.target.value})
  }

  handleFirstName = (event) =>{
    this.setState({firstName:event.target.value})
  }

  handleLastName = (event) =>{
    this.setState({lastName:event.target.value})
  }

  handleAddress = (event) =>{
    this.setState({address:event.target.value})
  }

  handleEmail = (event) =>{
    this.setState({email:event.target.value})
  }

  handlePhone = (event) =>{
    this.setState({phone:event.target.value})
  }

  adminDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/admins/${this.state.adminId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          user_id: this.state.userId,
          username: this.state.username,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          address: this.state.address,
          email: this.state.email,
          phone: this.state.phone,
          salary_month: this.state.salaryMonth,
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          //this.navTo('/admins');
          track(`deleted admin, username = ${this.state.username} `,
                    'Administrator', 'Delete')        
          this.props.history.push('/admins');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/admins/${this.state.adminId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              user_id: this.state.userId,
              username: this.state.username,
              first_name: this.state.firstName,
              last_name: this.state.lastName,
              address: this.state.address,
              email: this.state.email,
              phone: this.state.phone,
              salary_month: this.state.salaryMonth
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              track(`deleted admin, username = ${this.state.username} `,
                    'Administrator', 'Delete')        
              
              this.props.history.push('/admins');
              return;
            },
            error: (error) => {
              alert('Unable to delete admin. Please try your request again')
              return;
            }
          })
          return;
        }
      })  
    }
    secondFunction();
  }

  
  adminEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/admins/${this.state.adminId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          user_id: this.state.userId,
          username: this.state.username,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          address: this.state.address,
          email: this.state.email,
          phone: this.state.phone,
          salary_month: this.state.salaryMonth
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          //this.navTo('/admins');
          track(`Updated admin, username = ${this.state.username} `,
                    'Administrator', 'Update')
          
          this.props.history.push('/admins');
          return;
        },
        error: (error) => {
          alert(`Unable to update admin - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
        }
      })
    }  
    secondFunction();
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  cancelId = ()=>{
    //this.navTo(`/admins`)
    this.props.history.push('/admins');
  }

  editProfilePictureShow = () =>{
    //document.getElementById('profile_picture_change').hidden= false;
  }

  handleProfilePicture = (event)=>{
    this.setState({profile_picture: event.target.files[0]})
  }

  handleSalary = (event) => {
    this.setState({
      salaryMonth:event.target.value
    })
  }

  changeProfilePicture = (e)=>{
    e.preventDefault();
  
    var data1 = new FormData();
    data1.append('file', this.state.profile_picture);
    data1.append('filename', this.state.username);

    //fetch(`http://localhost:5000/uploads/${this.state.adminId}/admins`, { method: 'POST', body: data })
    //.then((response) => { response.json().then((body) => {
    //    this.setState({ imageURL: `http://localhost:5000/${body.file}` });
    //  });
    //});
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/uploads/${this.state.adminId}/admins`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        enctype: 'multipart/form-data',
        data: data1,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 800000,
                      
        success: (result) => {
          this.setState({ imageURL: URL.createObjectURL(this.state.profile_picture) })
          track(`Updated admin's picture of username = ${this.state.username} `,
                    'Administrator', 'Update')
          
          return;
        },
        error: (error) => {
          alert('Unable to change profile picture. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }


  handlePassword = (event) =>{
    this.setState({Password:event.target.value})
  }

  handlePasswordRe = (event) =>{
    this.setState({PasswordRe:event.target.value})
  }

  reset_password = ()=>{
    if (document.getElementById("resetAdminEdit").hidden){
      document.getElementById("resetAdminEdit").hidden=false;
      document.getElementById("resetAdminEdit").scrollIntoView();
    }else{
      document.getElementById("resetAdminEdit").hidden=true;
    } 
  }


  resetPassword = (event) =>{
    event.preventDefault();
    if(this.state.Password === '' || this.state.PasswordRe === '' || this.state.Password !== this.state.PasswordRe ){
      alert("Unable to change password, please enter a valid password")
    } else{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/admins/${this.state.adminId}/resetPassword`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            password: this.state.Password,
            re_password: this.state.PasswordRe
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            document.getElementById("resetAdminEdit").hidden=true;
            track(`Reset Password of admin, username = ${this.state.username} `,
                    'Administration', 'Update')          
            return;
          },
          error: (error) => {
            alert(`Unable to reset Password - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
          }
        })
      }  
      secondFunction();
    }
  }

  render() {
    
    
    return <div>
                <div id='headerPartAdminEdit'>  
                  <Header id='HeaderAdminEdit' as='h2' block={true}>
                      <Image size='large' circular src={this.state.imageURL} /> {this.state.firstName +" "+ this.state.lastName}
                  </Header>
                </div>
                <Divider horizontal/>
                
                <div id ='containerAdminEdit'>
                  <div id='cardAdminEdit'>
                    <CardTemplate
                    name={this.state.firstName +" "+ this.state.lastName}
                    img ={this.state.imageURL}
                    role = {this.state.role}
                    email = {this.state.email}
                    phoneNumber = {this.state.phone} 
                    onClick= {this.editProfilePictureShow}
                    ></CardTemplate>
                  </div>
                  <div id='addFormAdminEdit'>
                    <Segment id="addAdminEdit" inverted>
                      <Form id='formAdminEdit' inverted>
                      <Form.Group>
                        <Form.Field className='formHalfWidthAdminEdit'>
                            <label className="labelAdminEdit">Username</label>
                            <input className ='input' defaultValue={this.state.username} onChange={this.handleUsername} readOnly/>
                        </Form.Field>
                        <Form.Field className='formHalfWidthAdminEdit'>
                            <label className="labelAdminEdit">User ID</label>
                            <input className ='input' type='number' value={this.state.userId} readOnly/>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field className='formHalfWidthAdminEdit'>
                            <label className="labelAdminEdit">First Name</label>
                            <input className ='input' defaultValue={this.state.firstName} onChange={this.handleFirstName}/>
                        </Form.Field>
                        <Form.Field className='formHalfWidthAdminEdit'>
                            <label className="labelAdminEdit">Last Name</label>
                            <input className ='input' defaultValue={this.state.lastName} onChange={this.handleLastName}/>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field className='fullWidthFormAdminEdit'>
                            <label className="labelAdminEdit">Address</label>
                            <input className ='input' defaultValue={this.state.address} onChange={this.handleAddress}/>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field className='formHalfWidthAdminEdit'>
                            <label className="labelAdminEdit">E-Mail</label>
                            <input className ='input' defaultValue={this.state.email} onChange={this.handleEmail}/>
                        </Form.Field>
                        <Form.Field className='formHalfWidthAdminEdit'>
                            <label className="labelAdminEdit">Phone</label>
                            <input className ='input' defaultValue={this.state.phone} onChange={this.handlePhone}/>
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field className='formHalfWidthAdminEdit'>
                          <label className="labelAdminEdit">Salary (EGP)</label>
                          <input className ='input' type='number' value={this.state.salaryMonth}
                          onChange={this.handleSalary}/>
                        </Form.Field>
                      </Form.Group>
                      <Divider horizontal/>  
                        <div>
                          <Button id='submitAdminEdit1' content='Primary' floated='right' type='submit' onClick={this.adminEditId}>Update</Button>
                          <Button id='submitAdminEdit2' content='Secondary' floated='right' type='submit' onClick={this.adminDeleteId}>Delete</Button>
                          <Button id='submitAdminEdit3' content='Primary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                        </div>
                      </Form>
                    </Segment>
                  </div>
                </div>
                <Divider horizontal/>
                
                <div id='profilePictureChangeAdminEdit'>
                  <Segment>
                    <Form>
                      <input type='file' accept="image/*" onChange={this.handleProfilePicture}></input>
                      <Button id='submitAdminEdit4' onClick={this.changeProfilePicture}>Change Profile Picture</Button>
                    </Form>
                  </Segment>
                </div>
                <Divider horizontal/>
                
                <div >
                  <Button id='admin_reset_password' size='mini' color='red' onClick={this.reset_password}> Reset Password </Button>
                </div>
                
                <div id='resetPasswordAdminEdit'>
                <Segment id="resetAdminEdit" inverted>
                  <Form id='formResetPasswordAdmin' inverted>
                    <Form.Group>
                      <Form.Field className='formHalfWidthAdmin'>
                        <label className="labelAdmin">Password</label>
                        <input className='input' type='password' placeholder='Password' onChange={this.handlePassword}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthAdmin'>
                        <label className="labelAdmin">Password Re-enter</label>
                        <input className='input' type='password' placeholder='Password Re-enter' onChange={this.handlePasswordRe}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitResetPasswordAdmin' floated='right' type='submit' onClick={this.resetPassword}>Submit</Button>
                  </Form>
                </Segment>
              </div>
              
           </div>
  }
  
}


export default AdminEdit;
