import React from "react";
import { useJwt } from "react-jwt";

import {LogedInMenu} from "./LogedInMenu";

//import { useAuth0 } from "@auth0/auth0-react";

export const LogedInMenuAuth = (props) => {
  let { isExpired } = useJwt(props.token);
    
  return ((typeof props.token !== 'undefined' && props.token !== null && isExpired === false) ? <LogedInMenu token ={props.token} /> : <></>);
};
 
