import React, { Component } from 'react';
import { Header, Image, Table, Icon, Divider, Button} from 'semantic-ui-react'
import salary_icon from '../../media/Images/salary.png';
import '../../stylesheets/monthlyClosing.css';
import $ from 'jquery';
import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';
import GenerateNewSalary from './GenerateNewSalary/GenerateNewSalary';
import ViewExistingSalary from './ViewExistingSalary/ViewExistingSalary';


class Closing extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      
    }
  }

  componentDidMount(){
    document.getElementById('new_salary_comp').hidden = true;
    document.getElementById('existing_salary_comp').hidden = false;
  }

  finalizeSalary = () => {
    document.getElementById('new_salary_comp').hidden = false;
    document.getElementById('existing_salary_comp').hidden = true;
  }

  filterExistingSalaries = () => {
    document.getElementById('existing_salary_comp').hidden = false;
    document.getElementById('new_salary_comp').hidden = true;
  }
  
  
  render() {
    
    return <div>
              <div id='headerPartClosing'>
                <Header id='HeaderClosing' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={salary_icon} /> Monthly Closing
                </Header>
              </div>
              <Divider horizontal/>

              <div className='closing_button_container'>
                  <Button id='closing_button1' floated='right' size='mini' color='red' onClick={this.finalizeSalary}> Finalize Current Month </Button>
                  <Button id='closing_button2' floated='right' size='mini' color='red' onClick={this.filterExistingSalaries}> View Existing Months </Button>                  
              </div>
              <Divider horizontal/>

              <div id='new_salary_comp'>
                <GenerateNewSalary refreshToken={this.props.refreshToken}/>
              </div>

              <div id='existing_salary_comp'>
                <ViewExistingSalary refreshToken={this.props.refreshToken}/>
              </div>

              
           </div>

              
  }
  
}


export default Closing;
