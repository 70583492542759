//import {format} from 'date-fns'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

/*var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}*/
//onClick={()=>navTo(`/categories/${value}`)} 

export const COLUMNS = [
    {
        width:30,
        Header:()=>'',
        accessor:'key',
        Cell:({value}) => (
            <Link to={`/categories/${value}`}><Button circular icon='edit' content='Edit'/></Link>
        ),
        disableFilters: true
    },
    {
        Header: 'Name',
        accessor: 'text'
    }
]