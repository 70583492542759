import React from 'react'
//import { Dropdown, Icon, Input, Menu } from 'semantic-ui-react'
import '../../stylesheets/Header.css';
//import {useAuth0} from '@auth0/auth0-react';
import {InstructorMenu} from './InstructorMenu' 
import { useJwt } from "react-jwt";


export const InstructorMenuAuth = (props) =>{
    let { decodedToken, isExpired } = useJwt(props.token);
    
    let isAuthenticatedInstructor = false;

    if (typeof decodedToken !== 'undefined' && decodedToken !== null && isExpired === false){
        if(decodedToken.is_instructor === true && decodedToken.is_partner === false && decodedToken.is_admin === false && decodedToken.is_student === false){
            isAuthenticatedInstructor = true;
            sessionStorage.setItem("user_id",decodedToken.user_id);
        }
        else{
            isAuthenticatedInstructor = false
        }
    }else{
        isAuthenticatedInstructor = false
    }

    return (isAuthenticatedInstructor ? <InstructorMenu user_id={decodedToken.user_id} /> :<></>);
}