import React, { Component } from 'react';
import { Header, Image, Button, Table, Divider, Icon } from 'semantic-ui-react'
import '../../../stylesheets/classInfoStudPriv.css';
import $ from 'jquery';
import InstructorsClass from './InstructorsClass/InstructorsClass'
import inst_icon from '../../../media/Images/instructor_icon.png';

class ClassInfoStudPriv extends Component {
  constructor(props) {
    super(...arguments);
    this.state = {
      classId: 1,
      userId: 1,
      classInfo: {},
      classInstructors: [],
      requestExist: false,
      studentInClass: false,
      isRejected: false,
      rejectionReason: '',
      level: {}
    }
  }

  componentDidMount() {
    document.getElementById('studReg').hidden = true;
    document.getElementById('studNotReg').hidden = true;
    document.getElementById('response').hidden = true;
    this.setState({
      classId: this.props.match.params.id,
      userId: this.props.match.params.user_id
    }, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/classes/${this.state.classId}/studentsprev`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              classInfo: result.class_info,
              classInstructors: result.instructors
              //mainReg: result.main_students
            }, async () => {
              //
              $.ajax({
                url: `/api/classes/${this.state.classId}/studentsreqs/${this.state.userId}`, //TODO: update request URL
                type: "GET",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
                },
                success: (result) => {
                  this.setState({
                    requestExist: result.request_exist,
                    studentInClass: result.student_in_class,
                    isRejected: result.is_rejected,
                    rejectionReason: result.rejection_reason
                  });
                  /*if (result.student_in_class || result.is_rejected)
                  {
                    document.getElementById('studReg').hidden = true;
                    document.getElementById('studNotReg').hidden = true;
                    if (result.is_rejected){
                      document.getElementById('response').hidden = false;
                    }else{
                      document.getElementById('response').hidden = true;
                    }
  
                  }else if (result.request_exist){
                    document.getElementById('studReg').hidden = true;
                    document.getElementById('studNotReg').hidden = false;
                    document.getElementById('response').hidden = true;
                  } else{
                    document.getElementById('studReg').hidden = false;
                    document.getElementById('studNotReg').hidden = true;
                    document.getElementById('response').hidden = true;
                  }
                  */return;
                },
                error: (error) => {
                  alert('Unable to check if class in reg request. Please try your request again')
                  return;
                }
              })
              //
            })
            return;
          },
          error: (error) => {
            alert('Unable to load class info. Please try your request again')
            return;
          }
        })
        $.ajax({
          url: `/api/classes/${this.state.classId}/levels`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              level: result.level
            })
            return;
          },
          error: (error) => {
            alert('Unable to load the class level info. Please try your request again')
            return;
          }
        });

      }
      secondFunction();
    })
  }

  requestClassReg = () => {
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/classes/${this.state.classId}/studentsreqs/${this.state.userId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type: 'add',
          rejection_reason: ''
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          //document.getElementById('studReg').hidden = true;
          //document.getElementById('studNotReg').hidden = false;
          return;
        },
        error: (error) => {
          alert('Unable to request registeration of this class. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  cancelRequestClassReg = () => {
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/classes/${this.state.classId}/studentsreqs/${this.state.userId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer ' + sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type: 'delete',
          rejection_reason: ''
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          //document.getElementById('studReg').hidden = false;
          //document.getElementById('studNotReg').hidden = true;
          return;
        },
        error: (error) => {
          alert('Unable to cancel the registeration request of this class. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  render() {
    //console.log('in class',this.state.classInstructors)

    return <div>
      <div id='headerPartClassInfoStudPriv'>
        <Header id='HeaderClassInfoStudPriv' as='h2' block={true} >
          <Image size='large' circular src={inst_icon} /> Class Instructors
        </Header>
      </div>
      <Divider horizontal />

      <div id='class_description'>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={2}>Description</Table.Cell>
              <Table.Cell>
                {this.state.classInfo.description}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={2}>Duration</Table.Cell>
              <Table.Cell>
                {this.state.classInfo.duration_minutes} minutes
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={2}>Is Fixed</Table.Cell>
              <Table.Cell>
                {this.state.classInfo.Is_fixed ? "Fixed Class" : "Onetime Class"}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={2}>Weekly Slots</Table.Cell>
              <Table.Cell>
                {this.state.classInfo.slots_count == 2 ? "Twice a week" : this.state.classInfo.slots_count == 1 ? "Once a week" : '-'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={2}>Level</Table.Cell>
              <Table.Cell>
                {this.state.level.text}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
      <Divider horizontal />



      <div>
        <InstructorsClass instructors={this.state.classInstructors} classId={this.state.classId} />
      </div>

      <div id='studReg'>
        <Button id='studRegButton' size='mini' color='red' onClick={this.requestClassReg}> Request to Register in Class</Button>
      </div>

      <div id='studNotReg'>
        <Button id='studNotRegButton' size='mini' color='red' onClick={this.cancelRequestClassReg}> Cancel Registeration Request</Button>
      </div>

      <div id='response'>
        <Divider horizontal>
          <Header as='h4'>
            <Icon name='dont' />
            Request to register in class was rejected
          </Header>
        </Divider>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={2}>rejection Reason</Table.Cell>
              <Table.Cell>{this.state.rejectionReason}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

    </div>

  }

}


export default ClassInfoStudPriv;
