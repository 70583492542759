import React from 'react'
//import { Dropdown, Icon, Input, Menu } from 'semantic-ui-react'
import '../../stylesheets/Header.css';
//import {useAuth0} from '@auth0/auth0-react';
import {PartnerInstructorMenu} from './PartnerInstructorMenu' 
import { useJwt } from "react-jwt";


export const PartnerInstructorMenuAuth = (props) =>{
    let { decodedToken, isExpired } = useJwt(props.token);
    
    let isAuthenticatedAdminInstructor = false;

    if (typeof decodedToken !== 'undefined' && decodedToken !== null && isExpired === false){
        if(decodedToken.is_admin === false && decodedToken.is_partner === true && decodedToken.is_instructor === true  && decodedToken.is_student === false){
            isAuthenticatedAdminInstructor = true;
            sessionStorage.setItem("user_id",decodedToken.user_id);
        }
        else{
            isAuthenticatedAdminInstructor = false
        }
    }else{
        isAuthenticatedAdminInstructor = false
    }

    return (isAuthenticatedAdminInstructor ? <PartnerInstructorMenu user_id={decodedToken.user_id} /> :<></>);
}