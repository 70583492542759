import React from 'react'
import { Menu, Image } from 'semantic-ui-react'
//import '../../stylesheets/Header.css';
import { Link } from 'react-router-dom';
import doubleDot from '../../media/Images/double_dot.png';


export const LoginButton = () =>{
    /*const navTo = (uri) =>{
        window.location.href = window.location.origin + uri;
    }*/
    //id='dot_style'
            
    return(
        <>
            <Menu.Item 
            className='menu_item'
            name='Sign In'
            position='right'
            as={Link}
            to={'/logins'}    
            //onClick={()=> {navTo('/logins')}}
            >
                
                <div id='sign-in-text'>
                    <Image id='dotPng_style' src={doubleDot} size = 'mini'/>
                    <div id='id_text'>sign in</div>
                </div>
            </Menu.Item>
        </>
    )
}