import React,{useState} from 'react'
import { TextArea } from 'semantic-ui-react'
import '../../stylesheets/AboutUsPart.css';
import { Link } from 'react-router-dom';

const AboutUsPart = () =>{

  const handleAboutUs = ()=>{
    document.getElementById('SCHEDULE').scrollIntoView();
  }

  
return (
      <div id='AboutUsBackground' >
        <TextArea className='textAreaAbout' value={`One of Cairo’s longest running dance studios.${'\n'}Since 2012, our mission has always been about elevating the dance scene and creating safe spaces for people to learn, practice, and socialize.${'\n'}${'\n'}With international dance curriculums and a great variety of classes, you’re guaranteed to get the best quality and the most fun.`} />
      </div>
    )
  }
  export default AboutUsPart;