import React, { Component } from 'react';
import { Header, Image, Button, Divider, Segment, Form} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../media/Images/employee_icon.png';
import '../../stylesheets/employee.css'
import $ from 'jquery';
import track from '../track';

class Employee extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      employees: [],
      
      name: '',
      email: '',
      address: '',
      phone: '',
      balanceYear:21,
      title:''
    }
  }

  componentDidMount(){
    document.getElementById("addEmployee").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/employees`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ employees: result.employees })
          return;
        },
        error: (error) => {
          alert('Unable to load employees. Please try your request again')
          return;
        }
      });
    }
    secondFunction();
  }
  
  new_employee = ()=>{
    if (document.getElementById("addEmployee").hidden){
      document.getElementById("addEmployee").hidden=false;
      document.getElementById("addEmployee").scrollIntoView();
    }else{
      document.getElementById("addEmployee").hidden=true;
      document.getElementById("tableEmployee").scrollIntoView();
    } 
  }

  handleName = (event) =>{
    this.setState({name:event.target.value})
  }

  handleAddress = (event) =>{
    this.setState({address:event.target.value})
  }

  handleEmail = (event) =>{
    this.setState({email:event.target.value})
  }

  handlePhone = (event) =>{
    this.setState({phone:event.target.value})
  }

  handleBalanceYear = (event) =>{
    this.setState({balanceYear:event.target.value})
  }

  handleTitle = (event) =>{
    this.setState({title:event.target.value})
  }
  
  addEmployee  = (event) =>{
    event.preventDefault();
    let x = 0;
    if(this.state.name === ''){
      alert("Please enter your name to be able to sign up")
    } else if(this.state.email === '' ){
      alert("Please enter your E-mail to be able to sign up")
    } else if(this.state.phone === '' ){
      alert("Please enter your phone number to be able to sign up")
    } else{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/employees`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            name: this.state.name,
            address: this.state.address,
            email: this.state.email,
            phone: this.state.phone,
            balance_year: this.state.balanceYear,
            title: this.state.title
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            track(`Added new employee, name = ${this.state.name}`,
                    'Employee', 'Add')
            document.getElementById("addEmployee").hidden=true;
            $.ajax({
              url: `/api/employees`, //TODO: update request URL
              type: "GET",
              beforeSend: function (xhr) {   //Include the bearer token in header
                xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
              },
              success: (result) => {
                this.setState({ employees: result.employees });
                document.getElementById("tableEmployee").scrollIntoView();
                return;
              },
              error: (error) => {
                alert('Unable to load employees. Please try your request again')
                return;
              }
            })
            return;
          },
          error: (error) => {
            alert(`Unable to add employee - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
          }
        });
      }
      secondFunction();
    }
  }
 


  render() {
    
    return <div>
              <div id='headerPartEmployee'>
                <Header id='HeaderEmployee' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={inst_icon} /> Employees
                </Header>
              </div>
              <Divider horizontal/>
              
              <div >
                <Button id='employee_button1' size='mini' color='red' onClick={this.new_employee}> Add Employee </Button>
              </div>
              <Divider horizontal/>

              <div id="tableEmployee">
                  <BasicTable key ={this.state.employees}  data = {this.state.employees} />
              </div>
              <Divider horizontal/>
              

              <div id='addFormEmployee'>
                <Segment id="addEmployee" inverted>
                  <Form id='formEmployee' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormEmployee'>
                        <label className="labelEmployee">Name</label>
                        <input className='input' placeholder='Name' onChange={this.handleName}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthEmployee'>
                        <label className="labelEmployee">Title</label>
                        <input className='input' placeholder='Title' onChange={this.handleTitle}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthEmployee'>
                        <label className="labelEmployee">Yearly Balance</label>
                        <input className='input' type='number' placeholder='21' onChange={this.handleBalanceYear}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='fullWidthFormEmployee'>
                        <label className="labelEmployee">Address</label>
                        <input className='input' placeholder='Address' onChange={this.handleAddress}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthEmployee'>
                        <label className="labelEmployee">E-Mail</label>
                        <input className='input' placeholder='E-Mail' onChange={this.handleEmail}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthEmployee'>
                        <label className="labelEmployee">Phone</label>
                        <input className='input' placeholder='Phone' onChange={this.handlePhone}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitEmployee' floated='right' type='submit' onClick={this.addEmployee}>Submit</Button>
                  </Form>
                </Segment>
              </div>
           </div>
  }
  
}


export default Employee;
