import { format, formatDistance, formatRelative, subDays } from 'date-fns'
// import { Button } from 'semantic-ui-react'
// import { Link } from 'react-router-dom'


export const COLUMNS = [
    {
        Header:'#',
        accessor:'id',
        disableFilters: true
    },
    {
        Header: 'User - Full Name',
        accessor: 'user_fullname'
    },
    {
        Header: 'User ID',
        accessor: 'user_id'
    },
    {
        Header: 'Description',
        accessor: 'description'
    },
    {
        Header: 'Time',
        accessor: 'time',
        Cell:({value}) =>{return formatRelative(subDays(new Date(value),0), new Date(value))}    
    },
    {
        Header: 'Entity',
        accessor: 'table_type'
    },
    {
        Header: 'Action',
        accessor: 'crud_type'
    }
    // ,{
    //     Header: 'Day',
    //     accessor: 'day'
    // }
]