//import {format} from 'date-fns'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

/*var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}*/
//onClick={()=>navTo(`/instructors/${value}`)} 

export const COLUMNS = [
    {
        maxWidth:10,
        Header: 'ID',
        accessor: 'user_id'

    },
    {
        Header: 'Name',
        accessor: 'full_name'
    },
    {
        Header: 'Address',
        accessor: 'address'
    },
    {
        Header: 'E-Mail',
        accessor: 'email'
    },
    {
        Header: 'Phone Number',
        accessor: 'phone'
    },
    {
        Header: 'Dance',
        accessor: 'dance.text'
    }
]