import React from "react";
import { Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import '../../stylesheets/Header.css';
import Site_Logo from '../../media/Images/logo.PNG';


export const HomeLogo = () => {
    
  return (
      <>
            <Menu.Item
                    className='menu_item'
                    name='home'
                    as={Link}
                    to={`/`}
                ><img src={Site_Logo} alt="Soul motion logo" ></img></Menu.Item>
      </>
  );
};