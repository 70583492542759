import React from "react";
import { Dropdown } from 'semantic-ui-react'
import '../../stylesheets/Header.css';


export const HomeMenu = ({setAboutUs,setContact}) => {
    
  return (
      <>
            <Dropdown className='menu_item' item icon='bars'>
                    <Dropdown.Menu id='lines_options'>
                        <div id='lines_option'>
                        <Dropdown.Item icon='circle' text='about us'
                        onClick={()=>setAboutUs(true)} 
                        />
                        </div>
                        <div id='lines_option'>  
                        <Dropdown.Item icon='circle' text='contact'
                        onClick={()=>setContact(true)} 
                        />
                        </div>
                    </Dropdown.Menu>
            </Dropdown>
      </>
  );
};