import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import membership_icon from '../../media/Images/membership.png';
import '../../stylesheets/App.css';
import '../../stylesheets/membershipEdit.css'
import $ from 'jquery';
import track from '../track';

class MembershipEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      membershipName: '',
      amount: 0,
      durationMonths: 0,
      membershipId:0
    }
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  componentDidMount(){
    this.setState({membershipId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/memberships/${this.state.membershipId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              membershipName: result.membership.name,
              amount:result.membership.amount,
              durationMonths : result.membership.duration_months
             })
            return;
          },
          error: (error) => {
            alert('Unable to load roam to edit. Please try your request again')
            return;
          }
        })
      }  
      secondFunction();
    })
  }

  
  membershipDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/memberships/${this.state.membershipId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          name: this.state.membershipName,
          amount: this.state.amount,
          duration_months: this.state.durationMonths
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`deleted the membership named ${this.state.membershipName} `,
                    'Membership', 'Delete')        
          
          //this.navTo('/memberships');
          this.props.history.push('/memberships');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/memberships/${this.state.membershipId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              name: this.state.membershipName,
              amount: this.state.amount,
              duration_months: this.state.durationMonths
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              track(`deleted the membership named ${this.state.membershipName} `,
                    'Membership', 'Delete')        
          
              //this.navTo('/memberships');
              this.props.history.push('/memberships');
              return;
            },
            error: (error) => {
              alert('Unable to delete the dance membership. Please try your request again')
              return;
            }
          })
          return;
        }
      })
    }
    secondFunction();
  }

  
  membershipEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/memberships/${this.state.membershipId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          name: this.state.membershipName,
          amount: this.state.amount,
          duration_months: this.state.durationMonths
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`updated the membership named ${this.state.membershipName} `,
                    'Membership', 'Update')        
          
          //this.navTo('/memberships');
          this.props.history.push('/memberships');
          return;
        },
        error: (error) => {
          alert('Unable to update the dance membership. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleMembershipName = (event) => {
    this.setState({membershipName: event.target.value})
  }
  
  handleAmount = (event) => {
    this.setState({amount: event.target.value})
  }

  handleValidity = (event) => {
    this.setState({durationMonths: event.target.value})
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  cancelId = ()=>{
    this.props.history.push('/memberships');
    //this.navTo(`/memberships`)
  }

  render() {

    return <div>
                <div id='headerPartMembershipEdit'>
                  <Header id='HeaderMembershipEdit' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={membership_icon} /> Dance Membership
                  </Header>
                </div>
                <Divider horizontal/>

                <div id='addFormMembershipEdit'>
                  <Segment id="addMembership" inverted>
                    <Form id='formMembership' inverted>  
                    <Form.Group>
                      <Form.Field className='fullWidthFormMembershipEdit'>
                        <label className ="labelMembership">Membership Name</label>
                        <input className ='input' defaultValue={this.state.membershipName} onChange={this.handleMembershipName}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthMembershipEdit'>
                        <label className ="labelMembership">Membership Price</label>
                        <input className ='input' type='number' value={this.state.amount} onChange={this.handleAmount}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthMembershipEdit'>
                        <label className="labelMembership">Validity (Months)</label>
                        <input className ='input' type='number' value={this.state.durationMonths} onChange={this.handleValidity}/>
                      </Form.Field>
                    </Form.Group>
                      <div id = 'submitMembershipEditContainer'>
                        <Button id='submitMembershipEdit1' floated='right' type='submit' onClick={this.membershipEditId}>Update</Button>
                        <Button id='submitMembershipEdit2' floated='right' type='submit' onClick={this.membershipDeleteId}>Delete</Button>
                        <Button id='submitMembershipEdit3' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                      </div>
                    </Form>
                  </Segment>
                </div>
           </div>
  }
  
}


export default MembershipEdit;
