import React, { Component } from 'react';
import { Header, Image, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import rent_icon from '../../../media/Images/rent.svg';
import '../../../stylesheets/dailyPaymentRoomRentals.css';
//import $ from 'jquery';
//import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';

class RentPaymentComponent extends Component {
  constructor(props){
    super(...arguments);
    this.state = { 
    }
  }

  componentDidMount(){
    
  }

  
  render() {
    return <div>
              <div id = 'headerPartPaymentRoomRentals'>
                <Divider horizontal>
                  <h4 id='HeaderPaymentRoomRentals'>
                  <Image id='iconPaymentRoomRentals' size='large' src={rent_icon} />
                  Room Rental Payments
                  </h4>
                </Divider>
              </div>
              
              <Divider horizontal/>
              
              
              <div id = 'tablePaymentRoomRentals'>
                <BasicTable key ={this.props.rentPayment}  data = {this.props.rentPayment}/>
              </div>

              
              
           </div>
  }  
}

export default RentPaymentComponent;
