import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import level_icon from '../../media/Images/level.png';
import '../../stylesheets/level.css'
import $ from 'jquery';
import track from '../track';

class Level extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      levels: [],
      levelName: '',
      levelRank: 0
    }
  }

  componentDidMount(){
    document.getElementById("addLevel").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/levels`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ levels: result.levels })
          return;
        },
        error: (error) => {
          alert('Unable to load dance levels. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  new_level = ()=>{
    if (document.getElementById("addLevel").hidden){
      document.getElementById("addLevel").hidden=false;
      document.getElementById("addLevel").scrollIntoView();
    }else{
      document.getElementById("addLevel").hidden=true;
      document.getElementById("tableLevel").scrollIntoView();
    } 
  }

  addLevel = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/levels`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: this.state.levelName,
          rank: this.state.levelRank,
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Added new Level named ${this.state.levelName}`,
                    'Level', 'Add')
          $.ajax({
            url: `/api/levels`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              this.setState({ levels: result.levels },async ()=> {document.getElementById("addLevel").hidden=true;})
              return;
            },
            error: (error) => {
              alert('Unable to load roams. Please try your request again')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the level. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleLevelName = (event) => {
    this.setState({levelName: event.target.value})
  }
  
  handleLevelRank = (event) => {
    this.setState({levelRank: event.target.value})
  }

  render() {
    
    return <div>
              <div id='headerPartLevel'>
                <Header id='HeaderLevel' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={level_icon} /> Dance Levels
                </Header>
              </div>
              <Divider horizontal/>

              <div >
                <Button id='level_button1' size='mini' color='red' onClick={this.new_level}> Add a Level </Button>
              </div>
              <Divider horizontal/>


              <div id = 'tableLevel'>
                  <BasicTable key ={this.state.levels}  data = {this.state.levels} />
              </div>


              <div id='addFormLevel'>
                <Segment id="addLevel" inverted>
                  <Form id='formLevel' inverted>
                    <Form.Group>
                      <Form.Field className='formHalfWidthLevel'>
                        <label className="labelLevel">Level Name</label>
                        <input className ='input' placeholder='Level Name' onChange={this.handleLevelName}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthLevel'>
                        <label className="labelLevel">Level Rank</label>
                        <input className ='input' type='number' placeholder='Level Rank' onChange={this.handleLevelRank}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitLevel' floated='right' type='submit' onClick={this.addLevel}>Submit</Button>
                  </Form>
                </Segment>
              </div>
           </div>
  }
  
}


export default Level;
