import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import room_icon from '../../media/Images/room.png';
import '../../stylesheets/room.css'
import $ from 'jquery';
import track from '../track';

class Room extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      rooms: [],
      roomName: '',
      roomCapacity:0,
      rentValue:0
    }
  }

  componentDidMount(){
    document.getElementById("addRoom").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/rooms`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ rooms: result.rooms })
          return;
        },
        error: (error) => {
          alert('Unable to load roams. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  new_room = ()=>{
    if (document.getElementById("addRoom").hidden){
      document.getElementById("addRoom").hidden=false;
      document.getElementById("addRoom").scrollIntoView();
    }else{
      document.getElementById("addRoom").hidden=true;
      document.getElementById("tableRoom").scrollIntoView();
    } 
  }

  addRoom = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/rooms`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: this.state.roomName,
          capacity: this.state.roomCapacity,
          rent_value: this.state.rentValue
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Added new room, username = ${this.state.roomName}`,
                    'Room', 'Add')
                  
          $.ajax({
            url: `/api/rooms`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              this.setState({ rooms: result.rooms },async ()=> {
                document.getElementById("addRoom").hidden=true;
                document.getElementById("tableRoom").scrollIntoView();
              })
              return;
            },
            error: (error) => {
              alert('Unable to load roams. Please try your request again')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the room. Please try your request again')
          return;
        }
      }) 
    }
    secondFunction();
  }

  handleRoomName = (event) => {
    this.setState({roomName: event.target.value})
  }
  
  handleRoomCapacity = (event) => {
    this.setState({roomCapacity: event.target.value})
  }

  handleRentValue = (event) => {
    this.setState({rentValue: event.target.value})
  }

  render() {
    
    return <div>
              <div id='headerPartRoom'>
                <Header id='HeaderRoom' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={room_icon} /> Rooms
                </Header>
              </div>
              <Divider horizontal/>

              <div >
                <Button id='room_button1' size='mini' color='red' onClick={this.new_room}> Add a Room </Button>
              </div>
              <Divider horizontal/>

              <div id = 'tableRoom'>
                <BasicTable key ={this.state.rooms}  data = {this.state.rooms} />
              </div>
              <Divider horizontal/>

              <div id='addFormRoom'>
                <Segment id="addRoom" inverted>
                  <Form id='formRoom' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormRoom'>
                        <label className="labelRoom">Room Name</label>
                        <input className ='input' placeholder='Room Name' onChange={this.handleRoomName}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthRoom'>
                        <label className="labelRoom">Capacity</label>
                        <input className ='input' type='number' placeholder='Number of students, the room could hold' onChange={this.handleRoomCapacity}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthRoom'>
                        <label className="labelRoom">Rent Value (EGP)</label>
                        <input className ='input' type='number' placeholder='NRent Value (EGP)' onChange={this.handleRentValue}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitRoom' floated='right' type='submit' onClick={this.addRoom}>Submit</Button>
                  </Form>
                </Segment>
              </div>
           </div>
  }
  
}


export default Room;
