import React, { Component } from 'react';
import { Header, Image, Button} from 'semantic-ui-react'
import {BasicTableStud} from './BasicTableStud';
import inst_icon from '../../../media/Images/student.png';
import '../../../stylesheets/App.css';
import '../../../stylesheets/student.css'
//import $ from 'jquery';


class StudentsRequestsClass extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      //instructorsToAdd:[]
    }
    //this.setInstructorsToAdd = this.setInstructorsToAdd.bind(this)
  }

  /*setInstructorsToAdd(e){
    this.setState({instructorsToAdd: e})
  }*/

  componentDidMount(){
    
  }
  navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }

  render() {
    
    return <div>
                <div></div>
                <Header as='h2'>
                    <Image circular src={inst_icon} /> Registeration Requests
                </Header>
                <div>
                    <BasicTableStud key ={this.props.studentsRequests}  data = {this.props.studentsRequests} classId={this.props.classId} setResponse={this.props.setResponse} removeResponse={this.props.removeResponse}/>
                </div>
                
                <div style={{margin:"40px"}}></div>
           </div>
  }
  
}


export default StudentsRequestsClass;
