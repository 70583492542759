import React, { Component } from "react";
import {
  Header,
  Image,
  Dropdown,
  Button,
  Icon,
  Table,
  Divider,
} from "semantic-ui-react";
import schedule_icon from "../../media/Images/schedule_icon.png";
import "../../stylesheets/MyScheduleInstructor.css";
import $ from "jquery";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Inject,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
//import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';

class MyScheduleInstructor extends Component {
  constructor(props) {
    super(...arguments);
    /*this.data = extend([], scheduleData, null, true);*/

    this.state = {
      group_classes: "Hide",
      fixed_privates: "Hide",
      privates: "Hide",
      workshops: "Hide",
      fixed_rent: "Hide",
      rent: "Hide",
      fixed_team_reservation: "Hide",
      team_reservation: "Hide",

      data: [],
      all_data: [],
      classes: [],

      room: 0,
      class_description: "",
      level_id: 1,
      duration: 60,
      dance_category_id: 1,
      dance_id: 0,
      class_type: "",
      slot_count_current: 1,

      date1_start: new Date(),
      roomNew1: 1,
      date2_start: new Date(),
      roomNew2: 1,

      slot_count: [
        {
          key: 1,
          text: "Once a week",
          value: 1,
        },
        {
          key: 2,
          text: "Twice a week",
          value: 2,
        },
      ],

      durationOptions: [
        {
          key: 30,
          text: "30 Minutes",
          value: 30,
        },
        {
          key: 60,
          text: "60 Minutes",
          value: 60,
        },
        {
          key: 90,
          text: "90 Minutes",
          value: 90,
        },
        {
          key: 120,
          text: "120 Minutes",
          value: 120,
        },
        {
          key: 150,
          text: "150 Minutes",
          value: 150,
        },
        {
          key: 180,
          text: "180 Minutes",
          value: 180,
        },
        {
          key: 210,
          text: "210 Minutes",
          value: 210,
        },
        {
          key: 240,
          text: "240 Minutes",
          value: 240,
        },
        {
          key: 270,
          text: "270 Minutes",
          value: 270,
        },
        {
          key: 300,
          text: "300 Minutes",
          value: 300,
        },
      ],

      fixed_classes: [],
      onetime_classes: [],

      rooms: [],
      rooms_choice: [
        {
          key: 0,
          value: 0,
          text: "All Rooms",
        },
      ],

      levels: [],
      dance_categories: [],
      dances_options: [],
      dances: [],
      class_types_available: [
        {
          key: "Group Class",
          text: "Group Class",
          value: "Group Class",
        },
        {
          key: "Private Class - Fixed",
          text: "Private Class - Fixed",
          value: "Private Class - Fixed",
        },
        {
          key: "Private Class",
          text: "Private Class",
          value: "Private Class",
        },
        {
          key: "Workshop",
          text: "Workshop",
          value: "Workshop",
        },
      ],
      appointments_types_available: [
        {
          key: "Fixed Appointment",
          text: "Fixed Appointment",
          value: "Fixed Appointment",
        },
        {
          key: "Onetime Appointment",
          text: "Onetime Appointment",
          value: "Onetime Appointment",
        },
      ],
      appointments_types: "Onetime Appointment",
      rentReserveDateStart: new Date(),
      rentReserveDuration: 60,
      rent_reserve: "Rent",
      rentReserveName: "",
      roomRentReserve: 1,
      roomRentPrice: 0,
      roomRentPriceAfterDiscount: 0,
      roomRentDiscount: 0,
      //handle a class & attendance
      startTimeAttendance: new Date(),
      isFixedAttendance: "",
      scheduleTypeAttendance: "",
      classIdAttendance: 0,
      schedule_id: 0,
      cancelation: "no",
    };
  }

  componentDidMount() {
    document.getElementById("class_type_buttons").hidden = true;
    document.getElementById(
      "class_attendance_info_inst_myschedule"
    ).hidden = true;
    document.getElementById("class_info").hidden = true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken();
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/rooms/${sessionStorage.getItem("user_id")}/users`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState(
            {
              rooms: result.rooms,
              rooms_choice: this.state.rooms_choice.concat(result.rooms),
            },
            async () => {
              this.showLegend();
            }
          );
          return;
        },
        error: (error) => {
          alert("Unable to load rooms. Please try your request again");
          return;
        },
      });

      $.ajax({
        url: `/api/levels/${sessionStorage.getItem("user_id")}/users`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ levels: result.levels });
          return;
        },
        error: (error) => {
          alert("Unable to load levels. Please try your request again");
          return;
        },
      });

      $.ajax({
        url: `/api/categories/${sessionStorage.getItem("user_id")}/users`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ dance_categories: result.dance_categories });
          return;
        },
        error: (error) => {
          alert(
            "Unable to load dance categories. Please try your request again"
          );
          return;
        },
      });

      $.ajax({
        url: `/api/dances/${sessionStorage.getItem("user_id")}/users`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ dances: result.dances });
          return;
        },
        error: (error) => {
          alert("Unable to load dances. Please try your request again");
          return;
        },
      });

      $.ajax({
        url: `/api/dances/${
          this.state.dance_category_id
        }/users/${sessionStorage.getItem("user_id")}`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ dances_options: result.dances });
          return;
        },
        error: (error) => {
          alert("Unable to load dances. Please try your request again");
          return;
        },
      });

      $.ajax({
        url: `/api/instructors/${sessionStorage.getItem("user_id")}/schedules/${
          this.state.room
        }`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {
          //Include the bearer token in header
          xhr.setRequestHeader(
            "Authorization",
            "Bearer " + sessionStorage.getItem("access_token")
          );
        },
        success: (result) => {
          this.setState({ all_data: result.data }, async () => {
            this.setState({ data: result.data });
          });

          return;
        },
        error: (error) => {
          //alert('Unable to load the schedule of the chosen room. Please try your request again')
          return;
        },
      });
    };
    secondFunction();
  }

  handleChange = (event, { key, value }) => {
    this.setState({ [key]: value });
  };

  chooseRoom = (event, { value }) => {
    //let previousRoom = this.state.room;
    this.setState({ room: value }, async () => {
      if (this.state.room === 0) {
        this.showLegend();
        document.getElementById("class_type_buttons").hidden = true;
        document.getElementById("calendar_inst_myschedule").scrollIntoView();
      } else {
        this.hideLegend();
        document.getElementById("class_type_buttons").hidden = false;
        document.getElementById("calendar_inst_myschedule").scrollIntoView();
      }
      const secondFunction = async () => {
        const result = await this.props.refreshToken();
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/instructors/${sessionStorage.getItem(
            "user_id"
          )}/schedules/${this.state.room}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {
            //Include the bearer token in header
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + sessionStorage.getItem("access_token")
            );
          },
          success: (result) => {
            this.setState({
              all_data: result.data,
              data: result.data,
              group_classes: "Hide",
              fixed_privates: "Hide",
              privates: "Hide",
              workshops: "Hide",
              fixed_rent: "Hide",
              rent: "Hide",
              fixed_team_reservation: "Hide",
              team_reservation: "Hide",
            });
            return;
          },
          error: (error) => {
            //alert('Unable to load the schedule of the chosen room. Please try your request again');
            this.setState({
              all_data: [],
              data: [],
              group_classes: "Hide",
              fixed_privates: "Hide",
              privates: "Hide",
              workshops: "Hide",
              fixed_rent: "Hide",
              rent: "Hide",
              fixed_team_reservation: "Hide",
              team_reservation: "Hide",
            });
            return;
          },
        });
      };
      secondFunction();
    });
  };

  groups_button = () => {
    if (this.state.group_classes === "Hide") {
      this.setState({ group_classes: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "group" && d.is_fixed === "fixed") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ group_classes: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "group" && d.is_fixed === "fixed") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  fixed_privates_button = () => {
    if (this.state.fixed_privates === "Hide") {
      this.setState({ fixed_privates: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "private" && d.is_fixed === "fixed") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ fixed_privates: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "private" && d.is_fixed === "fixed") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  privates_button = () => {
    if (this.state.privates === "Hide") {
      this.setState({ privates: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "private" && d.is_fixed === "onetime") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ privates: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "private" && d.is_fixed === "onetime") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  workshops_button = () => {
    if (this.state.workshops === "Hide") {
      this.setState({ workshops: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "workshop" && d.is_fixed === "onetime") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ workshops: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "workshop" && d.is_fixed === "onetime") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  //Rent & reserve buttons
  fixed_rent_button = () => {
    if (this.state.fixed_rent === "Hide") {
      this.setState({ fixed_rent: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "Rent" && d.is_fixed === "fixed") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ fixed_rent: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "Rent" && d.is_fixed === "fixed") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  rent_button = () => {
    if (this.state.rent === "Hide") {
      this.setState({ rent: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (d.schedule_type === "Rent" && d.is_fixed === "onetime") {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ rent: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (d.schedule_type === "Rent" && d.is_fixed === "onetime") {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  fixed_team_reservation_button = () => {
    if (this.state.fixed_team_reservation === "Hide") {
      this.setState({ fixed_team_reservation: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (
              d.schedule_type === "Team Reservation" &&
              d.is_fixed === "fixed"
            ) {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ fixed_team_reservation: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (
                d.schedule_type === "Team Reservation" &&
                d.is_fixed === "fixed"
              ) {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  team_reservation_button = () => {
    if (this.state.team_reservation === "Hide") {
      this.setState({ team_reservation: "Show" }, async () => {
        this.setState({
          data: this.state.data.filter((d) => {
            if (
              d.schedule_type === "Team Reservation" &&
              d.is_fixed === "onetime"
            ) {
              return false;
            } else {
              return true;
            }
          }),
        });
      });
    } else {
      this.setState({ team_reservation: "Hide" }, async () => {
        this.setState({
          data: this.state.data.concat(
            this.state.all_data.filter((d) => {
              if (
                d.schedule_type === "Team Reservation" &&
                d.is_fixed === "onetime"
              ) {
                return true;
              } else {
                return false;
              }
            })
          ),
        });
      });
    }
  };

  //  eventTemplate(props:{[key: string]: Object}):JSX.Element{
  eventTemplate(props) {
    //console.log("hi")
    if (props.is_all_rooms) {
      let r_id = props.room_id;
      if (props.room_id > 6) {
        r_id = props.room_id - 6 * Math.floor(props.room_id / 6);
      }
      if (props.cancelation === "yes") {
        // start of canceled
        if (props.schedule_type === "group") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
              <br></br>({props.Instructor})
            </div>
          );
        } else if (props.schedule_type === "workshop") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }

        // end of canceled
      } else if (props.is_fixed === "fixed") {
        if (props.schedule_type === "group") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
              <br></br>({props.Instructor})
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }
      } else if (props.is_fixed === "onetime") {
        if (props.schedule_type === "workshop") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id={"room" + r_id}>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }
      }
    } else {
      //per room
      if (props.cancelation === "yes") {
        // start of canceled
        if (props.schedule_type === "group") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
              <br></br>({props.Instructor})
            </div>
          );
        } else if (props.schedule_type === "workshop") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id="canceled_slot">
              Canceled
              <br></br>
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }
        // end of canceled
      } else if (props.is_fixed === "fixed") {
        if (props.schedule_type === "group") {
          return (
            <div id="group">
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
              <br></br>({props.Instructor})
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id="fixed_private">
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id="fixed_rent">
              Fixed {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id="fixed_reserve">
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }
      } else if (props.is_fixed === "onetime") {
        if (props.schedule_type === "workshop") {
          return (
            <div id="workshop">
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>
              {props.level}
            </div>
          );
        } else if (props.schedule_type === "private") {
          return (
            <div id="private">
              {props.Subject}
              {props.description === "" ? <></> : <br></br>}
              {props.description === "" ? <></> : props.description}
              <br></br>({props.Instructor})<br></br>
              <Icon name="student" />
              {props.Student}
            </div>
          );
        } else if (props.schedule_type === "Rent") {
          return (
            <div id="rent">
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        } else if (props.schedule_type === "Team Reservation") {
          return (
            <div id="reserve">
              {props.Subject}
              <br></br>
              {props.description}
            </div>
          );
        }
      }
    }
  }

  showLegend = () => {
    //console.log('Here is why',this.state.rooms.length)
    for (let i = 1; i <= 6; i++) {
      if (i <= this.state.rooms.length) {
        document.getElementById(
          "room_legend_inst_myschedule_" + i
        ).style.display = "inline";
        //console.log('Here is id',"room_legend_inst_myschedule_"+i)
      } else {
        document.getElementById(
          "room_legend_inst_myschedule_" + i
        ).style.display = "none";
        //console.log('Here is id',"room_legend_inst_myschedule_"+i)
      }
    }
  };

  hideLegend = () => {
    for (let i = 1; i <= 6; i++) {
      document.getElementById(
        "room_legend_inst_myschedule_" + i
      ).style.display = "none";
    }
  };

  onPopupOpen(args) {
    args.cancel = true;
    //console.log('data = ',args.data)
    if (typeof args.data.class_id !== "undefined") {
      this.setState(
        {
          classIdAttendance: args.data.class_id,
          startTimeAttendance: args.data.StartTime,
          isFixedAttendance: args.data.is_fixed,
          scheduleTypeAttendance: args.data.schedule_type,
          schedule_id: args.data.id,
          cancelation: args.data.cancelation,
        },
        async () => {
          const secondFunction = async () => {
            const result = await this.props.refreshToken();
            // do something else here after firstFunction completes
            $.ajax({
              url: `/api/buttons`, //TODO: update request URL
              type: "POST",
              beforeSend: function (xhr) {
                //Include the bearer token in header
                xhr.setRequestHeader(
                  "Authorization",
                  "Bearer " + sessionStorage.getItem("access_token")
                );
              },
              dataType: "json",
              contentType: "application/json",
              data: JSON.stringify({
                class_id: this.state.classIdAttendance,
                date_start: this.state.startTimeAttendance,
                is_fixed: this.state.isFixedAttendance,
                schedule_type: this.state.scheduleTypeAttendance,
                schedule_id: this.state.schedule_id,
                cancelation: this.state.cancelation,
              }),
              xhrFields: {
                withCredentials: true,
              },
              crossDomain: true,
              success: (result) => {
                //here
                /* 'pay_rent': pay_rent,
              'cancel_once': cancel_once,
              'cancel_once_restore': cancel_once_restore,
              'completely_cancel':completely_cancel */
                if (this.state.cancelation === "no") {
                  document.getElementById(
                    "class_attendance_info_inst_myschedule"
                  ).hidden = false;
                  document
                    .getElementById("class_attendance_info_inst_myschedule")
                    .scrollIntoView();

                  if (result.class_info === "show") {
                    document.getElementById("class_info").hidden = false;
                  } else {
                    document.getElementById("class_info").hidden = true;
                  }
                } else if (this.state.cancelation === "yes") {
                  document.getElementById(
                    "class_attendance_info_inst_myschedule"
                  ).hidden = true;
                  document
                    .getElementById("calendar_inst_myschedule")
                    .scrollIntoView();
                  document.getElementById("class_info").hidden = true;
                }
              },
              error: (error) => {
                alert(
                  "Unable to configure the related buttons. Please try again later"
                );
                return;
              },
            });
          };
          secondFunction();
        }
      );
    } else {
      document.getElementById(
        "class_attendance_info_inst_myschedule"
      ).hidden = true;
      document.getElementById("class_info").hidden = true;
    }
  }

  navTo = (uri) => {
    window.location.href = window.location.origin + uri;
  };

  render() {
    return (
      <div>
        <div id="headerPartInstMySchedule">
          <Header
            id="scheduleHeaderInstMySchedule"
            as="h2"
            block={true}
            value={this.state.room}
          >
            <Image size="large" circular src={schedule_icon} /> My Schedule
          </Header>
        </div>
        <Divider horizontal />

        <ScheduleComponent
          id="calendar_inst_myschedule"
          width="100%"
          height="550px"
          showHeaderBar={false}
          howHeaderBar={false}
          currentView="Week"
          firstDayOfWeek={6}
          startHour="12:00"
          endHour="00:00"
          eventSettings={{
            dataSource: this.state.data,
            template: this.eventTemplate.bind(this),
          }}
          // timezone="Etc/GMT-2"
          popupOpen={this.onPopupOpen.bind(this)}
        >
          <ViewsDirective>
            <ViewDirective
              option="Week"
              timeScale={{ enable: true, slotCount: 2 }}
              allowVirtualScrolling={false}
              headerRows="hour"
            />
          </ViewsDirective>
          <Inject
            services={[Day, Week, WorkWeek]}
            timeScale={{ enable: true, slotCount: 2 }}
            allowVirtualScrolling={false}
            headerRows="hour"
          />
        </ScheduleComponent>

        <Divider horizontal />

        <div id="cont_block_inst_myschedule">
          <div id="legends_inst_myschedule">
            <div id="room_legend_inst_myschedule_1">
              <Icon id="legend_1_inst_myschedule" name="circle" />{" "}
              {this.state.rooms.length >= 1
                ? this.state.rooms[0].text
                : "Room 1"}
            </div>
            <div id="room_legend_inst_myschedule_2">
              <Icon id="legend_2_inst_myschedule" name="circle" />{" "}
              {this.state.rooms.length >= 2
                ? this.state.rooms[1].text
                : "Room 2"}
            </div>
            <div id="room_legend_inst_myschedule_3">
              <Icon id="legend_3_inst_myschedule" name="circle" />{" "}
              {this.state.rooms.length >= 3
                ? this.state.rooms[2].text
                : "Room 3"}
            </div>
            <div id="room_legend_inst_myschedule_4">
              <Icon id="legend_4_inst_myschedule" name="circle" />{" "}
              {this.state.rooms.length >= 4
                ? this.state.rooms[3].text
                : "Room 4"}
            </div>
            <div id="room_legend_inst_myschedule_5">
              <Icon id="legend_5_inst_myschedule" name="circle" />{" "}
              {this.state.rooms.length >= 5
                ? this.state.rooms[4].text
                : "Room 5"}
            </div>
            <div id="room_legend_inst_myschedule_6">
              <Icon id="legend_6_inst_myschedule" name="circle" />{" "}
              {this.state.rooms.length >= 6
                ? this.state.rooms[5].text
                : "Room 6"}
            </div>
          </div>

          <Divider horizontal />

          <div id="class_attendance_info_inst_myschedule">
            <div className="itemScheduleActionsInstMySchedule" id="class_info">
              <button
                id="class_attendance_info_inst_myschedule_button"
                onClick={() => {
                  this.props.history.push(
                    `/instructors/${sessionStorage.getItem(
                      "user_id"
                    )}/classes/${this.state.classIdAttendance}`
                  );
                }}
              >
                {" "}
                Class Info{" "}
              </button>
            </div>
          </div>
        </div>

        {/*Start of class Type buttons*/}
        <div id="class_type_buttons">
          <Divider horizontal>
            <h4>
              <Icon name="hide" />
              <Icon name="unhide" />
              Actions
            </h4>
          </Divider>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button1"
                    size="mini"
                    onClick={this.groups_button}
                  >
                    {" "}
                    {this.state.group_classes} Group Classes{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button2"
                    size="mini"
                    onClick={this.fixed_privates_button}
                  >
                    {" "}
                    {this.state.fixed_privates} Fixed Private Classes{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button3"
                    size="mini"
                    onClick={this.privates_button}
                  >
                    {" "}
                    {this.state.privates} Private Classes{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button4"
                    size="mini"
                    onClick={this.workshops_button}
                  >
                    {" "}
                    {this.state.workshops} Workshops{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button5"
                    size="mini"
                    onClick={this.fixed_rent_button}
                  >
                    {" "}
                    {this.state.fixed_rent} Fixed Rents{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button6"
                    size="mini"
                    onClick={this.rent_button}
                  >
                    {" "}
                    {this.state.rent} Rents{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button7"
                    size="mini"
                    onClick={this.fixed_team_reservation_button}
                  >
                    {" "}
                    {this.state.fixed_team_reservation} Fixed Team Reservations{" "}
                  </Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    id="per_room_button8"
                    size="mini"
                    onClick={this.team_reservation_button}
                  >
                    {" "}
                    {this.state.team_reservation} Team Reservations{" "}
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        {/*End of class Type buttons*/}

        {/*Start Room*/}
        <div id="schedule_actions">
          <Divider horizontal>
            <h4>
              <Icon name="calendar" />
              Actions
            </h4>
          </Divider>
          <Table definition>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>Select a Room</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    placeholder="Select a Room"
                    selection
                    options={this.state.rooms_choice}
                    onChange={this.chooseRoom}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        {/*End Room*/}
      </div>
    );
  }
}

export default MyScheduleInstructor;
