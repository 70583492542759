import $ from 'jquery';

const track = function(description, table_type, crud_type){
    $.ajax({
        url: `/api/tracks`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
            description: description,
            table_type:table_type,
            crud_type: crud_type
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
            return;
        },
        error: (error) => {
            return;
        }
    });
}
export default track;
