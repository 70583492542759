import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import category_icon from '../../media/Images/category.png';
import '../../stylesheets/categoryEdit.css'
import $ from 'jquery';
import track from '../track';

class CategoryEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      categoryName: '',
      categoryId:0
    }
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  componentDidMount(){
    this.setState({categoryId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/categories/${this.state.categoryId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              categoryName: result.category.text
             })
            return;
          },
          error: (error) => {
            alert('Unable to load roam to edit. Please try your request again')
            return;
          }
        })
      }  
      secondFunction();
    })
  }

  
  categoryDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/categories/${this.state.categoryId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          name: this.state.categoryName
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`deleted the dance category named ${this.state.categoryName} `,
                    'Dance_Category', 'Delete')        
          
          //this.navTo('/categories'); 
          this.props.history.push('/categories');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/categories/${this.state.categoryId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              name: this.state.categoryName
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              track(`deleted the dance category named ${this.state.categoryName} `,
                    'Dance_Category', 'Delete')        
          
              //this.navTo('/categories');
              this.props.history.push('/categories');
              return;
            },
            error: (error) => {
              alert('Unable to delete the dance category. Please try your request again')
              return;
            }
          })
          return;
        }
      })
    }
    secondFunction();
  }

  
  categoryEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/categories/${this.state.categoryId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          name: this.state.categoryName
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Updated the dance category named ${this.state.categoryName} `,
                    'Dance_Category', 'Update')        
          
          //this.navTo('/categories');
          this.props.history.push('/categories');
          return;
        },
        error: (error) => {
          alert('Unable to update the dance category. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  cancelId = ()=>{
    //this.navTo(`/categories`)
    this.props.history.push('/categories');
  }

  handleCategoryName = (event) => {
    this.setState({categoryName: event.target.value})
  }

  render() {
    //console.log(this.props.match.params.id)
    //console.log(this.state.categoryName)

    return <div>
                <div id='headerPartCategoryEdit'>
                  <Header id='HeaderCategoryEdit' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={category_icon} /> Categories
                  </Header>
                </div>
                <Divider horizontal/>

                <div id='addFormCategoryEdit'>
                  <Segment id="addCategoryEdit" inverted>
                    <Form id='formCategoryEdit' inverted>
                      <Form.Group>
                        <Form.Field className='fullWidthFormCategoryEdit'>
                          <label className="labelCategoryEdit">Category Name</label>
                          <input className ='input' defaultValue={this.state.categoryName} onChange={this.handleCategoryName}/>
                        </Form.Field>
                      </Form.Group>
                      <Divider horizontal/>
                      <div id = 'submitCategoryEditContainer'>
                        <Button id='submitCategoryEdit1' content='Primary' floated='right' type='submit' onClick={this.categoryEditId}>Update</Button>
                        <Button id='submitCategoryEdit2' content='Secondary' floated='right' type='submit' onClick={this.categoryDeleteId}>Delete</Button>
                        <Button id='submitCategoryEdit3' content='Primary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                      </div>
                    </Form>
                  </Segment>
                </div>
           </div>
  }
  
}


export default CategoryEdit;
