import React, {useMemo} from 'react'
import {useTable, useSortBy, useRowSelect} from 'react-table'
import {COLUMNS} from './columns'
import '../../stylesheets/table.css'
//import { Checkbox} from './Checkbox'



export const BasicTable = (props) =>{
    /*var navTo = (uri) =>{
        window.location.href = window.location.origin + uri;
    }*/
    
    for (var i = 0; i < props.data.length; i++) {
        if (props.data[i].percentage > 0){
            props.data[i].percentage = props.data[i].percentage*100 + " %";
        }
    }

    const columns = useMemo(()=> COLUMNS,[])
    const data = useMemo(()=> props.data,[props]) /* use on instructors throuh props*/

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
//        selectedFlatRows
        } = useTable({
            columns: columns,
            data: data
        },
        useSortBy, 
        useRowSelect,
        (hooks)=>{
            hooks.visibleColumns.push((columns)=>{
                return [
                    ...columns
                ]
            })

        }
    )

    /*useEffect(() => {
        const x = selectedFlatRows.map((row)=>row.original)    
        if (x.length === 0) {
            //console.log("nothing")
        }
        else{
            const y = x[0];
            //console.log(y.value);
            //navTo(`/rooms/${y.value}`)
            this.props.history.push(`/rooms/${y.value}`);
        }
    })*/

    return(
        <div key ={props.data}>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? "▼": "▲"): ''}
                                        </span>
                                    </th>
                                ))
                            }
                            
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {
                                        row.cells.map((cell) => {
                                            return <td  {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        })
                                    }
                                </tr>
                            )
                        })
                    }

                </tbody>

            </table>
        </div>
    )
} 