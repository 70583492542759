import React, { Component } from 'react';
import { Header, Image} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import '../../../../stylesheets/App.css';
import '../../../../stylesheets/instructor.css'
//import $ from 'jquery';


class InstructorsClass extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      
    }
  }

  componentDidMount(){
    
  }
  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  render() {
    
    return <div>
                <div>
                    <BasicTable key ={this.props.instructors}  data = {this.props.instructors} classId={this.props.classId}/>
                </div>
           </div>
  }
  
}


export default InstructorsClass;
