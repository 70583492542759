import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Dropdown, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import subscription_icon from '../../media/Images/subscription.png';
import '../../stylesheets/subscription.css'
import $ from 'jquery';

class Subscription extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      categories: [],
      subscriptions: [],
      subscriptionName: '',
      amount: 0,
      durationWeeks: 0,
      numberClasses: 0,
      danceCatId:0,
      types : [{
                  "key": 1,
                  "text": "Group Classes",
                  "value": 1
              },
              {
                  "key": 2,
                  "text": "Private Classes",
                  "value": 2
              },
              {
                  "key": 3,
                  "text": "Workshops",
                  "value": 3
              }],
      isPrivate:false,
      isWorkshop:false
    }
  }

  componentDidMount(){
    document.getElementById("add_subscription").hidden=true;
    document.getElementById("add_new").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/categories`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ categories: result.dance_categories })
          return;
        },
        error: (error) => {
          alert('Unable to load dance categories. Please try your request again')
          return;
        }
      })
      
      $.ajax({
        url: `/api/subscriptions`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ subscriptions: result.subscriptions })
          return;
        },
        error: (error) => {
          alert('Unable to load subscriptions. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  new_subscription = ()=>{
    if (document.getElementById("add_subscription").hidden){
      document.getElementById("add_subscription").hidden=false;
    }else{
      document.getElementById("add_subscription").hidden=true;
    } 
  }

  addSubscription = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/subscriptions`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: this.state.subscriptionName,
          amount: this.state.amount,
          duration_weeks: this.state.durationWeeks,
          number_classes: this.state.numberClasses,
          dance_cat_id: this.state.danceCatId,
          is_private: this.state.isPrivate,
          is_workshop: this.state.isWorkshop
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          $.ajax({
            url: `/api/subscriptions`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              this.setState({ subscriptions: result.subscriptions },async ()=> {document.getElementById("add_subscription").hidden=true;})
              return;
            },
            error: (error) => {
              alert('Unable to load roams. Please try your request again')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the subscription. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleSubscriptionName = (event) => {
    this.setState({subscriptionName: event.target.value})
  }
  
  handleAmount = (event) => {
    this.setState({amount: event.target.value})
  }

  handleChangeCategory = (event, {value}) => {
    this.setState({danceCatId: value}, async ()=> {
      //console.log(this.state.danceCatId)
    })
  }

  handleChangeTypes = (event, {value}) => {
    // eslint-disable-next-line
    if(value == 1){
      this.setState({isPrivate: false , isWorkshop: false})
    }// eslint-disable-next-line
    else if (value == 2){
      this.setState({isPrivate: true , isWorkshop: false})
    }// eslint-disable-next-line
    else if (value == 3){
      this.setState({isPrivate: false , isWorkshop: true})
    }
  }
  
  handleValidity = (event) => {
    this.setState({durationWeeks: event.target.value})
  }

  handleNumberClasses = (event) => {
    this.setState({numberClasses: event.target.value})
  }


  render() {
    
    return <div>
                <div id='headerPartSubscription'>
                  <Header id='HeaderSubscription' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={subscription_icon} /> Subscriptions
                  </Header>
                </div>
                <Divider horizontal/>
                
                <div id = 'tableSubscription'>
                    <BasicTable key ={this.state.subscriptions}  data = {this.state.subscriptions} />
                </div>
                <Divider horizontal/>

              <div id ='add_new' style={{margin:"20px"}}>
                <Button.Group widths='1'>
                  <Button id='calendar_button1' size='mini' color='red' onClick={this.new_subscription}> Add New Subscription </Button>
                </Button.Group>
              </div>


              <div id='form-div'>
                <Segment id="add_subscription" inverted>
                  <Form id='form' Inverted>
                    <Form.Group>
                      <Form.Field className='form1'>
                        <label id="label">Subscription Type</label>
                        <Dropdown
                          placeholder='Select the subscription type'
                          selection
                          options={this.state.types}
                          onChange={this.handleChangeTypes}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='form2'>
                        <label id="label">Dance Category</label>
                        <Dropdown
                          placeholder='Select the dance category'
                          selection
                          options={this.state.categories}
                          onChange={this.handleChangeCategory}
                        />
                      </Form.Field>

                      
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='form2'>
                        <label id="label">Subscription Name</label>
                        <input id ='input' placeholder='Subscription Name' onChange={this.handleSubscriptionName}/>
                      </Form.Field>
                    <Form.Field className='form2'>
                        <label id="label">Subscription Price</label>
                        <input id ='input' type='number' placeholder='Subscription Price' onChange={this.handleAmount}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='form2'>
                        <label id="label">Validity (weeks)</label>
                        <input id ='input' type='number' placeholder='Validity (weeks)' onChange={this.handleValidity}/>
                      </Form.Field>
                      <Form.Field className='form2'>
                        <label id="label">Number of Classes</label>
                        <input id ='input' type='number' placeholder='Number of Classes' onChange={this.handleNumberClasses}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submit' floated='right' type='submit' onClick={this.addSubscription}>Submit</Button>
                  </Form>
                </Segment>
              </div>
              <div style={{margin:"40px"}}></div>
              <div style={{margin:"40px"}}></div>
           </div>
  }
  
}


export default Subscription;
