//import {format} from 'date-fns'
import { Button } from 'semantic-ui-react'

var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}

export const COLUMNS = [
    {
        Header:()=>'Edit Student',
        accessor:'id',
        Cell:({value}) => (
            <Button circular icon='edit' onClick={()=>navTo(`/students/${value}`)} />
        ),
        disableFilters: true
    },
    {
        Width:10,
        Header: 'ID',
        accessor: 'user_id'
    },
    {
        Header: 'Student Name',
        accessor: 'full_name'
    },
    {
        Header: 'Due Amount (EGP)',
        accessor:'due_amount' 
    }
]