import {format} from 'date-fns'

import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export const COLUMNS = [
    /*{
        width:10,
        Header:()=>'',
        accessor:'key',
        Cell:({value}) => (
            <Link to={`/`}><Button circular icon='edit' content='View Details'/></Link>
        ),
        disableFilters: true
    },*/
    {
        Header: 'Payment Date',
        accessor: 'payment_date',
        Cell:({value}) =>{return format(new Date(value), "eeee, dd/MM/yyyy hh:mm aaaaa'm'")}
    },
    {
        Header: 'Renter Name',
        accessor: 'attendance.rent_name',
    },
    {
        Header: 'Rental Date',
        accessor: 'attendance.start',
        Cell:({value}) =>{return format(new Date(value), 'eeee, dd/MM/yyyy')}
    },
    {
        Header: 'Payment Amount (EGP)',
        accessor: 'value',
    },
    {
        Header: 'Method',
        accessor: 'method',
    },
    {
        Header: 'Remaining Amount(EGP)',
        accessor: 'attendance.rent_remaining'
    },
    {
        Header: 'Total Amount AD (EGP)',
        accessor: 'attendance.rent_value_after_discount'
    },
    {
        Header: 'Discount',
        accessor: 'attendance.rent_discount',
        Cell:({value}) =>{return `${value* 100} %`}
    },
    {
        Header: 'Total Amount BD (EGP)',
        accessor: 'attendance.rent_value'
    }
]