import React, { Component } from 'react';
import { Header, Image, Button, Divider, Form, Segment, Table, Dropdown} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../media/Images/employee_icon.png';
import '../../stylesheets/leaveEdit.css'
import $ from 'jquery';
import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';
import track from '../track';

class Leave extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      leaveId:0,
      leave: {},
      leaveInstances: [],

      startDate: new Date(),
      endDate: new Date(),
    }
    this.removeLeaves = this.removeLeaves.bind(this)
  }

  getLeaveInfo(){
    $.ajax({
      url: `/api/leaves/${this.state.leaveId}`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {   //Include the bearer token in header
        xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
      },
      success: (result) => {
        this.setState({ 
          leave: result.leave,
          leaveInstances: result.leave_instances
        })
        return;
      },
      error: (error) => {
        alert('Unable to load leave info. Please try your request again')
        return;
      }
    });
  }

  componentDidMount(){
    this.setState({leaveId: this.props.match.params.id}, async () => {
      document.getElementById("addLeaveEdit").hidden=true;
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        this.getLeaveInfo()
      }
      secondFunction();
    })    
  }
  
  addLeaves  = (event) =>{
    event.preventDefault();
    if(this.state.endDate<this.state.startDate){
      alert("The end date has to be after the start date!")
    } else{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/leaves/${this.state.leaveId}`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            action:'add',
            leave_instant_id: 0,
            leave_id: this.state.leaveId,
            start_date: this.state.startDate,
            end_date: this.state.endDate
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            track(`added leave from ${this.state.startDate} to ${this.state.endDate}`,
                    'Leave', 'Added')        
          
            this.getLeaveInfo()
            document.getElementById("addLeaveEdit").hidden=true;
            return;
          },
          error: (error) => {
            alert(`Unable to add leave"}`)
            return;
          }
        });
      }
      secondFunction();
      
    }
    
  }
  
  removeLeaves(id){
    let x = 0;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/leaves/${this.state.leaveId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          action:'delete',
          leave_instant_id: id,
          leave_id: this.state.leaveId,
          start_date: this.state.startDate,
          end_date: this.state.endDate
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`deleted leave from ${this.state.startDate} to ${this.state.endDate}`,
                    'Leave', 'Delete')        
          
          this.getLeaveInfo()
          document.getElementById("addLeaveEdit").hidden=true;
      
          return;
        },
        error: (error) => {
          alert(`Unable to remove this leave instance"}`)
          return;
        }
      });
      }
      secondFunction();
    
  }

  handleStartDate = (event) => {
    this.setState({
        startDate: event.target.value
    });
  }
  handleEndDate = (event) => {
    this.setState({
        endDate: event.target.value
    });
  }
  
  showAddForm = ()=>{
    if (document.getElementById("addLeaveEdit").hidden){
      document.getElementById("addLeaveEdit").hidden=false;
      document.getElementById("addLeaveEdit").scrollIntoView();
    }else{
      document.getElementById("addLeaveEdit").hidden=true;
      document.getElementById("addLeaveEdit1").scrollIntoView();
    } 
  }

  handleStartDate = (event) => {
    this.setState({
        startDate: event.target.value
    }, async ()=>{
      //console.log(this.state.startDate)
    });
  }

  

  render() {
    console.log(this.state.leave?.employee?.name ?? "");
    return <div>
              <div id='headerPartLeaveEdit'>
                <Header id='HeaderLeaveEdit' as='h2' block={true} value={this.state.leave}>
                  <Image size='large' circular src={inst_icon} /> Leaves
                </Header>
              </div>
              <Divider horizontal/>
              
              <div id='addFormLeaveEdit1'>
                <Segment id="addLeaveEdit1" inverted>
                  <Form id='formLeaveEdit1' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormLeaveEdit'>
                        <label className="labelLeaveEdit">Name</label>
                        <input className ='input' defaultValue={this.state.leave?.employee?.name ?? ""} readOnly/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthLeaveEdit'>
                        <label className="labelLeaveEdit">Title</label>
                        <input className ='input' defaultValue={this.state.leave?.employee?.title ?? ""} readOnly/>
                      </Form.Field> 
                      <Form.Field className='formHalfWidthLeaveEdit'>
                        <label className="labelLeaveEdit">Yearly Total Balance</label>
                        <input className ='input' defaultValue={this.state.leave?.balance ?? ""} readOnly/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthLeaveEdit'>
                        <label className="labelLeaveEdit">Taken</label>
                        <input className ='input' defaultValue={this.state.leave?.taken ?? ""} readOnly/>
                      </Form.Field> 
                      <Form.Field className='formHalfWidthLeaveEdit'>
                        <label className="labelLeaveEdit">Remaining Balance</label>
                        <input className ='input' defaultValue={this.state.leave?.remaining ?? ""} readOnly/>
                      </Form.Field>
                    </Form.Group>
                  </Form>
                </Segment>
              </div>

              <div >
                <Button id='leave_button1' size='mini' color='red' onClick={this.showAddForm}> Add Leave </Button>
              </div>
              <Divider horizontal/>

              {/*Start of Adding Leave Form*/}
              <div id='addFormLeaveEdit'>
                <Segment id="addLeaveEdit" inverted>
                  <Form id='formLeaveEdit' inverted>
                    <Form.Group>
                      <Form.Field className='formHalfWidthAdminSchedule'>
                        <label className="labelAdminSchedule">Leave Date</label>
                        <DateTimePickerComponent
                          CssClass="customClass"
                          id='StartTime'
                          className ="e-field"
                          format='dd/MM/yy hh:mm a'
                          value={this.state.startDate}
                          onChange={this.handleStartDate}
                        />
                      </Form.Field>
                      <Form.Field className='formHalfWidthAdminSchedule'>
                        <label className="labelAdminSchedule">Leave Date</label>
                        <DateTimePickerComponent
                          CssClass="customClass"
                          id='StartTime'
                          className ="e-field"
                          format='dd/MM/yy hh:mm a'
                          value={this.state.endDate}
                          onChange={this.handleEndDate}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitAdminScheduleNewClass' floated='right' type='submit' onClick={this.addLeaves}>Submit</Button>
                  </Form>
                </Segment>
              </div>
              {/*End of Adding Class Form*/}

              <div id="tableLeaveEdit">
                  <BasicTable key ={this.state.leaveInstances}  data = {this.state.leaveInstances} removeLeaves={this.removeLeaves}/>
              </div>
              <Divider horizontal/>
           </div>
  }
  
}


export default Leave;
