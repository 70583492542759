import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import level_icon from '../../media/Images/level.png';
import '../../stylesheets/levelEdit.css'
import $ from 'jquery';
import track from '../track';

class LevelEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      levelName: '',
      levelRank:0,
      levelId:0
    }
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  componentDidMount(){
    this.setState({levelId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/levels/${this.state.levelId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              levelName: result.level.text,
              levelRank:result.level.rank
             })
            return;
          },
          error: (error) => {
            alert('Unable to load roam to edit. Please try your request again')
            return;
          }
        })
      }  
      secondFunction();
    })
  }

  
  levelDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/levels/${this.state.levelId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          name: this.state.levelName,
          rank: this.state.levelRank
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`deleted the level named ${this.state.levelName} `,
                    'Level', 'Delete')        
          
          //this.navTo('/levels');
          this.props.history.push('/levels');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/levels/${this.state.levelId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              name: this.state.levelName,
              rank:this.state.levelRank
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              //this.navTo('/levels');
              track(`deleted the level named ${this.state.levelName} `,
                    'Level', 'Delete')        
          
              this.props.history.push('/levels');
              return;
            },
            error: (error) => {
              alert('Unable to delete the dance level. Please try your request again')
              return;
            }
          })
          return;
        }
      })
    }
    secondFunction();
  }

  
  levelEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/levels/${this.state.levelId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          name: this.state.levelName,
          rank: this.state.levelRank
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`updated the level named ${this.state.levelName} `,
                    'Level', 'Update')        
          
          //this.navTo('/levels');
          this.props.history.push('/levels');
          return;
        },
        error: (error) => {
          alert('Unable to update the dance level. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  cancelId = ()=>{
    this.props.history.push('/levels');
    //this.navTo(`/levels`)
  }

  handleLevelName = (event) => {
    this.setState({levelName: event.target.value})
  }

  handleLevelRank = (event) => {
    this.setState({levelRank: event.target.value})
  }

  render() {

    return <div>
                <div id='headerPartLevelEdit'>
                  <Header id='HeaderLevelEdit' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={level_icon} /> Dance Levels
                  </Header>
                </div>
                <Divider horizontal/>

                <div id='addFormLevelEdit'>
                  <Segment id="addLevel" inverted>
                    <Form id='formLevel' inverted>
                      <Form.Group>
                        <Form.Field className='formHalfWidthLevel'>
                          <label className="labelLevel">Level Name</label>
                          <input className ='input' defaultValue={this.state.levelName} onChange={this.handleLevelName}/>
                        </Form.Field>
                        <Form.Field className='formHalfWidthLevel'>
                          <label className="labelLevel">Level Rank</label>
                          <input className ='input' type='number' value={this.state.levelRank} onChange={this.handleLevelRank}/>
                        </Form.Field>
                      </Form.Group>
                      <div id = 'submitLevelEditContainer'>
                        <Button id='submitLevelEdit1' content='Primary' floated='right' type='submit' onClick={this.levelEditId}>Update</Button>
                        <Button id='submitLevelEdit2' content='Secondary' floated='right' type='submit' onClick={this.levelDeleteId}>Delete</Button>
                        <Button id='submitLevelEdit3' content='Primary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                      </div>
                    </Form>
                  </Segment>
                </div>
           </div>
  }
  
}


export default LevelEdit;
