import React from 'react'
//import { Dropdown, Icon, Input, Menu } from 'semantic-ui-react'
import '../../stylesheets/Header.css';
//import {useAuth0} from '@auth0/auth0-react';
import {AdminMenu} from './AdminMenu' 
import { useJwt } from "react-jwt";


export const AdminMenuAuth = (props) =>{
    let { decodedToken, isExpired } = useJwt(props.token);
    
    let isAuthenticatedAdmin = false;

    if (typeof decodedToken !== 'undefined' && decodedToken !== null && isExpired === false){
        if(decodedToken.is_partner === false && decodedToken.is_admin === true && decodedToken.is_instructor === false && decodedToken.is_student === false){
            isAuthenticatedAdmin = true;
            sessionStorage.setItem("user_id",decodedToken.user_id);
        }
        else{
            isAuthenticatedAdmin = false
        }
    }else{
        isAuthenticatedAdmin = false
    }

    return (isAuthenticatedAdmin ? <AdminMenu user_id={decodedToken.user_id} /> :<></>);
}