import React from 'react'
import { Card, Icon, Image } from 'semantic-ui-react'
import '../stylesheets/card.css';

const CardTemplate = (props) => (
<Card id='cardSize' as='div'>
    <Image as='img' id='profile-pic' src={props.img} wrapped ui={false} />
    <Card.Content>
      <h2>{props.name}</h2>
      <Card.Meta>
        <span className='date'>{props.role}</span>
      </Card.Meta>
      <Card.Description>
        <Icon name='mail' />
        Email Addresss: {props.email}
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
      <>
        <Icon name='phone' />
        Phone Number: {props.phoneNumber}
      </>
    </Card.Content>
  </Card>

)

export default CardTemplate;