import React from 'react'
//import { Dropdown, Icon, Input, Menu } from 'semantic-ui-react'
import '../../stylesheets/Header.css';
//import {useAuth0} from '@auth0/auth0-react';
import {PublicMenu} from './PublicMenu' 
import { useJwt } from "react-jwt";


export const PublicMenuAuth = (props) =>{
    let { decodedToken, isExpired } = useJwt(props.token);
    
    let isAuthenticatedUser = false;

    if (typeof decodedToken !== 'undefined' && decodedToken !== null && isExpired === false){
        isAuthenticatedUser = true;
    }else{
        isAuthenticatedUser = false;
    }
  
    return (isAuthenticatedUser ? <></> :<PublicMenu/>);
}