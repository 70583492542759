import React, { Component } from 'react';
import { Divider, Image, Button} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../../media/Images/instructor_icon.png';
import '../../../stylesheets/classInfoInstructor.css'
//import $ from 'jquery';


class InstructorsClass extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      
    }
  }

  componentDidMount(){
    
  }
  navTo = (uri) =>{
    this.props.history.push(uri);
  }

  render() {
    
    return <div>
                <div id = 'headerPartClassInfoInstructor'>
                  <Divider horizontal>
                    <h4 id='HeaderClassInfoInstructor'>
                      <Image id='iconClassInfoInstructor' size='large' src={inst_icon} />
                      Instructors
                    </h4>
                  </Divider>
                </div>
                <Divider horizontal/>

                <div id='tableClassInfoInstructor'>
                    <BasicTable key ={this.props.instructors}  data = {this.props.instructors} classId={this.props.classId} history={this.props.history} refreshToken={this.props.refreshToken} reloadClass={this.props.reloadClass}/>
                    <Divider horizontal/>
                    <Button id='inst_add_button1' size='mini' color='red' onClick={() => {this.navTo(`/classes/${this.props.classId}/instructors`)}}> Add Instructor </Button>
                </div>
                <Divider horizontal/>

           </div>
  }
  
}


export default InstructorsClass;
