import React, { Component } from 'react';
import { Header, Image, Button, Dropdown, Form, Segment, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import ExistingInstructor from './Instructor/ExistingInstructor';
import NewInstructor from './Instructor/NewInstructor';
import dance_icon from '../../media/Images/category.png';
import '../../stylesheets/dance.css'
import $ from 'jquery';
import track from '../track';


class Dance extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      dance_category_id: 1,
      dance_categories:[],
      dances: [],
      dances_options:[],
      danceName: '',
      danceCatId:0,
      newExistingInstructor:'existing',
      instructorId:0,
      
    }
    this.newInstructor = React.createRef();
  }

  componentDidMount(){
    document.getElementById("existing_inst_comp").hidden=false;
    document.getElementById("new_inst_comp").hidden=true;
    document.getElementById("addDance").hidden=true;
    
    $.ajax({
      url: `/api/dances`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {   //Include the bearer token in header
        xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
      },
      success: (result) => {
        this.setState({ dances: result.dances })
        return;
      },
      error: (error) => {
        alert('Unable to load dance dances. Please try your request again')
        return;
      }
    })

    $.ajax({
      url: `/api/categories`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {   //Include the bearer token in header
        xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
      },
      success: (result) => {
        this.setState({ dance_categories: result.dance_categories })
        return;
      },
      error: (error) => {
        alert('Unable to load dance categories. Please try your request again')
        return;
      }
    })
  }

  handleChangeCategory1 = (event, {value}) => {
    this.setState({dance_category_id: value}, async ()=> {
      this.setState({dances_options:[]}, async () => {
        this.setState({
        dances_options:this.state.dances[this.state.dance_category_id]
      }, async () => {
        //console.log(this.state.dances_options)
        //console.log(this.state.dance_category_id)
      })})
    })
  }

  handleChangeCategory2 = (event, {value}) => {
    this.setState({danceCatId: value})
  }

  
  new_dance = ()=>{
    if (document.getElementById("addDance").hidden){
      document.getElementById("addDance").hidden=false;
      document.getElementById("addDance").scrollIntoView();
    }else{
      document.getElementById("addDance").hidden=true;
      document.getElementById("tableDance").scrollIntoView();
    } 
  }

  addDance = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/dances`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: this.state.danceName,
          dance_cat_id: this.state.danceCatId
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Added new dance named ${this.state.danceName}`,
                    'Dance', 'Add')
          
          if(this.state.newExistingInstructor === 'new'){
            this.newInstructor.current.addInstructor(result.dance.key);
            document.getElementById("tableDance").scrollIntoView();
          }else{
            ////existing
            //console.log("we are in adding inst")
            //console.log("result :", result)
            $.ajax({
              url: `/api/dances/${result.dance.key}/instructors/${this.state.instructorId}`, //TODO: update request URL
              type: "POST",
              beforeSend: function (xhr) {   //Include the bearer token in header
                xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
              },
              dataType: 'json',
              contentType: 'application/json',
              data: JSON.stringify({
              }),
              xhrFields: {
                withCredentials: true
              },
              crossDomain: true,
              success: (result) => {
                track(`Assigned instructor ${this.state.instructorId} to ${this.state.danceName} dance`,
                    'Dance.instructors', 'Add')
                
                document.getElementById("tableDance").scrollIntoView();
                return;
              },
              error: (error) =>{
                return;
              }     
            })
          }
  
          $.ajax({
            url: `/api/dances`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              this.setState({dances: result.dances}, async ()=>{
                this.setState({dances_options:this.state.dances[this.state.dance_category_id]},async ()=> {document.getElementById("addDance").hidden=true;})
              })
              return;
            },
            error: (error) => {
              alert('Unable to load roams. Please try your request again')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the dance. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleDanceName = (event) => {
    this.setState({danceName: event.target.value})
  }

  existingInstructorClicked = ()=>{
    this.setState({newExistingInstructor:"existing"}, async ()=>{
      document.getElementById("existing_inst_comp").hidden=false;
      document.getElementById("new_inst_comp").hidden=true;
    })
  }

  newInstructorClicked = ()=>{
    this.setState({newExistingInstructor:"new"}, async ()=>{
      document.getElementById("existing_inst_comp").hidden=true;
      document.getElementById("new_inst_comp").hidden=false;
    })
  }

  

  render() {
    //console.log('existing instr id = ',this.state.instructorId)
    
    return <div>
                <div id='headerPartDance'>
                  <Header id='HeaderDance' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={dance_icon} /> Dances
                  </Header>
                </div>
                <Divider horizontal/>

                <div >
                  <Button id='dance_button1' size='mini' color='red' onClick={this.new_dance}> Add a Dance </Button>
                </div>
                <Divider horizontal/>

                <div id='chooseCat'>
                  <label className="labelDance">Dance Category</label>
                  <Dropdown
                    placeholder='Select the dance category'
                    selection
                    options={this.state.dance_categories}
                    onChange={this.handleChangeCategory1}
                  />
                </div>

                <Divider horizontal/>

                <div id = 'tableDance'>
                    <BasicTable key ={this.state.dances_options}  data = {this.state.dances_options} />
                </div>
                <Divider horizontal/>



              <div id='addFormDance'>
                <Segment id="addDance" inverted>
                  <Form id='formDance' inverted>
                    <Form.Group>
                      <Form.Field className='formHalfWidthDance'>
                        <label className="labelDance">Dance Category</label>
                        <Dropdown
                          placeholder='Select the category'
                          selection
                          options={this.state.dance_categories}
                          onChange={this.handleChangeCategory2}
                        />
                      </Form.Field>
                      <Form.Field className='formHalfWidthDance'>
                        <label className="labelDance">Dance Name</label>
                        <input className ='input' placeholder='Dance Name' onChange={this.handleDanceName}/>
                      </Form.Field>
                    </Form.Group>
                    <Divider horizontal/>
                    <Form.Group>
                      <div id='existingVsNewInstContainer'>
                        <Button id='existingInstButton' size='mini' color='red' onClick={this.existingInstructorClicked}> 
                          Existing Instructor
                        </Button>
                        <Button id='newInstButton' size='mini' color='red' onClick={this.newInstructorClicked}> 
                          New Instructor
                        </Button>
                      </div>
                    </Form.Group>
                    <Divider horizontal/>
                    <Form.Group>
                      <div className='fullWidthFormDance' id='existing_inst_comp'>
                        <ExistingInstructor setInstructor={(id)=>this.setState({instructorId: id})} refreshToken={this.props.refreshToken}/>
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className='fullWidthFormDance' id='new_inst_comp'>
                        <NewInstructor ref={this.newInstructor} setInstructor={(id)=>this.setState({instructorId: id})} refreshToken={this.props.refreshToken}/>
                      </div>
                    </Form.Group>
                    <Button id='submitDance' floated='right' type='submit' onClick={this.addDance}>Submit</Button>
                  </Form>
                </Segment>
              </div>
           </div>
  }
  
}


export default Dance;
