import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Dropdown, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import package_icon from '../../media/Images/package.png';
import '../../stylesheets/package.css'
import $ from 'jquery';
import track from '../track';

class Package extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      categories: [],
      packages: [],
      packageName: '',
      amount: 0,
      durationWeeks: 0,
      numberClasses: 0,
      danceCatId:0,
      types : [{
                  "key": 1,
                  "text": "Group Classes",
                  "value": 1
              },
              {
                  "key": 2,
                  "text": "Private Classes",
                  "value": 2
              },
              {
                  "key": 3,
                  "text": "Workshops",
                  "value": 3
              }],
      isPrivate:false,
      isWorkshop:false
    }
  }

  componentDidMount(){
    document.getElementById("addPackage").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/categories`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ categories: result.dance_categories })
          return;
        },
        error: (error) => {
          alert('Unable to load dance categories. Please try your request again')
          return;
        }
      })
      
      $.ajax({
        url: `/api/packages`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ packages: result.packages })
          return;
        },
        error: (error) => {
          alert('Unable to load dance packages. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  new_package = ()=>{
    if (document.getElementById("addPackage").hidden){
      document.getElementById("addPackage").hidden=false;
      document.getElementById("addPackage").scrollIntoView();
    }else{
      document.getElementById("addPackage").hidden=true;
      document.getElementById("tablePackage").scrollIntoView();
    } 
  }

  addPackage = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/packages`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: this.state.packageName,
          amount: this.state.amount,
          duration_weeks: this.state.durationWeeks,
          number_classes: this.state.numberClasses,
          dance_cat_id: this.state.danceCatId,
          is_private: this.state.isPrivate,
          is_workshop: this.state.isWorkshop
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Added new Package named ${this.state.packageName}`,
                    'Package', 'Add')
          $.ajax({
            url: `/api/packages`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              this.setState({ packages: result.packages },async ()=> {
                document.getElementById("addPackage").hidden=true;
                document.getElementById("tablePackage").scrollIntoView();
              })
              return;
            },
            error: (error) => {
              alert('Unable to load roams. Please try your request again')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the package. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handlePackageName = (event) => {
    this.setState({packageName: event.target.value})
  }
  
  handleAmount = (event) => {
    this.setState({amount: event.target.value})
  }

  handleChangeCategory = (event, {value}) => {
    this.setState({danceCatId: value}, async ()=> {
      //console.log(this.state.danceCatId);
    })
  }

  handleChangeTypes = (event, {value}) => {
    // eslint-disable-next-line
    if(value == 1){
      this.setState({isPrivate: false , isWorkshop: false})
    }// eslint-disable-next-line
    else if (value == 2){
      this.setState({isPrivate: true , isWorkshop: false})
    }// eslint-disable-next-line
    else if (value == 3){
      this.setState({isPrivate: false , isWorkshop: true})
    }
  }
  
  handleValidity = (event) => {
    this.setState({durationWeeks: event.target.value})
  }

  handleNumberClasses = (event) => {
    this.setState({numberClasses: event.target.value})
  }


  render() {
    
    return <div>
              <div id='headerPartPackage'>
                <Header id='HeaderPackage' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={package_icon} /> Dance Packages
                </Header>
              </div>
              <Divider horizontal/>

              <div >
                <Button id='package_button1' size='mini' color='red' onClick={this.new_package}> Add a Package </Button>
              </div>
              <Divider horizontal/>

              <div id = 'tablePackage'>
                  <BasicTable key ={this.state.packages}  data = {this.state.packages} />
              </div>
              <Divider horizontal/>

              <div id='addFormPackage'>
                <Segment id="addPackage" inverted>
                  <Form id='formPackage' inverted>
                    <Form.Group>
                    <Form.Field className='formHalfWidthRoom'>
                        <label className="labelPackage">Dance Category</label>
                        <Dropdown
                          placeholder='Select the dance category'
                          selection
                          options={this.state.categories}
                          onChange={this.handleChangeCategory}
                        />
                      </Form.Field>

                      <Form.Field className='formHalfWidthRoom'>
                        <label className="labelPackage">Package Type</label>
                        <Dropdown
                          placeholder='Select the package type'
                          selection
                          options={this.state.types}
                          onChange={this.handleChangeTypes}
                        />
                      </Form.Field>
                      
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthPackage'>
                        <label className="labelPackage">Package Name</label>
                        <input className ='input' placeholder='Package Name' onChange={this.handlePackageName}/>
                      </Form.Field>
                    <Form.Field className='formHalfWidthPackage'>
                        <label className="labelPackage">Package Price</label>
                        <input className ='input' type='number' placeholder='Package Price' onChange={this.handleAmount}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthPackage'>
                        <label className="labelPackage">Validity (weeks)</label>
                        <input className ='input' type='number' placeholder='Validity (weeks)' onChange={this.handleValidity}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthPackage'>
                        <label className="labelPackage">Number of Classes</label>
                        <input className ='input' type='number' placeholder='Number of Classes' onChange={this.handleNumberClasses}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitPackage' floated='right' type='submit' onClick={this.addPackage}>Submit</Button>
                  </Form>
                </Segment>
              </div>
              <div style={{margin:"40px"}}></div>
              <div style={{margin:"40px"}}></div>
           </div>
  }
  
}


export default Package;
