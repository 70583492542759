import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import room_icon from '../../media/Images/room.png';
import '../../stylesheets/roomEdit.css'
import $ from 'jquery';
import track from '../track';

class RoomEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      roomName: '',
      roomCapacity:0,
      rentValue:0,
      roomId:0
    }
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  componentDidMount(){
    this.setState({roomId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/rooms/${this.state.roomId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              roomName: result.room.text,
              roomCapacity: result.room.capacity,
              rentValue:result.room.rent_value
             })
            return;
          },
          error: (error) => {
            alert('Unable to load roam to edit. Please try your request again')
            return;
          }
        })
      }  
      secondFunction();
    })
  }

  
  roomDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/rooms/${this.state.roomId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          name: this.state.roomName,
          capacity: this.state.roomCapacity,
          rent_value: this.state.rentValue
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`deleted the room named ${this.state.roomName} `,
                    'Room', 'Delete')        
          
          //this.navTo('/rooms');
          this.props.history.push('/rooms');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/rooms/${this.state.roomId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              name: this.state.roomName,
              capacity: this.state.roomCapacity,
              rent_value: this.state.rentValue
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              track(`deleted the room named ${this.state.roomName} `,
                    'Room', 'Delete')        
          
              //this.navTo('/rooms');
              this.props.history.push('/rooms');
              return;
            },
            error: (error) => {
              alert('Unable to delete the room. Please try your request again')
              return;
            }
          })
          return;
        }
      })
    }
    secondFunction();
  }

  
  roomEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/rooms/${this.state.roomId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          name: this.state.roomName,
          capacity: this.state.roomCapacity,
          rent_value: this.state.rentValue
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Updated the room named ${this.state.roomName} `,
                    'Room', 'Update')        
          
          //this.navTo('/rooms');
          this.props.history.push('/rooms');
          return;
        },
        error: (error) => {
          alert('Unable to update the room. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  cancelId = ()=>{
    this.props.history.push('/rooms');
    //this.navTo(`/rooms`)
  }

  handleRoomName = (event) => {
    this.setState({roomName: event.target.value})
  }
  
  handleRoomCapacity = (event) => {
    this.setState({roomCapacity: event.target.value})
  }

  handleRentValue = (event) => {
    this.setState({rentValue: event.target.value})
  }

  render() {
    //console.log(this.props.match.params.id)
    //console.log(this.state.roomName)
    //console.log(this.state.roomCapacity)

    return <div>
              <div id='headerPartRoomEdit'>
                <Header id='HeaderRoomEdit' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={room_icon} /> Rooms
                </Header>
              </div>
              <Divider horizontal/>

              <div id='addFormRoomEdit'>
                <Segment id="addRoomEdit" inverted>
                  <Form id='formRoomEdit' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormRoomEdit'>
                        <label className="labelRoomEdit">Room Name</label>
                        <input className ='input' defaultValue={this.state.roomName} onChange={this.handleRoomName}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthRoomEdit'>
                        <label className="labelRoomEdit">Capacity</label>
                        <input className ='input' type='number' value={this.state.roomCapacity} onChange={this.handleRoomCapacity}/>
                      </Form.Field> 
                      <Form.Field className='formHalfWidthRoomEdit'>
                        <label className="labelRoomEdit">Rent Value</label>
                        <input className ='input' type='number' value={this.state.rentValue} onChange={this.handleRentValue}/>
                      </Form.Field>
                    </Form.Group>
                    <Divider horizontal/>
                    <div id = 'submitRoomEditContainer'>
                      <Button id='submitRoomEdit1' content='Primary' floated='right' type='submit' onClick={this.roomEditId}>Update</Button>
                      <Button id='submitRoomEdit2' content='Primary' floated='right' type='submit' onClick={this.roomDeleteId}>Delete</Button>
                      <Button id='submitRoomEdit3' content='Primary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                    </div>
                  </Form>
                </Segment>
              </div>
           </div>
  }
  
}


export default RoomEdit;
