import React, { Component } from 'react';
import { Header, Image, Button, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../../media/Images/instructor_icon.png';
import '../../../stylesheets/classInfoInstructorAdd.css'
import $ from 'jquery';
import track from '../../track';

class InstructorAdd extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      classId:0,
      instructors: [],
      instructorsToAdd:[]
    }
    this.setInstructorsToAdd = this.setInstructorsToAdd.bind(this)
  }

  setInstructorsToAdd(e){
    this.setState({instructorsToAdd: e})
  }

  componentDidMount(){
    this.setState({classId: this.props.match.params.id}, async ()=>{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/classes/${this.state.classId}/instructors`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ instructors: result.instructors })
            return;
          },
          error: (error) => {
            alert('Unable to load instructors. Please try your request again')
            return;
          }
        })
      }
      secondFunction();
    })
    
    
  }
  
  navTo = (uri) =>{
    this.props.history.push(uri);
  }

  addInstructors  = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/classes/${this.state.classId}/instructors`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type:'add',
          instructors: this.state.instructorsToAdd,
          instructorId:0
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`added instructors ${this.state.instructorsToAdd}to class of id = ${this.state.classId} `,
                    'Class.instructors', 'Add');
          
          this.navTo(`/classes/${this.state.classId}`)
          return;
        },
        error: (error) => {
          alert('Unable to add the dance. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
 


  render() {
    //console.log('instructorsToAdd',this.state.instructorsToAdd)
    
    return <div>
                <div id='headerPartInstructorAdd'>
                  <Header id='HeaderInstructorAdd' as='h2' block={true} >
                    <Image size='large' circular src={inst_icon} /> Add Instructors
                  </Header>
                </div>
                <Divider horizontal/>
                
                <div id='tableInstructorAdd'>
                  <BasicTable key ={this.state.instructors}  data = {this.state.instructors} instructorsToAdd={this.state.instructorsToAdd} setInstructorsToAdd={this.setInstructorsToAdd}/>
                  <Divider horizontal/>
                  <Button id='instructor_add_button1' size='mini' color='red' onClick={this.addInstructors}> Add Instructors </Button>
                </div>
                <Divider horizontal/>
           </div>
  }
  
}


export default InstructorAdd;
