import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import category_icon from '../../media/Images/category.png';
import '../../stylesheets/category.css'
import $ from 'jquery';
import track from '../track';

class Category extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      categories: [],
      categoryName: ''
    }
  }

  componentDidMount(){
    document.getElementById("addCategory").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/categories`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ categories: result.dance_categories })
          return;
        },
        error: (error) => {
          alert('Unable to load dance categories. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  new_category = ()=>{
    if (document.getElementById("addCategory").hidden){
      document.getElementById("addCategory").hidden=false;
      document.getElementById("addCategory").scrollIntoView();
    }else{
      document.getElementById("addCategory").hidden=true;
      document.getElementById("tableCategory").scrollIntoView();
    } 
  }

  addCategory = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/categories`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          name: this.state.categoryName,
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          $.ajax({
            url: `/api/categories`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              track(`Added new dance category named ${this.state.categoryName}`,
                    'Dance_Category', 'Add')
          
              this.setState({ categories: result.dance_categories },async ()=> {
                document.getElementById("addCategory").hidden=true;
                document.getElementById("tableCategory").scrollIntoView();
              })
              return;
            },
            error: (error) => {
              alert('Unable to load roams. Please try your request again')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the category. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleCategoryName = (event) => {
    this.setState({categoryName: event.target.value})
  }
  

  render() {
    
    return <div>
              <div id='headerPartCategory'>
                <Header id='HeaderCategory' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={category_icon} /> Categories
                </Header>
              </div>
              <Divider horizontal/>

              <div >
                <Button id='category_button1' size='mini' color='red' onClick={this.new_category}> Add a Category </Button>
              </div>
              <Divider horizontal/>

              <div id = 'tableCategory'>
                  <BasicTable key ={this.state.categories}  data = {this.state.categories} />
              </div>
              <Divider horizontal/>

              <div id='addFormCategory'>
                <Segment id="addCategory" inverted>
                  <Form id='formCategory' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormCategory'>
                        <label className="labelCategory">Category Name</label>
                        <input className ='input' placeholder='Category Name' onChange={this.handleCategoryName}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitCategory' floated='right' type='submit' onClick={this.addCategory}>Submit</Button>
                  </Form>
                </Segment>
              </div>
           </div>
  }
}


export default Category;
