import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import student_icon from '../../media/Images/student.png';
import '../../stylesheets/student.css'
import $ from 'jquery';
import track from '../track';

class Student extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      students: [],
      UserName: '',
      FirstName: '',
      LastName: '',
      Password: process.env.REACT_APP_Default_Password, //'Soulmotion@123',
      PasswordRe: process.env.REACT_APP_Default_Password,
      ProfilePicture: null,
      Email: '',
      Address: '',
      Phone: ''
    }
  }

  componentDidMount(){
    document.getElementById("addStudent").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/students`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ students: result.students })
          return;
        },
        error: (error) => {
          alert('Unable to load students. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  new_student = ()=>{
    if (document.getElementById("addStudent").hidden){
      document.getElementById("addStudent").hidden=false;
      document.getElementById("addStudent").scrollIntoView();
    }else{
      document.getElementById("addStudent").hidden=true;
      document.getElementById("tableStudent").scrollIntoView();
    } 
  }

  addStudent = (event) =>{
    event.preventDefault();
    let x = 0;
    if(this.state.FirstName !== ''){x = x+1;}
    if(this.state.LastName !== ''){x = x+1;}
    // if(this.state.UserName === '' ){
    //   alert("Please enter a unique Username to be able to sign up")
    // } else 
    if(x < 1){
      alert("Please enter your name to be able to sign up")
    }
    else if(this.state.Email === '' ){
      alert("Please enter your E-mail to be able to sign up")
    }
    else if(this.state.Phone === '' ){
      alert("Please enter your phone number to be able to sign up")
    } 
    //else if(this.state.Password === '' || this.state.PasswordRe === '' || this.state.Password !== this.state.PasswordRe ){
    //  alert("Please check your password to be able to sign up")
    //} 
    else{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/students`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            username: this.state.UserName,
            first_name: this.state.FirstName,
            last_name: this.state.LastName,
            password: this.state.Password,
            re_password: this.state.PasswordRe,
            address: this.state.Address,
            email: this.state.Email,
            phone: this.state.Phone
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            if (this.state.ProfilePicture !== null){
              var data1 = new FormData();
              data1.append("file", this.state.ProfilePicture)
              data1.append("filename", this.state.UserName)
              
              $.ajax({
                url: `/uploads/${result.student.id}/students`, //TODO: update request URL
                type: "POST",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                enctype: 'multipart/form-data',
                data: data1,
                processData: false,
                contentType: false,
                cache: false,
                timeout: 800000,
              
                xhrFields: {
                  withCredentials: true
                },
                crossDomain: true,
                success: (result) => {
                  track(`Added new student, username = ${this.state.UserName} with picture`,
                    'Student', 'Add')
                  
                  document.getElementById("addStudent").hidden=true;
                  $.ajax({
                    url: `/api/students`, //TODO: update request URL
                    type: "GET",
                    beforeSend: function (xhr) {   //Include the bearer token in header
                      xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                    },
                    success: (result) => {
                      this.setState({ students: result.students },async ()=> {
                        document.getElementById("addStudent").hidden=true;
                        document.getElementById("tableStudent").scrollIntoView();
                      })
                      return;
                    },
                    error: (error) => {
                      alert('Unable to load students. Please try your request again')
                      return;
                    }
                  })
                  return;
                },
                error: (error) => {
                  alert('Unable to add the profile picture. Please try your request again')
                  return;
                }
              })
            } else{
              track(`Added new student, username = ${this.state.UserName} without picture`,
                    'Student', 'Add')
                  
              document.getElementById("addStudent").hidden=true;
              $.ajax({
                url: `/api/students`, //TODO: update request URL
                type: "GET",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                success: (result) => {
                  this.setState({ students: result.students },async ()=> {
                    document.getElementById("addStudent").hidden=true;
                    document.getElementById("tableStudent").scrollIntoView();
                  })
                  return;
                },
                error: (error) => {
                  alert('Unable to load students. Please try your request again')
                  return;
                }
              })
              return;
            }
            return;
          },
          error: (error) => {
            alert(`Unable to add student - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
          }
        })
      }  
      secondFunction();
    }
  }

  handleUserName = (event) =>{
    this.setState({UserName:event.target.value})
  }

  handleFirstName = (event) =>{
    this.setState({FirstName:event.target.value}, async ()=>{
      this.setState({username: this.state.FirstName.toLowerCase().trim()+"."+this.state.LastName.toLowerCase().trim()})
    })
  }

  handleLastName = (event) =>{
    this.setState({LastName:event.target.value}, async ()=>{
      this.setState({username: this.state.FirstName.toLowerCase().trim()+"."+this.state.LastName.toLowerCase().trim()})
    })
  }

  handleAddress = (event) =>{
    this.setState({Address:event.target.value})
  }

  handleEmail = (event) =>{
    this.setState({Email:event.target.value})
  }

  handlePhone = (event) =>{
    this.setState({Phone:event.target.value})
  }

  /*handlePassword = (event) =>{
    this.setState({Password:event.target.value})
  }

  handlePasswordRe = (event) =>{
    this.setState({PasswordRe:event.target.value})
  }*/

  handleProfilePicture = (event)=>{
    this.setState({ProfilePicture: event.target.files[0]})
  }

  
  render() {
    //console.log("inside render")
    //console.log("window.location.hash = ", window.location.hash)
    //console.log('Password = ', this.state.Password)
    return <div>
              <div id='headerPartStudent'>
                <Header id='HeaderStudent' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={student_icon} /> Students
                </Header>
              </div>
              <Divider horizontal/>

              <div >
                <Button id='student_button1' size='mini' color='red' onClick={this.new_student}> Add Student </Button>
              </div>
              <Divider horizontal/>

              <div id = 'tableStudent'>
                <BasicTable key ={this.state.students}  data = {this.state.students} />
              </div>
              <Divider horizontal/>


              <div id='addFormStudent'>
                <Segment id="addStudent" inverted>
                  <Form id='formStudent' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormStudent'>
                        <label className="labelStudent">User Name</label>
                        {/* <input className='input' placeholder='User Name' onChange={this.handleUserName}/> */}
                        <input className='input' readOnly value={this.state.FirstName.toLowerCase().trim()+"."+this.state.LastName.toLowerCase().trim()}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthStudent'>
                        <label className="labelStudent">First Name</label>
                        <input className='input' placeholder='First Name' onChange={this.handleFirstName}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthStudent'>
                        <label className="labelStudent">Last Name</label>
                        <input className='input' placeholder='Last Name' onChange={this.handleLastName}/>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field className='fullWidthFormStudent'>
                        <label className="labelStudent">Profile Picture</label>
                        <input className='input' type='file' placeholder="Choose Instructor's Profile Picture" accept="image/*" onChange={this.handleProfilePicture}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='fullWidthFormStudent'>
                        <label className="labelStudent">Address</label>
                        <input className='input' placeholder='Address' onChange={this.handleAddress}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthStudent'>
                        <label className="labelStudent">E-Mail</label>
                        <input className='input' placeholder='E-Mail' onChange={this.handleEmail}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthStudent'>
                        <label className="labelStudent">Phone</label>
                        <input className='input' placeholder='Phone' onChange={this.handlePhone}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitStudent' floated='right' type='submit' onClick={this.addStudent}>Submit</Button>
                  </Form>
                </Segment>
              </div>
           </div>
  }
  
}


export default Student;
