import React, { Component } from 'react';
import { Header, Image, Button, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../../media/Images/student.png';
import '../../../stylesheets/classInfoStudentAdd.css';
import $ from 'jquery';
import track from '../../track';


class StudentAdd extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      classId:0,
      students: [],
      studentsToAdd:[]
    }
    this.setStudentsToAdd = this.setStudentsToAdd.bind(this)
  }

  setStudentsToAdd(e){
    this.setState({studentsToAdd: e})
  }

  componentDidMount(){
    this.setState({classId: this.props.match.params.id}, async ()=>{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/classes/${this.state.classId}/students`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ students: result.students })
            return;
          },
          error: (error) => {
            alert('Unable to load students. Please try your request again')
            return;
          }
        })
      }  
      secondFunction();
    })
    
    
  }
  
  navTo = (uri) =>{
    this.props.history.push(uri);
  }

  addStudents  = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/classes/${this.state.classId}/students`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          type:'add',
          students: this.state.studentsToAdd,
          studentId:0
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`added students ${this.state.studentsToAdd}to class of id = ${this.state.classId} `,
                    'Class.students', 'Add');
          
          
          this.navTo(`/classes/${this.state.classId}`)
          return;
        },
        error: (error) => {
          alert('Unable to add the dance. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
 


  render() {
    
    return <div>
                <div id='headerPartStudentAdd'>
                  <Header id='HeaderStudentAdd' as='h2' block={true} >
                    <Image size='large' circular src={inst_icon} /> Add Students
                  </Header>
                </div>
                <Divider horizontal/>

                <div id='tableStudentAdd'>
                    <BasicTable key ={this.state.students}  data = {this.state.students} studentsToAdd={this.state.studentsToAdd} setStudentsToAdd={this.setStudentsToAdd}/>
                    <Divider horizontal/>
                    <Button id='student_add_button1' size='mini' color='red' onClick={this.addStudents}> Add Students </Button>
                </div>
                <Divider horizontal/>
           </div>
  }
  
}


export default StudentAdd;
