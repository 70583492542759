import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import misc_icon from '../../media/Images/misc.png';
import '../../stylesheets/miscEdit.css'
import $ from 'jquery';
import track from '../track';
//import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
//import {format} from 'date-fns';

class MiscEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      miscDescription: '',
      amount: 0,
      miscId:0
    }
  }

  componentDidMount(){
    this.setState({miscId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/miscs/${this.state.miscId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              miscDescription: result.misc.description,
              amount:result.misc.amount
              //date:result.misc.date
             })
            return;
          },
          error: (error) => {
            alert('Unable to load misc to edit. Please try your request again')
            return;
          }
        })
      }  
      secondFunction();
    })
  }

  
  miscDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/miscs/${this.state.miscId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          description: this.state.miscDescription,
          amount: this.state.amount
          //date: this.state.date
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`deleted the misc = ${this.state.miscDescription} `,
                    'Misc', 'Delete')        
          
          //this.navTo('/miscs');
          this.props.history.push('/miscs');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/miscs/${this.state.miscId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              description: this.state.miscDescription,
              amount: this.state.amount
            //  date: this.state.date
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              track(`deleted the misc = ${this.state.miscDescription} `,
                    'Misc', 'Delete')        
          
              //this.navTo('/miscs');
              this.props.history.push('/miscs');
              return;
            },
            error: (error) => {
              alert('Unable to delete the dance misc. Please try your request again')
              return;
            }
          })
          return;
        }
      })
    }
    secondFunction();
  }

  
  miscEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/miscs/${this.state.miscId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          description: this.state.miscDescription,
          amount: this.state.amount
          //date: this.state.date
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`updated the misc = ${this.state.miscDescription} `,
                    'Misc', 'Update')        
          
          //this.navTo('/miscs');
          this.props.history.push('/miscs');
          return;
        },
        error: (error) => {
          alert('Unable to update the dance misc. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleMiscDescription = (event) => {
    this.setState({miscDescription: event.target.value})
  }
  
  handleAmount = (event) => {
    this.setState({amount: event.target.value})
  }

  cancelId = ()=>{
    this.props.history.push('/miscs');
    //this.navTo(`/miscs`)
  }

  render() {

    return <div>
                <div id='headerPartMiscEdit'>
                  <Header id='HeaderMiscEdit' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={misc_icon} /> Misc
                  </Header>
                </div>
                <Divider horizontal/>

                <div id='addFormMiscEdit'>
                  <Segment id="addMisc" inverted>
                    <Form id='formMisc' inverted>
                    <Form.Group>
                      <Form.Field className='formHalfWidthMiscEdit'>
                        <label className="labelMisc">Description</label>
                        <input className ='input' defaultValue={this.state.miscDescription} onChange={this.handleMiscDescription}/>   
                      </Form.Field>

                      <Form.Field className='formHalfWidthMiscEdit'>
                        <label className="labelMisc">Price (LE)</label>
                        <input className ='input' type='number' value={this.state.amount} onChange={this.handleAmount}/>
                      </Form.Field>
                    </Form.Group>
                    <div>
                      <Button id='submitMiscEdit1' content='Primary' floated='right' type='submit' onClick={this.miscEditId}>Update</Button>
                      <Button id='submitMiscEdit2' content='Secondary' floated='right' type='submit' onClick={this.miscDeleteId}>Delete</Button>
                      <Button id='submitMiscEdit3' content='Primary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                    </div>
                    </Form>
                  </Segment>
                </div>
           </div>
  }
  
}


export default MiscEdit;
