//import {format} from 'date-fns'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

/*var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}*/
//onClick={()=>navTo(`/instructors/${value}`)} 

export const COLUMNS = [
    {
        width:30,
        Header:()=>'',
        accessor:'id',
        Cell:({value}) => (
            <Link to={`/partners/${value}`}><Button circular icon='edit' content='Edit'/></Link>
        ),
        disableFilters: true
    },
    {
        width:30,
        Header:()=>'',
        accessor:'user_id_2',
        Cell:({value}) => (
            <Link to={`/users/${value}/payslips`}><Button circular icon='edit' content='View Salary'/></Link>
        ),
        disableFilters: true
    },
    {
        maxWidth:10,
        Header: 'ID',
        accessor: 'user_id'

    },
    {
        Header: 'Name',
        accessor: 'full_name'
    },
    {
        Header: 'Address',
        accessor: 'address'
    },
    {
        Header: 'E-Mail',
        accessor: 'email'
    },
    {
        Header: 'Phone Number',
        accessor: 'phone'
    },
    {
        Header: 'Salary (EGP)',
        accessor: 'salary_month'
    }
]