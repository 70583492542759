import {format} from 'date-fns'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

/*var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}*/
//onClick={()=>navTo(`/miscs/${value}`)}

export const COLUMNS = [
    {
        Header:()=>'',
        accessor:'id',
        Cell:({value}) => (
            <Link to={`/kitchens/${value}`}><Button circular icon='edit'/></Link>
        ),
        disableFilters: true
    },
    {
        Header: 'Description',
        accessor: 'description'
    },
    {
        Header: 'Date',
        accessor: 'date',
        Cell:({value}) =>{return format(new Date(value), 'dd/MM/yyyy')}
    },
    {
        Header: 'Amount In (LE)',
        accessor: 'amount_in'
    },
    {
        Header: 'Amount Out (LE)',
        accessor: 'amount_out'
    }
]