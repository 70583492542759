import {format} from 'date-fns'
//import { Button } from 'semantic-ui-react'
//import { Link } from 'react-router-dom'

/*var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
}*/
//onClick={()=>navTo(`/rooms/${value}`)}

export const COLUMNS = [
    {
        Header: 'Day',
        accessor: 'attendance.start',
        Cell:({value}) =>{return format(new Date(value), "eeee")}
    },
    {
        Header: 'Date',
        accessor: 'attendance.start2',
        Cell:({value}) =>{return format(new Date(value), "dd/MM/yyyy")}
    },
    {
        Header: 'Time',
        accessor: 'attendance.start3',
        Cell:({value}) =>{return format(new Date(value), "hh:mm bb")}
    },
    {
        Header: 'Class',
        accessor: 'attendance.Class.dance.text'
    },
    {
        Header: 'Description',
        accessor: 'attendance.Class.description'
    },
    {
        Header: 'Duration',
        accessor: 'attendance.Class.duration_minutes'
    },
    {
        Header: 'Level',
        accessor: 'attendance.Class.level.text'
    }
]