import React from 'react'
import { Menu,Dropdown } from 'semantic-ui-react'
import Site_Logo from '../../media/Images/logo.PNG';
import verticalMenu from '../../media/Images/verticalMenu.png';

import '../../stylesheets/Header.css';

import { HomeOrMenu } from './HomeOrMenu';
import {AuthenticationButton} from './AuthenticationButton'
import {LogedInMenuAuth} from './LogedInMenuAuth'
import { Link } from 'react-router-dom';



export const Header = ({token, setToken, history, aboutUs, setAboutUs, setContact}) =>{
    //const [activeItem, setActiveItem] = useState('Home');

  //const handleItemClick = (e, { name }) => setActiveItem(name)

  /*const navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/
  
  //console.log(activeItem)
  return (
    <>
      <>
        <Menu id='menu_id' widths={3} size='mini' secondary={true} inverted ={true} style={{object_position:"center"}}>
          
          <HomeOrMenu setAboutUs={setAboutUs} setContact={setContact}/>
          <AuthenticationButton token ={token} setToken={setToken}/>
        </Menu>
      </>
      <>
      <LogedInMenuAuth token ={token}/>
      </>
    </>
    
    )
  
  }
  



