import {format} from 'date-fns'

import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export const COLUMNS = [
    {
        width:10,
        Header:()=>'',
        accessor:'subscription.student.id',
        Cell:({value}) => (
            <Link to={`/students/${value}`}><Button circular icon='expand'/></Link>
        ),
        disableFilters: true
    },
    {
        Header: 'Payment Date',
        accessor: 'payment_date',
        Cell:({value}) =>{return format(new Date(value), "eeee, dd/MM/yyyy hh:mm aaaaa'm'")}
    },
    {
        width:10,
        Header: 'Student ID',
        accessor: 'subscription.student.user_id',
    },
    {
        Header: 'Student Name',
        accessor: 'subscription.student.full_name',
    },
    {
        Header: 'Payment Amount (EGP)',
        accessor: 'value',
    },
    {
        Header: 'Method',
        accessor: 'method',
    },
    {
        Header: 'Remaining (EGP)',
        accessor: 'subscription.remaining'
    },
    {
        Header: 'Total Amount AD (EGP)',
        accessor: 'subscription.amount_after_discount'
    },
    {
        Header: 'Subscription ID',
        accessor: 'subscription.id',
    },
    {
        Header: 'Subscription Type',
        accessor: 'subscription.package_membership_name',
    },
    {
        Header: 'Package Type',
        accessor: 'subscription.package_type'
    },
    {
        Header: 'Category',
        accessor: 'subscription.dance_category.text',
    },
    {
        Header: 'Price BD (LE)',
        accessor: 'subscription.amount'
    },
    {
        Header: 'Discount',
        accessor: 'subscription.discount'
    },
    {
        Header: 'Start Date',
        accessor: 'subscription.start_date',
        Cell:({value}) =>{return format(new Date(value), 'eeee, dd/MM/yyyy')}
    },
    {
        Header: 'Expiry Date',
        accessor: 'subscription.expiry_date',
        Cell:({value}) =>{return format(new Date(value), 'dd/MM/yyyy')}
    },
    {
        Header: 'Validity Duration',
        accessor: 'subscription.duration'
    },
    {
        Header: 'Is Expired',
        accessor: 'subscription.is_expired'
    },
    {
        Header: 'Number of Classes',
        accessor: 'subscription.number_classes'
    },
    {
        Header: 'Number of Remaining Classes',
        accessor: 'subscription.number_classes_remaining'
    }
]