//import {format} from 'date-fns'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

/*var navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
    //this.props.history.push(uri);
}
<Button circular icon='edit' onClick={()=>navTo(`/students/${value}`)} />
            
*/

export const COLUMNS = [
    {
        Header:()=>'Edit Student',
        accessor:'id',
        Cell:({value}) => (
            <Link to={`/students/${value}`}><Button circular icon='edit' content='Edit'/></Link>
        ),
        disableFilters: true
    },
    {
        Header: 'ID',
        accessor: 'user_id'
    },
    {
        Header: 'Student Name',
        accessor: 'full_name'
    },
    {
        Header: 'Due Amount (EGP)',
        accessor:'due_amount' 
    }
]