import React, { Component } from 'react';
import { Header, Image, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../../../media/Images/instructor_icon.png';
import '../../../../stylesheets/classInfoInstPrivInstructor.css'
//import $ from 'jquery';


class InstructorsClass extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      
    }
  }

  componentDidMount(){
    
  }
  navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }

  render() {
    
    return <div>
                <div id = 'headerPartClassInfoInstPrivInstructor'>
                  <Divider horizontal>
                    <h4 id='HeaderClassInfoInstPrivInstructor'>
                      <Image id='iconClassInfoInstPrivInstructor' size='large' src={inst_icon} />
                      Instructors
                    </h4>
                  </Divider>
                </div>
                <Divider horizontal/>

                <div id='tableClassInfoInstPrivInstructor'>
                    <BasicTable key ={this.props.instructors}  data = {this.props.instructors} classId={this.props.classId}/>
                </div>
                <Divider horizontal/>
           </div>
  }
  
}


export default InstructorsClass;
