import React from 'react'
//import { Dropdown, Icon, Input, Menu } from 'semantic-ui-react'
import '../../stylesheets/Header.css';
//import {useAuth0} from '@auth0/auth0-react';
import {StudentMenu} from './StudentMenu' 
import { useJwt } from "react-jwt";


export const StudentMenuAuth = (props) =>{
    let { decodedToken, isExpired } = useJwt(props.token);
    
    let isAuthenticatedStudent = false;

    if (typeof decodedToken !== 'undefined' && decodedToken !== null && isExpired === false){
        if(decodedToken.is_partner === false && decodedToken.is_instructor === false & decodedToken.is_admin === false & decodedToken.is_student === true){
            isAuthenticatedStudent = true;
            sessionStorage.setItem("user_id",decodedToken.user_id);
        }
        else{
            isAuthenticatedStudent = false;
        }
    }else{
        isAuthenticatedStudent = false;
    }
  
    return (isAuthenticatedStudent ? <StudentMenu user_id={decodedToken.user_id} /> :<></>);
}