import React, { Component } from 'react';
import { Header, Divider, Icon} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import '../../../stylesheets/detailedSubscription.css'
import $ from 'jquery';

class DetailedSubscription extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      
    }
  }

  componentDidMount(){
    
  }
  
  
  render() {
    
    return <div id = 'tableDetailedSubscription'>
                <Divider horizontal>
                  <h4>
                    <Icon name='credit card' />
                    Subscription Usage
                  </h4>
                </Divider>
                <div>
                    <BasicTable key ={this.props.subscriptionsDetails}  data = {this.props.subscriptionsDetails} history={this.props.history}/>
                </div>


           </div>
  }
  
}


export default DetailedSubscription;
