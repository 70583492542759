import React, { Component } from 'react';
import { Header, Image, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../../media/Images/admin_icon.png';
import '../../../stylesheets/adminAP.css'
import $ from 'jquery';


class Admin extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      admins: [],
      
      UserName: '',
      FirstName: '',
      LastName: '',
      Password: '',
      PasswordRe: '',
      ProfilePicture: null,
      Email: '',
      Address: '',
      Phone: '',
      salaryHour:0,

      values:['']
    }
  }

  componentDidMount(){
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/admins`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ admins: result.admins })
          return;
        },
        error: (error) => {
          alert('Unable to load admins. Please try your request again')
          return;
        }
      })
    }
    secondFunction();  
  }
  
  


  render() {
    
    return <div>
                <div id='headerPartAdminAP'>
                  <Header id='HeaderAdminAP' as='h2' block={true}>
                      <Image size='large' circular src={inst_icon} /> Admins
                  </Header>
                </div>
                <Divider horizontal/>

                <div id="tableAdminAP">
                    <BasicTable key ={this.state.admins}  data = {this.state.admins} />
                </div>              
           </div>
  }
  
}


export default Admin;
