import React, { Component } from 'react';
import { Header, Image, Button, Divider, Segment, Form} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../media/Images/partner_icon.png';
import '../../stylesheets/partner.css'
import $ from 'jquery';
import track from '../track';

class Partner extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      partners: [],
      
      UserName: '',
      FirstName: '',
      LastName: '',
      Password: '',
      PasswordRe: '',
      ProfilePicture: null,
      Email: '',
      Address: '',
      Phone: '',
      salaryHour:0,

      values:['']
    }
  }

  componentDidMount(){
    document.getElementById("addPartner").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/partners`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ partners: result.partners })
          return;
        },
        error: (error) => {
          alert('Unable to load partners. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  new_partner = ()=>{
    if (document.getElementById("addPartner").hidden){
      document.getElementById("addPartner").hidden=false;
      document.getElementById("addPartner").scrollIntoView();
    }else{
      document.getElementById("addPartner").hidden=true;
      document.getElementById("tablePartner").scrollIntoView();
    } 
  }

  handleUserName = (event) =>{
    this.setState({UserName:event.target.value})
  }

  handleFirstName = (event) =>{
    this.setState({FirstName:event.target.value}, async ()=>{
      this.setState({username: this.state.FirstName.toLowerCase().trim()+"."+this.state.LastName.toLowerCase().trim()})
    })
  }

  handleLastName = (event) =>{
    this.setState({LastName:event.target.value}, async ()=>{
      this.setState({username: this.state.FirstName.toLowerCase().trim()+"."+this.state.LastName.toLowerCase().trim()})
    })
  }

  handleAddress = (event) =>{
    this.setState({Address:event.target.value})
  }

  handleEmail = (event) =>{
    this.setState({Email:event.target.value})
  }

  handlePhone = (event) =>{
    this.setState({Phone:event.target.value})
  }

  handlePassword = (event) =>{
    this.setState({Password:event.target.value})
  }

  handlePasswordRe = (event) =>{
    this.setState({PasswordRe:event.target.value})
  }

  handleProfilePicture = (event)=>{
    this.setState({ProfilePicture: event.target.files[0]})
  }

  handleSalary = (event) => {
    this.setState({
      salaryHour:event.target.value
    })
  }
  

  
  addPartner  = (event) =>{
    event.preventDefault();
    let x = 0;
    if(this.state.FirstName !== ''){x = x+1;}
    if(this.state.LastName !== ''){x = x+1;}
    // if(this.state.UserName === '' ){
    //   alert("Please enter a unique Username to be able to sign up")
    // } else 
    if(x < 1){
      alert("Please enter your name to be able to sign up")
    }
    else if(this.state.Email === '' ){
      alert("Please enter your E-mail to be able to sign up")
    }
    else if(this.state.Phone === '' ){
      alert("Please enter your phone number to be able to sign up")
    } 
    else if(this.state.Password === '' || this.state.PasswordRe === '' || this.state.Password !== this.state.PasswordRe ){
      alert("Please check your password to be able to sign up")
    } else{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/partners`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            username: this.state.UserName,
            first_name: this.state.FirstName,
            last_name: this.state.LastName,
            password: this.state.Password,
            re_password: this.state.PasswordRe,
            address: this.state.Address,
            email: this.state.Email,
            phone: this.state.Phone,
            salary_hour: this.state.salaryHour
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            if (this.state.ProfilePicture !== null){
              var data1 = new FormData();
              data1.append("file", this.state.ProfilePicture)
              data1.append("filename", this.state.UserName)
              
              $.ajax({
                url: `/uploads/${result.partner.id}/partners`, //TODO: update request URL
                type: "POST",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                enctype: 'multipart/form-data',
                data: data1,
                processData: false,
                contentType: false,
                cache: false,
                timeout: 800000,
              
                xhrFields: {
                  withCredentials: true
                },
                crossDomain: true,
                success: (result) => {
                  track(`Added new partner, username = ${this.state.UserName} with picture`,
                    'Partner', 'Add')
                  document.getElementById("addPartner").hidden=true;
                  $.ajax({
                    url: `/api/partners`, //TODO: update request URL
                    type: "GET",
                    beforeSend: function (xhr) {   //Include the bearer token in header
                      xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                    },
                    success: (result) => {
                      this.setState({ partners: result.partners });
                      document.getElementById("tablePartner").scrollIntoView();
                      return;
                    },
                    error: (error) => {
                      alert('Unable to load partners. Please try your request again')
                      return;
                    }
                  })
                  return;
                },
                error: (error) => {
                  alert('Unable to add the profile picture. Please try your request again')
                  return;
                }
              })
            } else{
              track(`Added new partner, username = ${this.state.UserName} without picture`,
                    'Partner', 'Add')
                  
              document.getElementById("addPartner").hidden=true;
              $.ajax({
                url: `/api/partners`, //TODO: update request URL
                type: "GET",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                success: (result) => {
                  this.setState({ partners: result.partners });
                  document.getElementById("tablePartner").scrollIntoView();
                  return;
                },
                error: (error) => {
                  alert('Unable to load partners. Please try your request again')
                  return;
                }
              })
              return;
            }
            return;
          },
          error: (error) => {
            alert(`Unable to add partner - ${typeof error?.responseJSON?.message ===typeof "yes" ? error?.responseJSON?.message : "You have been Idol for a while, please refresh page"}`)
            return;
          }
        })
      }  
      secondFunction();
    }
  }
 


  render() {
    
    return <div>
                <div id='headerPartPartner'>
                  <Header id='HeaderPartner' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={inst_icon} /> Partners
                  </Header>
                </div>
                <Divider horizontal/>
                
                <div >
                  <Button id='partner_button1' size='mini' color='red' onClick={this.new_partner}> Add Partner </Button>
                </div>
                <Divider horizontal/>
                
                <div id="tablePartner">
                    <BasicTable key ={this.state.partners}  data = {this.state.partners} />
                </div>
                <Divider horizontal/>

              <div id='addFormPartner'>
                <Segment id="addPartner" inverted>
                  <Form id='formPartner' inverted>
                    <Form.Group>
                      <Form.Field className='fullWidthFormPartner'>
                        <label className="labelPartner">User Name</label>
                        {/* <input className='input' placeholder='User Name' onChange={this.handleUserName}/> */}
                        <input className='input' readOnly value={this.state.FirstName.toLowerCase().trim()+"."+this.state.LastName.toLowerCase().trim()}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthPartner'>
                        <label className="labelPartner">First Name</label>
                        <input className='input' placeholder='First Name' onChange={this.handleFirstName}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthPartner'>
                        <label className="labelPartner">Last Name</label>
                        <input className='input' placeholder='Last Name' onChange={this.handleLastName}/>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field className='formHalfWidthPartner'>
                        <label className="labelPartner">Password</label>
                        <input className='input' type='password' placeholder='Password' onChange={this.handlePassword}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthPartner'>
                        <label className="labelPartner">Password Re-enter</label>
                        <input className='input' type='password' placeholder='Password Re-enter' onChange={this.handlePasswordRe}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='fullWidthFormPartner'>
                        <label className="labelPartner">Profile Picture</label>
                        <input className='input' type='file' placeholder="Choose Partner's Profile Picture" accept="image/*" onChange={this.handleProfilePicture}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='fullWidthFormPartner'>
                        <label className="labelPartner">Address</label>
                        <input className='input' placeholder='Address' onChange={this.handleAddress}/>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='formHalfWidthPartner'>
                        <label className="labelPartner">E-Mail</label>
                        <input className='input' placeholder='E-Mail' onChange={this.handleEmail}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthPartner'>
                        <label className="labelPartner">Phone</label>
                        <input className='input' placeholder='Phone' onChange={this.handlePhone}/>
                      </Form.Field>
                    </Form.Group>

                    <Form.Group>
                      <Form.Field className='formHalfWidthPartner'>
                        <label className="labelPartner">Salary (EGP/Month)</label>
                        <input className='input' type='number' placeholder={'Salary (EGP/Month)'}
                         onChange={this.handleSalary}/>
                      </Form.Field>
                    
                    </Form.Group>
                                       
                    <Button id='submitPartner' floated='right' type='submit' onClick={this.addPartner}>Submit</Button>
                  </Form>
                </Segment>
              </div>

              
           </div>
  }
  
}


export default Partner;
