import React, { Component } from 'react';
import { Header, Image, Button, Divider, Table, Dropdown} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import inst_icon from '../../media/Images/employee_icon.png';
import '../../stylesheets/leave.css'
import $ from 'jquery';
import track from '../track';

class Leave extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      leaves: [],
      currentYear:0,
      chosenYear:0,
      years:[],
      yearOptions:[]
    }
  }

  getCurrentYearLeaves(){
    $.ajax({
      url: `/api/years/${this.state.currentYear}/leaves`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {   //Include the bearer token in header
        xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
      },
      success: (result) => {
        this.setState({ leaves: result.leaves })
        return;
      },
      error: (error) => {
        alert('Unable to load leaves. Please try your request again')
        return;
      }
    });
  }

  getAllYearThenLeaves(){
    $.ajax({
      url: `/api/years/leaves`, //TODO: update request URL
      type: "GET",
      beforeSend: function (xhr) {   //Include the bearer token in header
        xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
      },
      success: (result) => {
        this.setState({ 
          years: result.years,
          yearOptions: result.years_options,
          currentYear: result.years[0],
          chosenYear: result.years[0]
        }, async ()=>{
          this.getCurrentYearLeaves()
        })
        return;
      },
      error: (error) => {
        alert('Unable to load available years of leaves. Please try your request again')
        return;
      }
    });
  }
  componentDidMount(){
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      this.getAllYearThenLeaves()
    }
    secondFunction();    
  }
  
  getChosenYearLeaves = (event, {value})=>{
    this.setState({chosenYear: value}, async ()=> {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/years/${this.state.chosenYear}/leaves`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({ leaves: result.leaves })
            return;
          },
          error: (error) => {
            alert('Unable to load leaves of the chosen year. Please try your request again')
            return;
          }
        });
      }
      secondFunction();
    })
  }  
  
  startNextYearLeaves  = (event) =>{
    event.preventDefault();
    let x = 0;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/leaves`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          next_year: this.state.currentYear+1
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Started leaves of Y${this.state.currentYear+1}`,
                    'Leave', 'Start')
          
          this.getAllYearThenLeaves()
          return;
        },
        error: (error) => {
          alert(`Unable to start next year's leaves"}`)
          return;
        }
      });
      }
      secondFunction();
    
  }
 


  render() {
    
    return <div>
              <div id='headerPartLeave'>
                <Header id='HeaderLeave' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={inst_icon} /> Leaves
                </Header>
              </div>
              <Divider horizontal/>
              
              <div >
                <Button id='leave_button1' size='mini' color='red' onClick={this.startNextYearLeaves}> Start Leaves of Y{this.state.currentYear + 1} </Button>
                <Table definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell textAlign='right' width={1}>Choose a Year</Table.Cell>
                        <Table.Cell textAlign='left' width={1}>
                        <Dropdown
                          placeholder="Choose a Year"
                          selection
                          options={this.state.yearOptions}
                          onChange={this.getChosenYearLeaves}
                        />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                </Table>
              </div>
              <Divider horizontal/>              

              <div id="tableLeave">
                  <BasicTable key ={this.state.leaves}  data = {this.state.leaves} />
              </div>
              <Divider horizontal/>
           </div>
  }
  
}


export default Leave;
