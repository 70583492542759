import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import kitchen_icon from '../../media/Images/kitchen.png';
import '../../stylesheets/kitchen.css'
import $ from 'jquery';
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
import track from '../track';

class Kitchen extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      kitchens: [],
      kitchenDescription: '',
      amountIn: 0,
      amountOut: 0,
      date: new Date()
    }
  }

  componentDidMount(){
    document.getElementById("addKitchen").hidden=true;
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/kitchens`, //TODO: update request URL
        type: "GET",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        success: (result) => {
          this.setState({ kitchens: result.kitchens })
          return;
        },
        error: (error) => {
          alert('Unable to load dance kitchens. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }
  
  new_kitchen = ()=>{
    if (document.getElementById("addKitchen").hidden){
      document.getElementById("addKitchen").hidden=false;
      document.getElementById("addKitchen").scrollIntoView();
    }else{
      document.getElementById("addKitchen").hidden=true;
      document.getElementById("tableKitchen").scrollIntoView();
    } 
  }

  addKitchen = (event) =>{
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/kitchens`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          description: this.state.kitchenDescription,
          amount_in: this.state.amountIn,
          amount_out: this.state.amountOut,
          date: this.state.date
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`Added new kitchen = ${this.state.kitchenDescription}`,
                    'Kitchen', 'Add')
          
          $.ajax({
            url: `/api/kitchens`, //TODO: update request URL
            type: "GET",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            success: (result) => {
              this.setState({ kitchens: result.kitchens },async ()=> {
                document.getElementById("addKitchen").hidden=true;
                document.getElementById("tableKitchen").scrollIntoView();
              })
              return;
            },
            error: (error) => {
              alert('Unable to load roams. Please try your request again')
              return;
            }
          })
          return;
        },
        error: (error) => {
          alert('Unable to add the kitchen. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleKitchenDescription = (event) => {
    this.setState({kitchenDescription: event.target.value})
  }
  
  handleAmountIn = (event) => {
    this.setState({amountIn: event.target.value})
  }

  handleAmountOut = (event) => {
    this.setState({amountOut: event.target.value})
  }

  handleDate = (event) => {
    this.setState({
        date: event.target.value
    });
  }

  
  
  render() {
    
    return <div>

              <div id='headerPartKitchen'>
                <Header id='HeaderKitchen' as='h2' block={true} value={this.state.room}>
                  <Image size='large' circular src={kitchen_icon} /> Kitchens
                </Header>
              </div>

              <div >
                <Button id='kitchen_button1' size='mini' color='red' onClick={this.new_kitchen}> Add a Kitchen </Button>
              </div>
              <Divider horizontal/>

              <Divider horizontal/>
              <div id = 'tableKitchen'>
                  <BasicTable key ={this.state.kitchens}  data = {this.state.kitchens} />
              </div>

              <div id='addFormKitchen'>
                <Segment id="addKitchen" inverted>
                  <Form id='formKitchen' inverted>
                    <Form.Group>
                      <Form.Field className='form1'>
                        <label className="labelKitchen">Description</label>
                        <input className ='input' placeholder='Description' onChange={this.handleKitchenDescription}/>   
                      </Form.Field>

                      <Form.Field className='date' id="date">
                        <label className="labelKitchen">Date</label>
                        <DatePickerComponent
                          id='StartTime'
                          className ="e-field"
                          format='dd/MM/yy'
                          value={this.state.date}
                          onChange={this.handleDate}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field className='form1'>
                        <label className="labelKitchen">Amount In (LE)</label>
                        <input className ='input' type='number' placeholder='Amount In (LE)' onChange={this.handleAmountIn}/>
                      </Form.Field>

                      <Form.Field className='form1'>
                        <label className="labelKitchen">Amount Out (LE)</label>
                        <input className ='input' type='number' placeholder='Amount Out (LE)' onChange={this.handleAmountOut}/>
                      </Form.Field>
                      
                    </Form.Group>
                    <Button id='submitKitchen' floated='right' type='submit' onClick={this.addKitchen}>Submit</Button>
                  </Form>
                </Segment>
              </div>
           </div>
  }
  
}


export default Kitchen;
