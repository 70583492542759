import React, { Component } from 'react';
import { Header, Divider, Icon} from 'semantic-ui-react'
import {BasicTable} from './BasicTable';
import '../../../../stylesheets/detailedStudentSubscriptionPriv.css'
import $ from 'jquery';

class DetailedSubscription extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      
    }
  }

  componentDidMount(){
    
  }
  
  
  render() {
    
    return <div>
                <div id='HeaderDetailedStudSubscription'>
                  <Divider horizontal>
                    <h4>
                      <Icon name='credit card' />
                      Subscription Usage
                    </h4>
                  </Divider>
                </div>
                <div id = 'tableDetailedStudSubscription'>
                    <BasicTable key ={this.props.subscriptionsDetails}  data = {this.props.subscriptionsDetails} />
                </div>


           </div>
  }
  
}


export default DetailedSubscription;
