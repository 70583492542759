import React, { useState } from 'react';
import { Route , Redirect, useHistory} from 'react-router-dom';
import { isExpired, decodeToken } from "react-jwt";
import $ from 'jquery';


const ProtectedRoute = ({path:path, setToken:setToken, requiredAuth:requiredAuth, component: Component, ...rest },props) => {
    let decodedToken = decodeToken(sessionStorage.getItem("access_token"));
    let isExpiredToken = isExpired(sessionStorage.getItem("access_token"));
    let history = useHistory();
    
    return (
            <Route 
                {...rest}
                render={(props) => {
                    //console.log("here we go")
                    if(isExpiredToken){
                        //console.log("here we go222");
                        $.ajax({
                            url: `/api/refresh`, //TODO: update request URL
                            type: "POST",
                            beforeSend: function (xhr) {   //Include the bearer token in header
                                xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("refresh_token"));
                            },
                            dataType: 'json',
                            contentType: 'application/json',
                            data: JSON.stringify({
                            }),
                            xhrFields: {
                                withCredentials: true
                            },
                            crossDomain: true,
                            success: (result) => {
                                sessionStorage.setItem("access_token",result.access_token);
                                //console.log("here we g333");
                                window.location.reload();
                                
                                /*
                                let prevAuth = 0;
                                decodedToken = decodeToken(result.access_token);
                                isExpiredToken = isExpired(result.access_token);
                                if (typeof decodedToken !== 'undefined' && decodedToken !== null && isExpiredToken === false){
                                    if (decodedToken.is_partner === true){
                                        prevAuth = 4;
                                    } else if (decodedToken.is_admin === true){
                                        prevAuth = 3;
                                    } else if (decodedToken.is_instructor === true){
                                        prevAuth = 2;
                                    } else if (decodedToken.is_student === true){
                                        prevAuth = 1;
                                    } 
                                    //console.log("here we g444");
                                    //console.log("prevAuth = ", prevAuth,"& requiredAuth =",requiredAuth);
                                    if (prevAuth >= requiredAuth){
                                        //console.log("should go to component");
                                        return(
                                            <Component {...rest} {...props} setToken={setToken}/>
                                        );
                                    }else{
                                        //console.log("should go to /");
                                        return(
                                            <Redirect to={{
                                                pathname: "/",
                                                state:{
                                                    from: props.location
                                                }
                                            }}
                                            />
                                        );       
                                    }
                                    
                                } else{
                                    return(
                                        <Redirect to={{
                                            pathname: "/logins",
                                            state:{
                                                from: props.location
                                            }
                                        }}
                                        />
                                    );
                                }*/
                            },
                            error: (error) => {
                                sessionStorage.removeItem("access_token")
                                sessionStorage.removeItem("refresh_token")
                                //setToken(null)
                                history.push('/logins')
                                return(
                                    <Redirect to={{
                                        pathname: "/logins",
                                        state:{
                                            from: props.location
                                        }
                                    }}
                                    />
                                );
                            }
                        });
                    }else{
                        let prevAuth = 0;
                        if (typeof decodedToken !== 'undefined' && decodedToken !== null && isExpiredToken === false){
                            if (decodedToken.is_partner === true){
                                prevAuth = 4;
                            } else if (decodedToken.is_admin === true){
                                prevAuth = 3;
                            } else if (decodedToken.is_instructor === true){
                                prevAuth = 2;
                            } else if (decodedToken.is_student === true){
                                prevAuth = 1;
                            } 
                            
                            if (prevAuth >= requiredAuth){
                                return(<Component {...rest} {...props} setToken={setToken}/>);
                            }else{
                                return(
                                    <Redirect to={{
                                        pathname: "/",
                                        state:{
                                            from: props.location
                                        }
                                    }}
                                    />
                                );       
                            }
                            
                        } else{
                            return(
                                <Redirect to={{
                                    pathname: "/logins",
                                    state:{
                                        from: props.location
                                    }
                                }}
                                />
                            );
                        }
                    }
                }} />
          )

    
}

export default ProtectedRoute;
