import BigCalendar from 'react-big-calendar-like-google';
import moment from 'moment';
import '../stylesheets/ScheduleCopy.css';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

const SchedueCopy = props => {
  const myEventsList = [
    {
      title: "Big Meeting",
      allDay: true,
      start: new Date(2022, 1, 21),
      end: new Date(2022, 1, 21),
  },
  {
      title: "Vacation",
      start: new Date(2022, 1, 22),
      end: new Date(2022, 1, 22),
  },
  {
      title: "Conference",
      start: new Date(2022, 1, 23),
      end: new Date(2022, 1, 24),
  }
];



  return(
  <div>
    <BigCalendar
      events={myEventsList}
      startAccessor='startDate'
      endAccessor='endDate'
    />
  </div>
)};

export default SchedueCopy;