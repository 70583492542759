import React, { Component } from 'react';
import {Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import CardTemplate from '../CardTemplate';
import no_profile_picture from '../../media/Images/no_profile_picture.png';
import '../../stylesheets/SelfUserEdit.css'
import $ from 'jquery';



class UserEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      userId:0,
      username:'',
      firstName: '',
      lastName: '',
      address: '',
      email: '',
      phone: '',
      role:'',
      Password:'',
      PasswordRe:'',

      profile_picture: null,
      imageURL: '',

      testAPI: '',
      testAPIPost: '',
      testImageURLBase64Decoded: '',

      user: {}
      
    }
  }

  navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }

  componentDidMount(){
    document.getElementById("resetUserEdit").hidden=true;
    
    this.setState({userId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/users/${this.state.userId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              user: result.user,
              userId: result.user.id,
              username: result.user.username,
              firstName: result.user.first_name,
              lastName: result.user.last_name,
              address: result.user.address,
              email: result.user.email,
              phone: result.user.phone,
              role: result.user.role
            }, async ()=> {
              $.ajax({
                url: `/uploads/${this.state.userId}/users`, //TODO: update request URL
                type: "GET",
                beforeSend: function (xhr) {   //Include the bearer token in header
                  xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
                },
                enctype: 'multipart/form-data',
                processData: false,
                contentType: false,
                cache: true,
                timeout: 10000000,
                              
                success: (result) => {
                  this.setState({
                    testAPI: 'Successful API',
                    testImageURLBase64Decoded: result,
                    imageURL: `data:image/jpeg;base64,${result}`,
                }//, async () => {
                    //console.log("result in UserEdit Component with base64=",this.state.testImageURLBase64Decoded);
                    //console.log("result in UserEdit Component with after decode base64=",this.state.imageURL);
                  //}
                  ); // `http://localhost:5000/uploads/${this.state.userId}/users`
                  return;
                },
                error: (error) => {
                  this.setState({
                    testAPI: 'Failed API',
                    imageURL: no_profile_picture })
                  return;
                }
              });
            });
            return ;
          },
          error: (error) => {
            alert('Unable to load User Account. Please try your request again')
            return;
          }
        })
      }  
      secondFunction();
    }) 
  }

  
  handleUsername = (event) =>{
    this.setState({username:event.target.value})
  }

  handleFirstName = (event) =>{
    this.setState({firstName:event.target.value})
  }

  handleLastName = (event) =>{
    this.setState({lastName:event.target.value})
  }

  handleAddress = (event) =>{
    this.setState({address:event.target.value})
  }

  handleEmail = (event) =>{
    this.setState({email:event.target.value})
  }

  handlePhone = (event) =>{
    this.setState({phone:event.target.value})
  }

    
  userEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/users/${this.state.userId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          user_id: this.state.userId,
          username: this.state.username,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          address: this.state.address,
          email: this.state.email,
          phone: this.state.phone
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          this.navTo('/');
          return;
        },
        error: (error) => {
          alert('Unable to update the user. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }

  cancelId = ()=>{
    this.navTo(`/`)
  }

  
  handleProfilePicture = (event)=>{
    this.setState({profile_picture: event.target.files[0]})
  }



  changeProfilePicture = (e)=>{
    e.preventDefault();
  
    var data1 = new FormData();
    data1.append('file', this.state.profile_picture);
    data1.append('filename', this.state.username);
    
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/uploads/${this.state.userId}/users`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        enctype: 'multipart/form-data',
        data: data1,
        processData: false,
        contentType: false,
        cache: false,
        timeout: 800000,
                      
        success: (result) => {
          this.setState({ 
            imageURL: URL.createObjectURL(this.state.profile_picture),
            testAPIPost:'Successful API Post'
          } //, async () => {
//            console.log("result in UserEdit Component after change=",result);
//          }
          );
          return;
        },
        error: (error) => {
          this.setState({testAPIPost:'Failed API Post'})
          alert('Unable to change profile picture. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }


  handlePassword = (event) =>{
    this.setState({Password:event.target.value})
  }

  handlePasswordRe = (event) =>{
    this.setState({PasswordRe:event.target.value})
  }

  reset_password = ()=>{
    if (document.getElementById("resetUserEdit").hidden){
      document.getElementById("resetUserEdit").hidden=false;
      document.getElementById("resetUserEdit").scrollIntoView();
    }else{
      document.getElementById("resetUserEdit").hidden=true;
    } 
  }


  resetPassword = (event) =>{
    event.preventDefault();
    if(this.state.Password === '' || this.state.PasswordRe === '' || this.state.Password !== this.state.PasswordRe ){
      alert("Unable to change password, please enter a valid password")
    } else{
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/users/${this.state.userId}/resetPassword`, //TODO: update request URL
          type: "POST",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            password: this.state.Password,
            re_password: this.state.PasswordRe
          }),
          xhrFields: {
            withCredentials: true
          },
          crossDomain: true,
          success: (result) => {
            document.getElementById("resetUserEdit").hidden=true;
            return;
          },
          error: (error) => {
            alert('Unable to reset Password - '+ error.responseJSON.message)
            return;
          }
        })
      }  
      secondFunction();
    }
  }

  render() {
    console.log("result in UserEdit Component with base64=",this.state.testImageURLBase64Decoded);
    console.log("result in UserEdit Component with after decode base64=",this.state.imageURL);
    console.log("testAPI=", this.state.testAPI)
    console.log("testAPIPOST=", this.state.testAPIPost)
    return <div>
                <div id='headerPartSelfUserEdit'>  
                  <Header id='HeaderSelfUserEdit' as='h2' block={true}>
                      <Image size='large' circular src={this.state.imageURL} /> {this.state.firstName +" "+ this.state.lastName}
                  </Header>
                </div>
                <Divider horizontal/>
                
                <div id ='containerSelfUserEdit'>
                  <div id='cardSelfUserEdit'>
                    <CardTemplate
                    name={this.state.firstName +" "+ this.state.lastName}
                    img ={this.state.imageURL}
                    role = {this.state.role}
                    email = {this.state.email}
                    phoneNumber = {this.state.phone}
                    ></CardTemplate>
                  </div>
                  <div id='addFormUserEdit'>
                    <Segment id="addSelfUserEdit" inverted>
                      <Form id='formSelfUserEdit' inverted>
                        <Form.Group>
                          <Form.Field className='formHalfWidthSelfUserEdit'>
                              <label className="labelSelfUserEdit">Username</label>
                              <input className ='input' defaultValue={this.state.username} onChange={this.handleUsername} readOnly/>
                          </Form.Field>
                          <Form.Field className='formHalfWidthSelfUserEdit'>
                              <label className="labelSelfUserEdit">User ID</label>
                              <input className ='input' type='number' value={this.state.userId} readOnly/>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='formHalfWidthSelfUserEdit'>
                              <label className="labelSelfUserEdit">First Name</label>
                              <input className ='input' defaultValue={this.state.firstName} onChange={this.handleFirstName}/>
                          </Form.Field>
                          <Form.Field className='formHalfWidthSelfUserEdit'>
                              <label className="labelSelfUserEdit">Last Name</label>
                              <input className ='input' defaultValue={this.state.lastName} onChange={this.handleLastName}/>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='fullWidthFormSelfUserEdit'>
                              <label className="labelSelfUserEdit">Address</label>
                              <input className ='input' defaultValue={this.state.address} onChange={this.handleAddress}/>
                          </Form.Field>
                        </Form.Group>
                        <Form.Group>
                          <Form.Field className='formHalfWidthSelfUserEdit'>
                              <label className="labelSelfUserEdit">E-Mail</label>
                              <input className ='input' defaultValue={this.state.email} onChange={this.handleEmail}/>
                          </Form.Field>
                          <Form.Field className='formHalfWidthSelfUserEdit'>
                              <label className="labelSelfUserEdit">Phone</label>
                              <input className ='input' defaultValue={this.state.phone} onChange={this.handlePhone}/>
                          </Form.Field>
                        </Form.Group>
                      
                        <div>
                          <Button id='submitSelfUserEdit1' content='Primary' floated='right' type='submit' onClick={this.userEditId}>Update</Button>
                          <Button id='submitSelfUserEdit2' content='Primary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                        </div>
                      </Form>
                    </Segment>
                  </div>
                </div>
                <Divider horizontal/>
                <div id='profilePictureChangeSelfUserEdit'>
                  <Segment>
                    <Form>
                      <input type='file' accept="image/*" onChange={this.handleProfilePicture}></input>
                      <Button id='submitSelfUserEdit3' onClick={this.changeProfilePicture}>Change Profile Picture</Button>
                    </Form>
                  </Segment>
                </div>
                <Divider horizontal/>
                
                <div >
                  <Button id='user_reset_password' size='mini' color='red' onClick={this.reset_password}> Reset Password </Button>
                </div>
                
                <div id='resetPasswordUserEdit'>
                <Segment id="resetUserEdit" inverted>
                  <Form id='formResetPasswordUser' inverted>
                    <Form.Group>
                      <Form.Field className='formHalfWidthUser'>
                        <label className="labelUser">Password</label>
                        <input className='input' type='password' placeholder='Password' onChange={this.handlePassword}/>
                      </Form.Field>
                      <Form.Field className='formHalfWidthUser'>
                        <label className="labelUser">Password Re-enter</label>
                        <input className='input' type='password' placeholder='Password Re-enter' onChange={this.handlePasswordRe}/>
                      </Form.Field>
                    </Form.Group>
                    <Button id='submitResetPasswordUser' floated='right' type='submit' onClick={this.resetPassword}>Submit</Button>
                  </Form>
                </Segment>
              </div>
                        
           </div>
  }
}

export default UserEdit;
