import React, { useState } from 'react'
import { TextArea } from 'semantic-ui-react'
import '../../stylesheets/FooterPart.css';
import facebookIcon from '../../media/Images/facebook.png';
import instagramIcon from '../../media/Images/Instagram.png';
import { Link } from 'react-router-dom';

const Footer = () => {

  const handleFooter = () => {
    document.getElementById('SCHEDULE').scrollIntoView();
  }


  return (
    <div id='FooterBackground' >
      {/* <img id='iconItem1' src={facebookIcon} alt='Facebook Icon'/> */}
      <a href='https://instagram.com/soulmotionstudio?igshid=YmMyMTA2M2Y=' target='_blank'>
        <img id='iconItem2' src={instagramIcon} alt='Instagram Icon' />
      </a>
      <div id='iconItem3'>
        <p><a href='https://api.whatsapp.com/send?phone=201275800528'>{`Whatsapp `}+20 127 580 0528</a></p>
        <br></br>
        <p><a href='tel:+20233059634'>Call us! +20 2 33059634</a></p>
      </div>

    </div>
  )
}
export default Footer;