import React, { Component, useEffect } from 'react';
import { Animator, ScrollContainer, ScrollContainerContext, ScrollPage, batch, Fade, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, ZoomIn } from "react-scroll-motion";


//import { Button } from 'semantic-ui-react'
import '../../stylesheets/Home.css';
//import $ from 'jquery';
import PromoPart from './PromoPart';
import PromoPartFooter from './PromoPartFooter';
import ClassesPart from './ClassesPart';
import PackagesPart from './PackagesPart';
import SchedulePart from './SchedulePart';
import AboutUsPart from './AboutUsPart';
import Footer from './Footer';


const Home = (props) =>{
  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  const FadeUp = batch(Fade(), Move(), Sticky());

  useEffect(()=>{
    if(props.aboutUs){
      document.getElementById('AboutUsPartComp').scrollIntoView();
      props.setAboutUs(false);
    }
  },[props.aboutUs]);

  useEffect(()=>{
    if(props.contact){
      document.getElementById('FooterPartComp').scrollIntoView();
      props.setContact(false);
    }
  },[props.contact]);




  const scrollClassesPart = ()=> {
    document.getElementById('ClassesPartComp').scrollIntoView();
  }

  // const scrollPackagesPart = ()=> {
  //   document.getElementById('PackagesPartComp').scrollIntoView();
  // }

  const scrollSchedulePart = ()=> {
    document.getElementById('SchedulePartComp').scrollIntoView();
  }

 return (
        <div id='container'>
          <PromoPart className='sectionClassInner'/>
          <PromoPartFooter className='sectionClassInner'
            scrollClassesPart={scrollClassesPart}
            // scrollPackagesPart={scrollPackagesPart}
            scrollSchedulePart={scrollSchedulePart}
          />
          <div id='ClassesPartComp'>
            <ClassesPart className='sectionClassInner'/>  
          </div>
          {/* <div id='PackagesPartComp'>
            <PackagesPart className='sectionClassInner'/>  
          </div> */}
          <div id='SchedulePartComp'>
            <SchedulePart className='sectionClassInner'/>  
          </div>
          
          <div id='AboutUsPartComp'>
            <AboutUsPart className='sectionClassInner'/>
          </div>
          <div id='FooterPartComp'>
            <Footer className='sectionClassInner'/>
          </div>
          
        </div>
    );
  
}

export default Home;
