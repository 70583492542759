import React, { Component } from 'react';
import { Header, Image, Button, Form, Segment, Divider} from 'semantic-ui-react'
import kitchen_icon from '../../media/Images/kitchen.png';
import '../../stylesheets/kitchenEdit.css'
import $ from 'jquery';
import track from '../track';
//import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
//import {format} from 'date-fns';

class KitchenEdit extends Component {
  constructor(props){
    super(...arguments);
    this.state = {
      kitchenDescription: '',
      amountIn: 0,
      amountOut: 0,
      kitchenId:0
    }
  }

  componentDidMount(){
    this.setState({kitchenId: this.props.match.params.id}, async () => {
      const secondFunction = async () => {
        const result = await this.props.refreshToken()
        // do something else here after firstFunction completes
        $.ajax({
          url: `/api/kitchens/${this.state.kitchenId}`, //TODO: update request URL
          type: "GET",
          beforeSend: function (xhr) {   //Include the bearer token in header
            xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
          },
          success: (result) => {
            this.setState({
              kitchenDescription: result.kitchen.description,
              amountIn:result.kitchen.amount_in,
              amountOut:result.kitchen.amount_out
              //date:result.kitchen.date
             })
            return;
          },
          error: (error) => {
            alert('Unable to load kitchen to edit. Please try your request again')
            return;
          }
        })
      }  
      secondFunction();
    })
  }

  
  kitchenDeleteId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/kitchens/${this.state.kitchenId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'delete',
          description: this.state.kitchenDescription,
          amount_in: this.state.amountIn,
          amount_out: this.state.amountOut
          //date: this.state.date
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`deleted the kitchen = ${this.state.kitchenDescription} `,
                    'Kitchen', 'Delete')        
          
          //this.navTo('/kitchens');
          this.props.history.push('/kitchens');
          return;
        },
        error: (error) => {
          $.ajax({
            url: `/api/kitchens/${this.state.kitchenId}`, //TODO: update request URL
            type: "POST",
            beforeSend: function (xhr) {   //Include the bearer token in header
              xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
            },
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({
              delete: 'deactivate',
              description: this.state.kitchenDescription,
              amount_in: this.state.amountIn,
              amount_out: this.state.amountOut
            //  date: this.state.date
            }),
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            success: (result) => {
              track(`deleted the kitchen = ${this.state.kitchenDescription} `,
                    'Kitchen', 'Delete')        
          
              //this.navTo('/kitchens');
              this.props.history.push('/kitchens');
              return;
            },
            error: (error) => {
              alert('Unable to delete the dance kitchen. Please try your request again')
              return;
            }
          })
          return;
        }
      })
    }
    secondFunction();
  }

  
  kitchenEditId = (event) => {
    event.preventDefault();
    const secondFunction = async () => {
      const result = await this.props.refreshToken()
      // do something else here after firstFunction completes
      $.ajax({
        url: `/api/kitchens/${this.state.kitchenId}`, //TODO: update request URL
        type: "POST",
        beforeSend: function (xhr) {   //Include the bearer token in header
          xhr.setRequestHeader("Authorization", 'Bearer '+ sessionStorage.getItem("access_token"));
        },
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          delete: 'edit',
          description: this.state.kitchenDescription,
          amount_in: this.state.amountIn,
          amount_out: this.state.amountOut
          //date: this.state.date
        }),
        xhrFields: {
          withCredentials: true
        },
        crossDomain: true,
        success: (result) => {
          track(`updated the kitchen = ${this.state.kitchenDescription} `,
                    'Kitchen', 'Update')        
          
          //this.navTo('/kitchens');
          this.props.history.push('/kitchens');
          return;
        },
        error: (error) => {
          alert('Unable to update the dance kitchen. Please try your request again')
          return;
        }
      })
    }
    secondFunction();
  }

  handleKitchenDescription = (event) => {
    this.setState({kitchenDescription: event.target.value})
  }
  
  handleAmountIn = (event) => {
    this.setState({amountIn: event.target.value})
  }

  handleAmountOut = (event) => {
    this.setState({amountOut: event.target.value})
  }

/*
  handleDate = (event) => {
    this.setState({
        date: event.target.value
    });
  }
*/
  /*navTo = (uri) =>{
    window.location.href = window.location.origin + uri;
  }*/

  cancelId = ()=>{
    this.props.history.push('/kitchens');
    //this.navTo(`/kitchens`)
  }

  render() {

    return <div>
                <div id='headerPartKitchenEdit'>
                  <Header id='HeaderKitchenEdit' as='h2' block={true} value={this.state.room}>
                    <Image size='large' circular src={kitchen_icon} /> Kitchen
                  </Header>
                </div>
                <Divider horizontal/>

                <div id='addFormKitchenEdit'>
                  <Segment id="addKitchenEdit" inverted>
                    <Form id='formKitchenEdit' inverted>
                      <Form.Group>
                        <Form.Field className='fullWidthFormKitchenEdit'>
                          <label className="labelKitchen">Description</label>
                          <input className ='input' defaultValue={this.state.kitchenDescription} onChange={this.handleKitchenDescription}/>   
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <Form.Field className='formHalfWidthKitchenEdit'>
                          <label className="labelKitchen">Amount In (LE)</label>
                          <input className ='input' type='number' value={this.state.amountIn} onChange={this.handleAmountIn}/>
                        </Form.Field>
                        <Form.Field className='formHalfWidthKitchenEdit'>
                          <label className="labelKitchen">Amount Out (LE)</label>
                          <input className ='input' type='number' value={this.state.amountOut} onChange={this.handleAmountOut}/>
                        </Form.Field>                        
                      </Form.Group>

                      <div>
                        <Button id='submitKitchenEdit1' content='Primary' floated='right' type='submit' onClick={this.kitchenEditId}>Update</Button>
                        <Button id='submitKitchenEdit2' content='Secondary' floated='right' type='submit' onClick={this.kitchenDeleteId}>Delete</Button>
                        <Button id='submitKitchenEdit3' content='Primary' floated='right' type='submit' onClick={this.cancelId}>Cancel</Button>
                      </div>
                    </Form>
                  </Segment>
                </div>
           </div>
  }
  
}


export default KitchenEdit;
