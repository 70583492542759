import React from 'react'
import { Menu } from 'semantic-ui-react'
import Site_Logo from '../../media/Images/logo.PNG';
import '../../stylesheets/PromoPartFooter.css';
import { Link } from 'react-router-dom';
import { Animator, ScrollContainer, ScrollContainerContext, ScrollPage, batch, Fade, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, ZoomIn } from "react-scroll-motion";

const PromoPartFooter = (props) =>{

  
return (
      <Menu id='menu_id' secondary={true} inverted ={true} >
            <div id='CLASSES' onClick={props.scrollClassesPart}>
              CLASSES
            </div>

            {/* <div id='PACKAGES'
              onClick={props.scrollPackagesPart}
            >
              PACKAGES
            </div> */}

            <div id='SCHEDULE'
              onClick={props.scrollSchedulePart}
            >
              SCHEDULE
            </div>
          
      </Menu>
    )
  }
  export default PromoPartFooter;