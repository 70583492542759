import React, { useState } from "react";
import { Menu } from "semantic-ui-react";
import salsa from "../../media/Images/Classes/salsa.png";
import bachata from "../../media/Images/Classes/bachata.png";
// import urbankiz from "../../media/Images/Classes/urbankiz.jpeg";
import salsastyling from "../../media/Images/Classes/salsastyling.png";
import bachatastyling from "../../media/Images/Classes/bachatastyling.jpeg";
import afro from "../../media/Images/Classes/afro.png";
import dancehall from "../../media/Images/Classes/dh.png";
import lyrical from "../../media/Images/Classes/lyrical.png";
import "../../stylesheets/ClassesPart.css";
import { Link } from "react-router-dom";

const ClassesPart = () => {
  const [hover_1, setHover_1] = useState(false);
  var linkStyle1;
  if (hover_1) {
    linkStyle1 = { backgroundImage: `url(${salsa})` };
    //linkStyle1 = {backgroundColor: 'red'}
  } else {
    linkStyle1 = {
      background: `linear-gradient(to top, rgba(87, 49, 49, 0.4), rgba(95, 38, 38, 0.4)), url( ${salsa})`,
      backgroundSize: "cover",
    };
    //linkStyle1 = {backgroundColor: 'blue'}
  }
  var toggleHover1 = () => {
    setHover_1(!hover_1);
  };
  //console.log("hover_1 =", hover_1)

  const [hover_2, setHover_2] = useState(false);
  var linkStyle2;
  if (hover_2) {
    linkStyle2 = { backgroundImage: `url(${bachata})` };
  } else {
    linkStyle2 = {
      background: `linear-gradient(to top, rgba(87, 49, 49, 0.4), rgba(95, 38, 38, 0.4)), url( ${bachata})`,
      backgroundSize: "cover",
    };
  }
  var toggleHover2 = () => {
    setHover_2(!hover_2);
  };
  //console.log("hover_2 =", hover_2)

  // const [hover_3, setHover_3] = useState(false);
  // var linkStyle3;
  // if (hover_3) {
  //   linkStyle3 = {backgroundImage: `url(${urbankiz})`}
  // }else{
  //   linkStyle3 = {
  //     background:`linear-gradient(to top, rgba(87, 49, 49, 0.4), rgba(95, 38, 38, 0.4)), url( ${urbankiz})`,
  //     backgroundSize:"cover"
  //   }
  // }
  // var toggleHover3 = ()=>{
  //   setHover_3(!hover_3);
  // }
  //console.log("hover_3 =", hover_3)

  const [hover_4, setHover_4] = useState(false);
  var linkStyle4;
  if (hover_4) {
    linkStyle4 = { backgroundImage: `url(${salsastyling})` };
  } else {
    linkStyle4 = {
      background: `linear-gradient(to top, rgba(87, 49, 49, 0.4), rgba(95, 38, 38, 0.4)), url( ${salsastyling})`,
      backgroundSize: "cover",
    };
  }
  var toggleHover4 = () => {
    setHover_4(!hover_4);
  };
  //console.log("hover_4 =", hover_4)

  const [hover_5, setHover_5] = useState(false);
  var linkStyle5;
  if (hover_5) {
    linkStyle5 = { backgroundImage: `url(${bachatastyling})` };
  } else {
    linkStyle5 = {
      background: `linear-gradient(to top, rgba(87, 49, 49, 0.4), rgba(95, 38, 38, 0.4)), url( ${bachatastyling})`,
      backgroundSize: "cover",
    };
  }
  var toggleHover5 = () => {
    setHover_5(!hover_5);
  };
  //console.log("hover_5 =", hover_5)

  const [hover_6, setHover_6] = useState(false);
  var linkStyle6;
  if (hover_6) {
    linkStyle6 = { backgroundImage: `url(${afro})` };
  } else {
    linkStyle6 = {
      background: `linear-gradient(to top, rgba(87, 49, 49, 0.4), rgba(95, 38, 38, 0.4)), url( ${afro})`,
      backgroundSize: "cover",
    };
  }
  var toggleHover6 = () => {
    setHover_6(!hover_6);
  };
  //console.log("hover_6 =", hover_6)

  const [hover_7, setHover_7] = useState(false);
  var linkStyle7;
  if (hover_7) {
    linkStyle7 = { backgroundImage: `url(${dancehall})` };
  } else {
    linkStyle7 = {
      background: `linear-gradient(to top, rgba(87, 49, 49, 0.4), rgba(95, 38, 38, 0.4)), url( ${dancehall})`,
      backgroundSize: "cover",
    };
  }
  var toggleHover7 = () => {
    setHover_7(!hover_7);
  };
  //console.log("hover_7 =", hover_7)

  const [hover_8, setHover_8] = useState(false);
  var linkStyle8;
  if (hover_8) {
    linkStyle8 = { backgroundImage: `url(${lyrical})` };
  } else {
    linkStyle8 = {
      background: `linear-gradient(to top, rgba(87, 49, 49, 0.4), rgba(95, 38, 38, 0.4)), url( ${lyrical})`,
      backgroundSize: "cover",
    };
  }
  var toggleHover8 = () => {
    setHover_8(!hover_8);
  };
  //console.log("hover_8 =", hover_8)

  const handleClasses = () => {
    document.getElementById("CLASSES").scrollIntoView();
  };

  const handlePackages = () => {
    document.getElementById("PACKAGES").scrollIntoView();
  };
  const handleSchedule = () => {
    document.getElementById("SCHEDULE").scrollIntoView();
  };

  return (
    <div id="ClassesBackground">
      <h1 id="ClassesHeader">CLASSES</h1>
      <div className="containerClass">
        <div
          className="itemClass"
          style={linkStyle1}
          onMouseEnter={toggleHover1}
          onMouseLeave={toggleHover1}
        >
          SALSA
        </div>
        <div
          className="itemClass"
          style={linkStyle2}
          onMouseEnter={toggleHover2}
          onMouseLeave={toggleHover2}
        >
          Bachata
        </div>
        {/* <div className='itemClass' style={linkStyle3} onMouseEnter={toggleHover3} onMouseLeave={toggleHover3} >
            URBANKIZ
          </div> */}
        <div
          className="itemClass"
          style={linkStyle4}
          onMouseEnter={toggleHover4}
          onMouseLeave={toggleHover4}
        >
          SALSA Lady STYLING
        </div>
        <div
          className="itemClass"
          style={linkStyle5}
          onMouseEnter={toggleHover5}
          onMouseLeave={toggleHover5}
        >
          BACHATA Lady STYLING
        </div>
        <div
          className="itemClass"
          style={linkStyle6}
          onMouseEnter={toggleHover6}
          onMouseLeave={toggleHover6}
        >
          Afro
        </div>
        <div
          className="itemClass"
          style={linkStyle7}
          onMouseEnter={toggleHover7}
          onMouseLeave={toggleHover7}
        >
          Dance Hall
        </div>
        <div
          className="itemClass"
          style={linkStyle8}
          onMouseEnter={toggleHover8}
          onMouseLeave={toggleHover8}
        >
          Lyrical
        </div>
      </div>
    </div>
  );
};
export default ClassesPart;
